import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import { connect } from "react-redux";
import FileImage from "./FileImage";
import { static_url } from "../../../config/config";
import { RowFlexCenter } from "../../shared/Grid";
import { queueUpdate } from "../../../actions/actions";
import { fetchAfterSave } from "../../../actions/pageControl";

const BgMobileView = ({ img_url, removed_clicked, remove }) => (
  <div>
    {img_url && !removed_clicked && (
      <RowFlexCenter>
        <img
          src={img_url}
          style={{ maxWidth: "100px", height: "auto" }}
          alt=""
        />
        <FlatButton label="Remove Image" onClick={remove} />
      </RowFlexCenter>
    )}
    {img_url && removed_clicked && (
      <div className="pd-h">Image will be removed (click save) </div>
    )}
    {!img_url && (
      <div className="pd-h">
        <FileImage
          name="mobile_background"
          label="select file"
          preview_size={80}
          info="Must be portrait orientation and minimum 500px height (1000px recomended)"
          min_height={500}
          orientation="portrait"
        />
      </div>
    )}
  </div>
);

class BgMobileState extends Component {
  constructor(props) {
    super(props);
    this.state = { removed_clicked: false };
  }

  remove = () => {
    const { page_type, page_id, dispatch } = this.props;

    const confirm = window.confirm("Removing mobile background. Are you sure?");
    if (!confirm) return;
    this.setState({ removed_clicked: true });

    const the_page_type = page_type === "events_home" ? "city" : page_type;

    const field =
      page_type === "events_home"
        ? "events_mobile_background"
        : "mobile_background";

    dispatch(queueUpdate(the_page_type, page_id, field, null));
    dispatch(fetchAfterSave(true));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.setState({
      removed_clicked: false
    });
  }

  render() {
    return (
      <BgMobileView
        img_url={this.props.img_url}
        removed_clicked={this.state.removed_clicked}
        remove={this.remove}
      />
    );
  }
}

const mapStateToProps = ({ data }) => {
  let db_value = null;
  let img_url = null;
  let modified = null; // will help us prevent browser hash
  const { page_type, page_id, data_key } = data;

  // this is city bg
  if (page_type === "events_home") {
    db_value = data.city.events_mobile_background;
    modified = data_key; // will alwayes refresh
    img_url = db_value
      ? "city_events/" + data.city.city_id + "/300x500.jpg"
      : null;
  } else {
    db_value = data[page_type].mobile_background;
    modified = data[page_type].modified;
    img_url = db_value ? page_type + "/" + page_id + "/300x500.jpg" : null;
  }

  img_url = img_url
    ? static_url + "img/mobile_background/" + img_url + "?" + modified
    : null;

  return {
    img_url,
    page_type,
    page_id,
    data_key
  };
};

export default connect(mapStateToProps)(BgMobileState);
