import React from "react";
import { connect } from "react-redux";

import CitySelect from "../shared/Drawer/CitySelect";
import ChapterSelect from "../shared/Drawer/ChapterSelect";
import PcCard from "../shared/PcCard";
import BreadCrumbs from "../shared/BreadCrumbs";
import PcAppBar from "../shared/TopBar/PcAppBar";
import PcDrawer from "../shared/Drawer/PcDrawer";

import EventTypeSelect from "../shared/Drawer/EventTypeSelect";
import EventList from "../shared/Drawer/EventTypeFilter/EventList";

import PcCheckbox from "../shared/PcCheckbox";
import PcHtmlEditor from "../shared/PcHtmlEditor";
import { modifyWrapper } from "../../core/ModifyWrapper";
import CardImages from "../shared/Images/CardImages";

import EventsCardPicks from "./EventsCardPicks";
import BackgroundMobile from "../shared/Images/BackgroundMobile";
import CardImagesBackground from "../shared/Images/CardImagesBackground";

const CheckboxEvents = modifyWrapper(PcCheckbox, "city");
const HtmlEditorEvents = modifyWrapper(PcHtmlEditor, "city");

const EventsHomeView = ({ city_id, title, data_key }) => {
  return (
    <div>
      <PcAppBar title={title + " events"} />
      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect />

        <EventTypeSelect />
        <EventList />
      </PcDrawer>
      <div className="pd pc-content">
        <BreadCrumbs />

        <PcCard cardTitle="Intro text">
          <CheckboxEvents name="has_events" label="Display&nbsp;events" />
          <h2>Intro text</h2>
          <HtmlEditorEvents
            name="body_events"
            dom_key={"events_home_" + { data_key }}
          />
        </PcCard>

        <EventsCardPicks />

        <CardImages data_type="city" data_id={city_id} />

        <CardImagesBackground data_type="events_home" data_id={city_id} />

        <PcCard cardTitle="Background Mobile" id="mb-mobile">
          <BackgroundMobile />
        </PcCard>
      </div>
    </div>
  );
};

const mapStateToProps = ({ data }) => {
  return {
    city_id: data.city.city_id,
    title: data.city.name,
    data_key: data.data_key
  };
};

export default connect(mapStateToProps)(EventsHomeView);
