import React, { Component } from "react";
import { connect } from "react-redux";
import FlatButton from "material-ui/FlatButton";
import FileImage from "./FileImage";
import { static_url } from "../../../config/config";
import { RowFlexCenter } from "../../shared/Grid";
import { queueUpdate } from "../../../actions/actions";
import { fetchAfterSave } from "../../../actions/pageControl";

const CoverView = ({ img_url, remove_clicked, remove }) => (
  <div>
    {img_url && !remove_clicked && (
      <RowFlexCenter>
        <img
          src={img_url}
          style={{ maxWidth: "100px", height: "auto" }}
          alt=""
        />
        <FlatButton label="Remove Cover" onClick={remove} />
      </RowFlexCenter>
    )}
    {img_url && remove_clicked && (
      <div className="pd-h">Image will be removed (click save) </div>
    )}
    {!img_url && (
      <div className="pd-h">
        <FileImage
          name="cover"
          label="select file"
          preview_size={80}
          orientation="portrait"
        />
      </div>
    )}
  </div>
);

class CoverState extends Component {
  constructor(props) {
    super(props);
    this.state = { remove_clicked: false };
  }

  remove = () => {
    const { city_id, dispatch } = this.props;

    const confirm = window.confirm("Removing cover. Are you sure?");
    if (!confirm) return;
    this.setState({ remove_clicked: true });

    dispatch(queueUpdate("city", city_id, "has_cover", null));
    dispatch(fetchAfterSave(true));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.setState({
      remove_clicked: false
    });
  }

  render() {
    return (
      <CoverView
        img_url={this.props.img_url}
        remove_clicked={this.state.remove_clicked}
        remove={this.remove}
      />
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { has_cover, symbol, modified, city_id } = data.city;
  let img_url = null;
  if (has_cover) {
    img_url =
      static_url + "covers/" + symbol.toLowerCase() + ".jpg?" + modified;
    //img_url = static_url + 'covers/181x266/' + symbol.toLowerCase() + '.jpg'
    // covers urls wit any get data are not working . Replaced whe it will be fixed
  }

  return {
    img_url,
    city_id,
    data_key: data.data_key
  };
};

export default connect(mapStateToProps)(CoverState);
