import { is_debug } from "../config/config";
import {
  indicatorOn,
  indicatorOff,
  cleanModiffy,
  clearPostData
} from "./actions";

import { clearCityDataAll } from "actions/cityData";

import { snackSuccess, snackError } from "./snacks";
import { leloFetch } from "../helpers";
import history from "../history.js";

export const addNew = (table, data) => {
  return (dispatch, getState) => {
    dispatch(indicatorOn());
    dispatch(cleanModiffy());
    dispatch(clearPostData());

    return leloFetch("add_new", {
      method: "POST",
      json_data: { table, data }
    }).then(
      data => {
        dispatch(indicatorOff());

        if (data.success) {
          dispatch(snackSuccess("Item added"));
          if (["category", "chapter"].includes(table)) {
            dispatch(clearCityDataAll());
          }
          history.push("/" + data.new_url);
        }
        if (data.error) {
          if (is_debug) dispatch(snackError("ERROR: " + data.error));
          else dispatch(snackError("ERROR: something went wrong"));
        }
      },
      error => {
        dispatch(indicatorOff());
        dispatch(snackError("ERROR: " + error));
      }
    );
  };
};

export default addNew;
