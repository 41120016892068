// @flow

import React, { useState } from "react";

import PcSearch from "../shared/PcSearch";
import EditableList from "../shared/EditableList";

type props = {
  country_id: number,
  venuelist_descriptive: Array<*>,
  queueModifyVenues: (Array<*>) => void
};

const AffiliateVenues = ({
  country_id,
  venuelist_descriptive = [],
  queueModifyVenues
}: props) => {
  const [state_venuelist: Array<*>, setState] = useState(venuelist_descriptive);

  const handleChange = venuelist => {
    setState(venuelist);
    queueModifyVenues(venuelist);
  };

  const handleAdd = venue => {
    const venuelist = [...state_venuelist, venue];
    setState(venuelist);
    queueModifyVenues(venuelist);
  };

  const excludes = state_venuelist.map(v => v.venue_id) || [];

  return (
    <>
      <PcSearch
        table="venue"
        narrow_field_key="country_id"
        narrow_field_id={country_id}
        onResultClick={handleAdd}
        search_id={"aff-" + country_id + '|' + excludes.join(',')}
        exclude={excludes}
      />
      <br />
      {state_venuelist.length === 0 && <div className="pd">No venues yet</div>}
      <EditableList
        items={state_venuelist}
        is_sortable={false}
        handleChange={handleChange}
      />
    </>
  );
};

export default AffiliateVenues;
