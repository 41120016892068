import React from "react";
import PcCard from "../shared/PcCard";
import PcTextField from "../shared/PcTextField";
import { Row, CellHalf } from "../shared/Grid";

import { modifyWrapper } from "../../core/ModifyWrapper";
const MTextField = modifyWrapper(PcTextField, "venue");

export default () => (
  <PcCard cardTitle="App links" id="card_venue_app">
    <Row>
      <CellHalf>
        <MTextField name="app_android" floatingLabelText="android" type="url" />
      </CellHalf>

      <CellHalf>
        <MTextField name="app_iphone" floatingLabelText="iphone" type="url" />
      </CellHalf>
    </Row>
  </PcCard>
);
