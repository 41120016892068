import { connect } from "react-redux";
import Info from "../shared/Info";

const mapStateToProps = ({ data }) => {
  let warnings = [];
  let notices = [];
  let errors = [];

  const city = data.city;

  if (city.redirect_to) {
    warnings.push(
      "This city has redirect set and another page will be displayed instead: " +
        city.redirect_to
    );
  } else {
    if (city.active) {
      if (!city.color) errors.push("An error accoured. Set city color.");
    }
    if (city.active || city.app_ready) {
      if (!city.menu_data) errors.push("An error accoured. Set menu data.");
      if (!city.lat || !city.lon)
        errors.push("An error accoured. Define map coordinates.");
      if (!data.background)
        errors.push("An error accoured. Add city background.");
    }
  }

  return {
    warnings,
    notices,
    errors
  };
};
export default connect(mapStateToProps)(Info);
