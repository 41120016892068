import React from "react";
//import {Card, CardTitle, CardText} from 'material-ui/Card';
//import PropTypes	from 'prop-types';

/*
const prop_types = {
	className			: PropTypes.string	,
	id 					: PropTypes.string	,
	cardTitle 			: PropTypes.string	,
	children			: PropTypes.any	,
	description			: PropTypes.string
}
*/

const styles = {
  header: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "7px"
  },
  card: {
    paddingBottom: "7px"
  }
};

const PcCard = ({ className = "", id, cardTitle, description, children }) => (
  <div className={className + " pd-w"} id={id} style={styles.card}>
    {cardTitle && <div style={styles.header}>{cardTitle}</div>}
    {description && <div>{description}</div>}
    {children}
  </div>
);

/*
const PcCard = ({className, id, cardTitle, description, children}) =>

	<Card className={className + ' pc-card'} id={id}>

		<CardTitle title={cardTitle} className={cardTitle ? "" : "hide"} style={{paddingBottom : "0"}}/>

		<CardText  style={cardTitle ? {paddingTop :  "0"} : {} }>

			{description &&
				<div className='pd-h'>{description}</div>
			}

			{children}

		</CardText>

	</Card>
*/

//PcCard.propTypes = prop_types

export default PcCard;
