import React, { Component } from "react";
import { connect } from "react-redux";
//import Dialog 				from 'material-ui/Dialog'
import NotModal from "./shared/NotModal";
import RaisedButton from "material-ui/RaisedButton";
import PcTextField from "./shared/PcTextField";

import { loginEntry } from "actions/login";
import { clearCityDataAll } from "actions/cityData";
import { clearConstants } from "actions/actions";

// Notice: Firefox requie native form submit to prompt for password saving

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: ""
    };
  }

  componentDidMount() {
    localStorage.removeItem("constants");
    localStorage.removeItem("user_data");
    this.props.clearEntries();

    window.setTimeout(
      function() {
        if (!this.state.username) {
          this.refs.refUsername.focusInput();
        } else {
          this.refs.refPass.focusInput();
        }
      }.bind(this),
      1000
    );
  }

  /*
		there is an issue with input when autofilled: https://github.com/mui-org/material-ui/issues/718
		It was because the autofill value was not registered by state. The timeout resolve the problem - probably simple blur made by click trigger value change
	*/
  handleLogin = e => {
    e.preventDefault();
    window.setTimeout(
      function() {
        const { username, password } = this.state;
        this.props.login(username, password);
      }.bind(this),
      500
    );
  };

  render() {
    const { username, password } = this.state;

    const actions = [
      <RaisedButton label="Login" type="submit" primary={true} key={1} />
    ];

    const error_style = {
      fontSize: "12px",
      color: "red"
    };

    const hideAutoFillColorStyle = {
      WebkitBoxShadow: "0 0 0 1000px white inset"
    };
    const hintStyle = { zIndex: "1" };

    return (
      <form onSubmit={this.handleLogin}>
        <NotModal title="Login" actions={actions} open={true} overlay={true}>
          {this.props.login_error && (
            <span style={error_style}>{this.props.login_error}</span>
          )}
          <PcTextField
            value={username}
            floatingLabelText="username"
            handleChange={val => this.setState({ username: val })}
            inputStyle={hideAutoFillColorStyle}
            hintStyle={hintStyle}
            ref="refUsername"
          />
          <br />
          <PcTextField
            value={password}
            type="password"
            floatingLabelText="password"
            handleChange={val => this.setState({ password: val })}
            inputStyle={hideAutoFillColorStyle}
            hintStyle={hintStyle}
            ref="refPass"
          />
        </NotModal>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    login_error: state.user_data.login_error || null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      dispatch(loginEntry(username, password));
    },
    clearEntries: () => {
      dispatch(clearCityDataAll());
      dispatch(clearConstants());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
