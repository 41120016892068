import React from "react";
import { connect } from "react-redux";

const EditedBy = ({ edited_by, modified }) => (
  <div className="breadcrumbs">
    edited: {!edited_by && !modified && <span>?</span>}
    {modified && <span>{modified}</span>}
    {edited_by && modified && <span> by </span>}
    {edited_by && <span>{edited_by}</span>}
  </div>
);

const mapStateToProps = state => {
  const item = state.data[state.data.page_type];
  return {
    edited_by: item ? item.edited_by : null,
    modified: item ? item.modified : null
  };
};

export default connect(mapStateToProps)(EditedBy);
