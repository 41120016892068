// @flow

import withActionDispatched from "./withActionDispatched";
import getUserData from "../actions/getUserData";
import getConstants from "../actions/getConstants";

function withUserDataFetched(WrappedComponent: any) {
  return withActionDispatched([getUserData, getConstants], WrappedComponent);
}

export default withUserDataFetched;
