/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import PcCard from "../../shared/PcCard.js";
import PcTextField from "../../shared/PcTextField.js";
import { Row, CellHalf, CellFull } from "../../shared/Grid";
import VenueMap from "./VenueMap";
import { buildObj } from "../../../helpers";
import { queueUpdate } from "../../../actions/actions.js";

class VenueCardLocation extends Component {
  constructor(props) {
    super(props);
    const { address, postcode, map, metro } = props;
    this.state = { address, postcode, map, metro };
  }

  componentWillReceiveProps(nextProps) {
    const { address, postcode, map, metro, data_key } = nextProps;
    if (this.props.data_key === nextProps.data_key) return;
    this.setState({ address, postcode, map, metro });
  }

  handleChange(key, value) {
    this.setState(buildObj(key, value));
    this.props.handleModify(key, value, this.props.venue_id);
  }

  render() {
    const { address, postcode, map, metro } = this.state;

    return (
      <PcCard cardTitle="Location" id="card_location">
        <Row>
          <CellHalf>
            <Row>
              <div className="col-xs-8">
                <PcTextField
                  value={address}
                  floatingLabelText="address"
                  handleChange={val => this.handleChange("address", val)}
                />
              </div>
              <div className="col-xs-4">
                <PcTextField
                  value={postcode}
                  floatingLabelText="postcode"
                  handleChange={val => this.handleChange("postcode", val)}
                />
              </div>
            </Row>
          </CellHalf>

          <CellHalf>
            <Row>
              <div className="col-xs-2">
                <PcTextField
                  value={map}
                  floatingLabelText="map"
                  handleChange={val => this.handleChange("map", val)}
                />
              </div>
              <div className="col-xs-4">
                <PcTextField
                  value={metro}
                  floatingLabelText="metro"
                  handleChange={val => this.handleChange("metro", val)}
                />
              </div>
              <div className="col-xs-6">
                <div className="button-wrapper">
                  <VenueMap address={address} />
                </div>
              </div>
            </Row>
          </CellHalf>
        </Row>
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { data_key, venue } = data;
  const { address, postcode, map, metro, venue_id } = venue;
  return { address, postcode, map, metro, venue_id, data_key };
};

const mapDispatchToProps = dispatch => {
  return {
    handleModify: (key, value, venue_id) => {
      dispatch(queueUpdate("venue", venue_id, key, value));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueCardLocation);
