import React from "react";
import PcCard from "../shared/PcCard";
import PcTextField from "../shared/PcTextField";
import { Row, CellHalf } from "../shared/Grid";

import { modifyWrapper } from "../../core/ModifyWrapper";

const MTextField = modifyWrapper(PcTextField, "venue");

export default () => (
  <PcCard cardTitle="Name" id="card_name">
    <Row>
      <CellHalf>
        <MTextField name="name" floatingLabelText="name" required={true} />
      </CellHalf>
      <CellHalf>
        <MTextField floatingLabelText="altname" name="altitle" />
      </CellHalf>
    </Row>
  </PcCard>
);
