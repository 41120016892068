import React, { Component } from "react";
import PcCard from "../shared/PcCard.js";
import { connect } from "react-redux";
import { queueUpdate } from "../../actions/actions.js";
import TextField from "material-ui/TextField";
import { debounce } from "lodash";

const LeadView = ({ lead, handleChange }) => {
  const counter = 140 - (lead || "").length;

  return (
    <PcCard cardTitle="Lead" id="card_lead">
      <div className="row">
        <div className="col-xs-12">
          <TextField
            name="any"
            value={lead || ""}
            multiLine={true}
            onChange={handleChange}
            fullWidth={true}
          />
        </div>
      </div>
      <div>Chars left: {counter}</div>
    </PcCard>
  );
};

class LeadState extends Component {
  constructor(props) {
    super(props);
    const { lead, venue_id } = props;
    this.state = { lead, venue_id };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps({ lead, venue_id }) {
    if (venue_id === this.state.venue_id) return;
    this.setState({ lead, venue_id });
  }

  propModifyDebounced = debounce(lead => {
    this.props.dispatch(
      queueUpdate("venue", this.state.venue_id, "lead", lead)
    );
  }, 500);

  handleChange(ev, val) {
    const lead = val.substr(0, 140);
    this.setState({ lead });
    this.propModifyDebounced(lead);
  }

  render() {
    return <LeadView {...this.state} handleChange={this.handleChange} />;
  }
}

const mapStateToProps = ({ data }) => {
  const { lead, venue_id } = data.venue;

  return { lead, venue_id };
};

export default connect(mapStateToProps)(LeadState);
