/* Figure.js */

import React, { Component } from "react";
import { connect } from "react-redux";
import FlatButton from "material-ui/FlatButton";

import PcTextField from "../PcTextField";
import { static_url } from "../../../config/config";
import { Row, CellHalf } from "../../shared/Grid";
import { queueUpdate } from "../../../actions/actions.js";
import FileImage from "./FileImage";

const FigureLoader = () => (
  <div className="pd">
    <small>Image must be landscape orientation & inimum width 870 px</small>
    <br />
    <br />
    <FileImage
      name="img_figure"
      label="add image"
      multiple={true}
      preview_size={80}
      min_width={870}
      orientation="landscape"
    />
  </div>
);

const FigureEditor = p => (
  <Row>
    <CellHalf>
      <img
        src={static_url + "img/figure/" + p.img_figure}
        alt=""
        style={{ maxWidth: "100%" }}
      />
    </CellHalf>

    <CellHalf>
      <PcTextField
        name="img_figure_desc"
        floatingLabelText="description"
        fullWidth={true}
        value={p.img_figure_desc}
        handleChange={val => p.handleChange("img_figure_desc", val)}
      />
      <PcTextField
        name="img_figure_copyright"
        floatingLabelText="copyright text"
        fullWidth={true}
        value={p.img_figure_copyright}
        handleChange={val => p.handleChange("img_figure_copyright", val)}
      />
      <PcTextField
        type="url"
        name="img_figure_copyright_url"
        floatingLabelText="copyright link"
        fullWidth={true}
        value={p.img_figure_copyright_url}
        handleChange={val => p.handleChange("img_figure_copyright_url", val)}
      />
      <div className="pd" />
      <FlatButton label="remove" onClick={() => p.handleRemove()} />
    </CellHalf>
  </Row>
);

class FigureView extends Component {
  handleChange = (key, value) => {
    let item = {};
    item[key] = value;
    this.props.handleImagesChange(item);
    this.props.modiffyItem(key, value);
  };

  handleRemove = () => {
    this.props.handleImagesChange({
      img_figure: null,
      img_figure_desc: null,
      img_figure_copyright: null,
      img_figure_copyright_url: null
    });
    this.props.modiffyItem("img_figure", null);
    this.props.modiffyItem("img_figure_desc", null);
    this.props.modiffyItem("img_figure_copyright", null);
    this.props.modiffyItem("img_figure_copyright_url", null);
  };

  render() {
    const { handleImagesChange, ...rest } = this.props;

    return (
      <div>
        {this.props.img_figure && (
          <FigureEditor
            {...rest}
            handleChange={this.handleChange.bind(this)}
            handleRemove={this.handleRemove.bind(this)}
          />
        )}

        {!this.props.img_figure && <FigureLoader />}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modiffyItem: (key, data) => {
      dispatch(queueUpdate(ownProps.data_type, ownProps.data_id, key, data));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(FigureView);
