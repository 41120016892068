import React, { Component } from "react";

import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";

import PropTypes from "prop-types";

import { state_colors } from "../../config/config";

const propTypes = {
  dom_key: PropTypes.string.isRequired,
  data_id: PropTypes.number.isRequired,
  //value			:		PropTypes.number.isRequired,
  handleChange: PropTypes.func.isRequired
};

const StateView = ({ value, handleChange }) => {
  const styleEl = {
    marginRight: "1em",
    display: "inline-block",
    width: "auto"
  };
  const iconStyle = { marginRight: "5px" };

  value = value + ""; // type issue

  return (
    <RadioButtonGroup
      valueSelected={value}
      onChange={handleChange}
      name="notUsedName"
    >
      <RadioButton
        value="6"
        style={styleEl}
        iconStyle={{ ...iconStyle, fill: state_colors[6] }}
        labelStyle={{ color: value === "6" ? state_colors[6] : "" }}
        label="Not&nbsp;ready"
      />
      <RadioButton
        value="2"
        style={styleEl}
        iconStyle={{ ...iconStyle, fill: state_colors[2] }}
        labelStyle={{ color: value === "2" ? state_colors[2] : "" }}
        label="New"
      />
      <RadioButton
        value="4"
        style={styleEl}
        iconStyle={{ ...iconStyle, fill: state_colors[4] }}
        labelStyle={{ color: value === "4" ? state_colors[4] : "" }}
        label="Editor"
      />
      <RadioButton
        value="3"
        style={styleEl}
        iconStyle={{ ...iconStyle, fill: state_colors[3] }}
        labelStyle={{ color: value === "3" ? state_colors[3] : "" }}
        label="Researcher"
      />
      <RadioButton
        value="5"
        style={styleEl}
        iconStyle={{ ...iconStyle, fill: state_colors[5] }}
        labelStyle={{ color: value === "5" ? state_colors[5] : "" }}
        label="Problem"
      />
      <RadioButton
        value="1"
        style={styleEl}
        iconStyle={{ ...iconStyle, fill: state_colors[1] }}
        labelStyle={{ color: value === "1" ? state_colors[1] : "" }}
        label="Ready"
      />
    </RadioButtonGroup>
  );
};

class StateContainer extends Component {
  state = {
    value: this.props.value || 6,
    dom_key: this.props.dom_key
  };

  static getDerivedStateFromProps(props, state) {
    if (props.dom_key !== state.dom_key) {
      return {
        value: props.value || 6,
        dom_key: props.dom_key
      };
    }
    return null;
  }

  handleChange = event => {
    const value = event.target.value;
    this.setState(
      { value },
      this.props.handleChange(this.props.data_id, value)
    );
  };

  render() {
    return (
      <StateView value={this.state.value} handleChange={this.handleChange} />
    );
  }
}

StateContainer.propTypes = propTypes;

export default StateContainer;
