//https://stackoverflow.com/questions/43944157/access-redux-store-in-javascript-function

// methods isSuperAdmin, isEditorPlus ... are not safe if used without connect - they don't update state. Try not use them.

import store from "../store";

export function hasAccessLevel(level, type) {
  const { user_data } = store.getState();
  const { rights } = user_data;

  if (!rights) return false;

  const rights_db = {
    event: rights.venue,
    events_home: rights.chapter,
    district: rights.chapter,
    event_type: rights.venue,
    feature_type: rights.feature,
    ...rights
  };

  if (!rights_db[type]) {
    console.error("Unknown right in fn hasAccessLevel : " + type);
  }

  return rights_db[type] >= level ? true : false;
}

export function isSuperAdmin() {
  const { user_data } = store.getState();
  const { group_id } = user_data;

  if (!group_id) return false;

  return group_id === 4;
}

export function isEditorPlus() {
  const { user_data } = store.getState();
  const { group_id } = user_data;

  if (!group_id) return false;

  return group_id === 303;
}

export function isEditor() {
  const { user_data } = store.getState();
  const { group_id } = user_data;
  if (!group_id) return false;
  return group_id === 3;
}

/* presumbly will never need it
export function hasAccessCountry(country_id) {
		const {user_data}			= store.getState()
		const {allowed_countries} 	= user_data

		if (!allowed_countries) return false

		return allowed_countries.findIndex(country_id) > -1
}
export function hasAccessCity(city_id) {
		const {user_data}			= store.getState()
		const {allowed_cities} 		= user_data

		if (!allowed_cities) return false

		return allowed_cities.findIndex(city_id) > -1
}
*/
