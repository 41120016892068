import React, { Component } from "react";
import { connect } from "react-redux";
import FlatButton from "material-ui/FlatButton";
import FileImage from "./FileImage";
import { static_url } from "../../../config/config";
import { RowFlexCenter } from "../../shared/Grid";
import { queueUpdate } from "../../../actions/actions";
import { fetchAfterSave } from "../../../actions/pageControl";

const CityThumbView = ({ img_url, remove_clicked, remove }) => (
  <div>
    {img_url && !remove_clicked && (
      <RowFlexCenter>
        <img
          src={img_url}
          style={{ maxWidth: "100px", height: "auto" }}
          alt=""
        />
        <FlatButton label="Remove City Thumb" onClick={remove} />
      </RowFlexCenter>
    )}
    {img_url && remove_clicked && (
      <div className="pd-h">Image will be removed (click save) </div>
    )}
    {!img_url && (
      <div className="pd-h">
        <FileImage
          name="city_thumb"
          label="select file"
          preview_size={80}
          info="Minimum 570px width & height"
          secondary={true}
        />
      </div>
    )}
  </div>
);

class CityThumbState extends Component {
  constructor(props) {
    super(props);
    this.state = { remove_clicked: false };
  }

  remove = () => {
    const { city_id, dispatch } = this.props;

    const confirm = window.confirm("Removing city thumb. Are you sure?");
    if (!confirm) return;
    this.setState({ remove_clicked: true });

    dispatch(queueUpdate("city", city_id, "city_thumb", null));
    dispatch(fetchAfterSave(true));
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.data_key === nextProps.data_key) return;
    this.setState({
      remove_clicked: false
    });
  }

  render() {
    return (
      <CityThumbView
        img_url={this.props.img_url}
        remove_clicked={this.state.remove_clicked}
        remove={this.remove}
      />
    );
  }
}

//http://www.inyourpocket.com/img/city_thumb/75/270x270.jpg

const mapStateToProps = ({ data }) => {
  const { city_thumb, modified, city_id } = data.city;
  let img_url = null;
  if (city_thumb) {
    img_url =
      static_url + "img/city_thumb/" + city_id + "/270x270.jpg?" + modified;
  }

  return {
    img_url,
    city_id,
    data_key: data.data_key
  };
};

export default connect(mapStateToProps)(CityThumbState);
