import React from "react";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Chip from "material-ui/Chip";
import PropTypes from "prop-types";

import PcCard from "../../shared/PcCard.js";
import { queueUpdate } from "../../../actions/actions";
import { buildObj } from "../../../helpers";
import getVenueParentStructure from "selectors/getVenueParentStructure";
import getCityStructureData from "selectors/getCityStructureData";

// multiple example
// http://thrysoee.dk/material-ui/

const categoryChildren = (venue_parents, multiple_categories = null) => {
  const items = [];

  venue_parents.map(item => {
    const { name, category_id, chapter_id, active } = item;

    if (!category_id) {
      return items.push(
        <MenuItem
          key={"ch" + chapter_id}
          value={null}
          primaryText={(name || "[no name]").toUpperCase()}
          disabled={true}
        />
      );
    } else {
      return items.push(
        <MenuItem
          insetChildren={multiple_categories ? true : false}
          checked={
            multiple_categories && multiple_categories.includes(category_id)
              ? true
              : false
          }
          key={category_id}
          value={category_id}
          primaryText={name}
          style={{ color: active ? "black" : "grey" }}
        />
      );
    }
  });
  return items;
};

const districtChildren = district_list => {
  const items = [];

  items.push(<MenuItem key="empty" value={""} primaryText="-none-" />);

  district_list.map(district => {
    const { name, district_id, active } = district;
    return items.push(
      <MenuItem
        key={district_id}
        value={district_id}
        primaryText={name}
        style={{ color: active ? "black" : "grey" }}
      />
    );
  });
  return items;
};

const categoriesSelector = values => (
  <span>{values.length ? values.length + " items" : null}</span>
);

class ParentsView extends React.PureComponent {
  static propTypes = {
    venue_id: PropTypes.number.isRequired,
    category_id: PropTypes.number.isRequired,
    district_id: PropTypes.number,
    chapter_list: PropTypes.array.isRequired,
    category_list: PropTypes.array.isRequired,
    district_list: PropTypes.array,
    venue_parents: PropTypes.array.isRequired,
    categories: PropTypes.array,
    data_key: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    const {
      venue_id,
      category_id,
      district_id,
      chapter_list,
      category_list,
      district_list,
      categories
    } = props;

    this.state = {
      venue_id,
      category_id,
      district_id,
      chapter_list,
      category_list,
      district_list,
      categories
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data_key === this.props.data_key) return;

    const {
      venue_id,
      category_id,
      district_id,
      chapter_list,
      category_list,
      district_list,
      categories
    } = nextProps;

    this.setState({
      venue_id,
      category_id,
      district_id,
      chapter_list,
      category_list,
      district_list,
      categories
    });
  }

  handleExtraCategoriesChange = (e, k, categories) => {
    this.handleChange("categories", categories);
  };

  handleExtraCategoryRemove = category_id => {
    const categories = this.state.categories.filter(c => c !== category_id);
    this.handleChange("categories", categories);
  };

  handleDistrictChange = (e, k, val) => {
    this.handleChange("district_id", val || null); // || null : convert '' to null
  };

  handleCategoryChange = (e, k, category_id) => {
    const { category_list } = this.props;

    const category = category_list.find(val => val.category_id === category_id);
    const { chapter_id } = category;
    this.setState({
      category_id,
      chapter_id
    });
    this.handleChange("category_id", category_id);
    this.handleChange("chapter_id", chapter_id);
  };

  handleChange(key, val) {
    const { venue_id, handleModify } = this.props;
    const newItem = buildObj(key, val);
    this.setState(newItem);
    handleModify(venue_id, key, val);
  }

  render() {
    const { category_list, venue_parents, district_list } = this.props;
    const { category_id, categories, district_id } = this.state;

    return (
      <PcCard cardTitle="Display In" id="card_parents">
        <div className="flex-wrapper">
          <SelectField
            floatingLabelText="Main Category"
            value={category_id}
            onChange={this.handleCategoryChange}
            className="flex-item"
            autoWidth={true}
          >
            {categoryChildren(venue_parents, category_list)}
          </SelectField>

          {district_list.length > 0 && (
            <SelectField
              floatingLabelText="District"
              value={district_id}
              onChange={this.handleDistrictChange}
              className="flex-item"
              autoWidth={true}
            >
              {districtChildren(district_list)}
            </SelectField>
          )}
        </div>

        <div className="row-flex">
          <SelectField
            floatingLabelText="Additional categories"
            value={categories}
            onChange={this.handleExtraCategoriesChange}
            multiple={true}
            selectionRenderer={categoriesSelector}
            className="flex-item"
            autoWidth={true}
          >
            {categoryChildren(venue_parents, categories)}
          </SelectField>

          {categories &&
            categories.map(category_id => {
              const category = category_list.find(
                category => category.category_id === category_id
              );
              if (!category) return null;
              return (
                <Chip
                  style={{ margin: "2px" }}
                  onRequestDelete={() =>
                    this.handleExtraCategoryRemove(category_id)
                  }
                  key={category_id}
                >
                  {category.name}
                </Chip>
              );
            })}
        </div>
      </PcCard>
    );
  }
}

function mapStateToProps({ data, data_city }) {
  const {
    city_id,
    category_id,
    categories,
    district_id,
    venue_id
  } = data.venue;

  //const data_city_arr = data_city.find(val=>val.city_id === city_id);

  //const data_city_item = (data_city_arr) ? data_city_arr.data : {chapter_list:[], category_list:[], district_list:[]}

  const { chapter_list, category_list, district_list } = getCityStructureData({
    data_city,
    city_id
  });

  return {
    city_id,
    category_id,
    venue_id,
    district_id,
    chapter_list,
    category_list,
    district_list,
    venue_parents:
      getVenueParentStructure({ chapter_list, category_list }) || [], // chapter_list + category list all
    categories: categories,
    data_key: data.data_key
  };
}

function mapDispatchToProps(dispatch) {
  return {
    handleModify: (venue_id, key, value) => {
      dispatch(queueUpdate("venue", venue_id, key, value));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParentsView);
