// @flow

//import { is_debug } from "../config/config";
import {
  indicatorOn,
  indicatorOffDelayed,
  loadData,
  apiFetchError
} from "./actions";

import { leloFetch } from "../helpers";

type t_mapRouteToApiParams = {
  page_type: string,
  page_id: number,
  api_call: string
};

const fetchPageData = ({
  page_type,
  page_id,
  api_call
}: t_mapRouteToApiParams) => {
  return (dispatch: *) => {
    const now = new Date();
    const hour_mark =
      now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
    const data_key = page_type + page_id + "|" + hour_mark;

    dispatch(indicatorOn());
    leloFetch(api_call, { timeout: 10000 }).then(
      data_in => {
        if (data_in.error) {
          dispatch(apiFetchError(JSON.stringify(data_in.error)));
        }
        const data = { ...data_in, data_key, page_type, page_id };
        dispatch(loadData(data));
        dispatch(indicatorOffDelayed());
      },
      error => {
        dispatch(apiFetchError(error));
        dispatch(indicatorOffDelayed());
      }
    );
  };
};

export default fetchPageData;
