import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import PcCard from "../PcCard.js";
import Gallery from "./Gallery";
import Video from "./Video";
import Figure from "./Figure";
import MediaTypeSelector from "./MediaTypeSelector";

import { Row, CellHalf } from "../../shared/Grid";

const prop_types = {
  data_type: PropTypes.string.isRequired,
  data_id: PropTypes.number.isRequired
};

class CardImagesWithState extends Component {
  init(props) {
    this.setState({ ...props });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.unique !== this.props.unique) this.init(nextProps);
  }

  handleImagesChange = data_change => {
    this.setState(data_change);
  };

  render() {
    const { data_type, data_id } = this.props;

    const {
      top_figure_type,
      gallery,
      video_json,
      video_link,
      img_figure,
      img_figure_desc,
      img_figure_copyright,
      img_figure_copyright_url
    } = this.state;

    const { handleImagesChange } = this;

    const type = top_figure_type;

    return (
      <PcCard cardTitle="Images Text" id="card_images_text">
        <MediaTypeSelector
          data_type={data_type}
          data_id={data_id}
          type={type}
          handleImagesChange={handleImagesChange}
        />

        <Row>
          {(type === 1 || type === 2 || type === 3) && (
            <CellHalf>
              <Gallery
                gallery={gallery}
                only_first_active={type === 1 ? true : false}
                handleImagesChange={handleImagesChange}
              />
            </CellHalf>
          )}

          {(type === 3 || type === 4) && (
            <CellHalf>
              <Video
                video_json={video_json}
                video_link={video_link}
                data_type={data_type}
                data_id={data_id}
                handleImagesChange={handleImagesChange}
              />
            </CellHalf>
          )}

          {type === 5 && (
            <Figure
              img_figure={img_figure}
              img_figure_desc={img_figure_desc}
              img_figure_copyright={img_figure_copyright}
              img_figure_copyright_url={img_figure_copyright_url}
              data_type={data_type}
              data_id={data_id}
              handleImagesChange={handleImagesChange}
            />
          )}
        </Row>
      </PcCard>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const el = state.data[ownProps.data_type];

  const { data_key, gallery } = state.data;

  let video_json = el.video_json ? el.video_json : null;
  if (!video_json && el.video_data) video_json = JSON.parse(el.video_data);

  return {
    data_type: ownProps.data_type,
    data_id: ownProps.data_id,
    unique: data_key,
    gallery: gallery || [],
    top_figure_type: el.top_figure_type || 1,
    video_json,
    video_link: el.video_link,
    img_figure: el.img_figure,
    img_figure_copyright: el.img_figure_copyright,
    img_figure_copyright_url: el.img_figure_copyright_url,
    img_figure_desc: el.img_figure_desc
  };
};

const CardImages = connect(mapStateToProps)(CardImagesWithState);

CardImages.propTypes = prop_types;

export default CardImages;
