import React, { Component } from "react";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";
import { queueUpdate } from "../../actions/actions.js";

const CategoryParentView = ({ parent_id, category_list, onChange }) => (
  <SelectField
    floatingLabelText="parent category"
    value={parent_id}
    onChange={onChange}
    autoWidth={true}
  >
    <MenuItem value={0} primaryText="-none-" />

    {category_list.map((item, i) => (
      <MenuItem value={item.category_id} primaryText={item.name} key={i} />
    ))}
  </SelectField>
);

class CategoryParentState extends Component {
  state = { parent_id: this.props.parent_id };

  handleChange(event, index, value) {
    this.setState({ parent_id: value || 0 }, () =>
      this.props.handleChange(value)
    );
  }

  prepareCategoryList(category_list) {
    return category_list.filter(
      item => !item.parent_id && item.category_id !== this.props.category_id
    );
  }

  render() {
    const category_list = this.prepareCategoryList(
      this.props.category_list || []
    );
    return (
      <CategoryParentView
        parent_id={this.state.parent_id}
        category_list={category_list}
        onChange={this.handleChange.bind(this)}
      />
    );
  }
}

const mapStateToProps = state => {
  const d = state.data;
  const c = d.category;
  return {
    name: c.name,
    category_id: c.category_id,
    parent_id: c.parent_id,
    category_list: state.data.category_list,
    key: "category" + c.category_id
  };
};

const mapDispatchToProps = (dispatch, { category_id }) => {
  return {
    handleChange: value => {
      const val = value > 0 ? value : null;
      dispatch(queueUpdate("category", category_id, "parent_id", val));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoryParentState);
