import React, { Component } from "react";
import PcCard from "../shared/PcCard.js";
import PcSearch from "../shared/PcSearch";
import EditableList from "../shared/EditableList";
import { connect } from "react-redux";
import { queueUpdate } from "../../actions/actions.js";
import { Row, CellHalf } from "../shared/Grid.js";

class VenueConnectedState extends Component {
  constructor(props) {
    super();
    this.state = {
      items: props.connected_venues || [],
      value: ""
    };
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // added new item
  addItem(data) {
    const items = [...this.state.items, data];
    this.setState({ items });
    this.props.modifyIds(items, this.props.venue_id);
  }

  handleChange(items) {
    this.setState({ items });
    this.props.modifyIds(items, this.props.venue_id);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.venue_id === this.props.venue_id) return;

    this.setState({
      items: nextProps.connected_venues || []
    });
  }

  render() {
    const { country_id, country_name, venue_id } = this.props;

    return (
      <PcCard cardTitle="Connected Venues" id="card_connected_venues">
        <Row>
          <CellHalf>
            {/*<PcSearch narrow_field_key='country_id' narrow_field_id={country_id} table='venue' onResultClick={this.addItem} tip={"venues in "+country_name} search_id={'venue'+venue_id}/>*/}
            <PcSearch
              narrow_field_key="country_id"
              narrow_field_id={country_id}
              table="venue"
              onResultClick={this.addItem}
              tip={"venues in " + country_name}
              search_id={"venue" + venue_id}
              exclude={[venue_id]}
            />
          </CellHalf>
          <CellHalf>
            {this.state.items.length > 0 && (
              <EditableList
                items={this.state.items}
                is_sortable={true}
                handleChange={this.handleChange}
              />
            )}
          </CellHalf>
        </Row>
      </PcCard>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modifyIds: (items, venue_id) => {
      const venue_ids = items.map(item => {
        return item.venue_id;
      });
      dispatch(
        queueUpdate("venue", venue_id, "venue_ids", [...venue_ids])
      ); /*...venue_ids will make them uniqe */
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const venue_id = ownProps.venue_id || state.data.page_id;

  return {
    connected_venues: state.data.venue.connected_venues || [],
    country_name: state.data.country.name,
    country_id: state.data.country.country_id,
    venue_id
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VenueConnectedState);
