import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PcCard from "../shared/PcCard";
import PcAppBar from "../shared/TopBar/PcAppBar";
import { RowFlexTop } from "../shared/Grid";
import CitySelect from "../shared/Drawer/CitySelect";
import { isSuperAdmin } from "../../helpers/hasAccess";
import getCityMenu from "selectors/getCityMenu";

const Home = ({ comments, browse_list }) => {
  const is_superadmin = isSuperAdmin();

  const title = "Pocket Content";

  document.title = title;

  return (
    <div>
      <PcAppBar title={title} no_drawer={true} no_save={true} />
      <div className="pd pc-content-wide">
        <RowFlexTop style={{ flexDirection: "row" }}>
          <div style={{ width: "400px" }}>
            <PcCard cardTitle="Comments">
              {comments.length === 0 && (
                <div className="pd">No pending comments</div>
              )}
              {comments.length > 0 && (
                <div>
                  {comments.map(({ url, name, subtitle }, key) => (
                    <div key={key} className="pd-h">
                      <Link to={"/" + url} className="the_link">
                        {name}
                      </Link>

                      <div>{subtitle}</div>
                    </div>
                  ))}
                </div>
              )}
            </PcCard>
          </div>

          {/* Browse */}

          <div style={{ width: "400px" }}>
            <PcCard cardTitle="Browse content">
              {!browse_list.length && <span>No access</span>}
              {browse_list.length > 10 && <CitySelect />}
              {browse_list.length <= 11 && (
                <div>
                  {browse_list.map(item => (
                    <div key={item.value} className="pd-h">
                      <Link to={item.link} className="the_link">
                        {!item.is_country && <span> - </span>}
                        {item.name}
                      </Link>
                    </div>
                  ))}
                </div>
              )}
            </PcCard>
          </div>

          <div style={{ width: "400px" }}>
            <PcCard cardTitle="Services">
              <div className="pd-h">
                <a
                  href="http://pc.inyourpocket.com/banner/"
                  className="the_link"
                >
                  Banner Managment System
                </a>
              </div>
              <div>
                <a
                  href="https://sites.google.com/a/inyourpocket.com/licensee-zone/?pli=1"
                  className="the_link"
                >
                  IYP Resources
                </a>
              </div>
              <div className="pd-h">
                <a href="/affiliate" className="the_link">
                  Affiliate widgets
                </a>
              </div>
            </PcCard>

            {is_superadmin && (
              <div>
                <PcCard cardTitle="PC users">
                  <div className="pd-h">
                    <Link to="/pc_user_list/1" className="the_link">
                      Users
                    </Link>
                  </div>
                  <div className="pd-h">
                    <Link to="/pc_group_list/1" className="the_link">
                      Groups
                    </Link>
                  </div>
                </PcCard>

                <PcCard cardTitle="Homepage articles">
                  <div className="pd-h">
                    <Link to="/homepage" className="the_link">
                      Manage
                    </Link>
                  </div>
                </PcCard>
              </div>
            )}
          </div>
        </RowFlexTop>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    comments: state.data.comments || [],
    browse_list: getCityMenu(state) || []
  };
};

class HomeContainer extends React.Component {
  render() {
    const { comments, browse_list } = this.props;
    return <Home comments={comments} browse_list={browse_list} />;
  }
}

export default connect(mapStateToProps)(HomeContainer);
