/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import PcCard from "../shared/PcCard.js";
import PcTextField from "../shared/PcTextField.js";
import FlatButton from "material-ui/FlatButton";
import { Row, CellHalf, CellFull } from "../shared/Grid";

import { modifyWrapper } from "../../core/ModifyWrapper";

const MTextField = modifyWrapper(PcTextField, "venue");

class ContactWithState extends Component {
  constructor(props) {
    super(props);
    this.state = { show_all: false, ...props };
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(nextState.show_all === this.state.show_all);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.venue_id === this.state.venue_id) return;
    this.setState({ show_all: false, ...nextProps });
  }

  handleClick() {
    this.setState({
      show_all: true
    });
  }

  render() {
    return <ContactView {...this.state} handleClick={this.handleClick} />;
  }
}

const ContactView = ({ fax, phone_2, skype, show_all, handleClick }) => (
  <PcCard cardTitle="Contact" id="card_contact">
    <Row>
      <CellHalf>
        <Row>
          <CellHalf>
            <MTextField name="phone" floatingLabelText="phone" />
          </CellHalf>
          <CellHalf>
            <MTextField name="email" floatingLabelText="email" type="email" />
          </CellHalf>

          {(show_all || fax) && (
            <CellHalf>
              <MTextField name="fax" floatingLabelText="fax" />
            </CellHalf>
          )}

          {(show_all || skype) && (
            <CellHalf>
              <MTextField name="skype" floatingLabelText="skype" />
            </CellHalf>
          )}
        </Row>
      </CellHalf>

      <CellHalf>
        <Row>
          <CellHalf>
            <MTextField name="contact" floatingLabelText="contact" />
          </CellHalf>

          {(show_all || phone_2) && (
            <CellHalf>
              <MTextField name="phone_2" floatingLabelText="2nd phone" />
            </CellHalf>
          )}

          {!show_all && (
            <CellHalf>
              <div className="button-wrapper">
                <FlatButton label="Show more contact" onClick={handleClick} />
              </div>
            </CellHalf>
          )}
        </Row>
      </CellHalf>
    </Row>
  </PcCard>
);
/*enf fo ContactView*/

function mapStateToProps(state) {
  const {
    contact,
    email,
    fax,
    phone,
    phone_2,
    skype,
    venue_id
  } = state.data.venue;

  // values used only to decide if display on init
  return {
    fax,
    phone_2,
    skype,
    venue_id
  };
}

export default connect(mapStateToProps)(ContactWithState);

//export default ContactWithState;
