import React from "react";
import { connect } from "react-redux";
import PcAppBar from "../shared/TopBar/PcAppBar";

const VenueAppBar = ({ name, custom_save_component }) => (
  <PcAppBar
    title={name}
    type="venue"
    custom_save_component={custom_save_component}
  />
);

const mapStateToProps = ({ data }, { custom_save_component }) => {
  const { name, archived } = data.venue;

  return {
    name: archived ? "[ARCHIVED] " + name : name,
    custom_save_component
  };
};

export default connect(mapStateToProps)(VenueAppBar);
