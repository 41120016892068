// @flow

import React from "react";
import { connect } from "react-redux";

const mapStateToProps = ({ user_data }, ownProps) => {
  return {
    user_data,
    ...ownProps
  };
};

export const withAccessSuperadmin = (WrappedComponent: any) => {
  const withAccessLimitet = props => {
    const { user_data, ...the_props } = props;
    return props.user_data.group_id === 4 ? (
      <WrappedComponent {...the_props} />
    ) : null;
  };
  return connect(mapStateToProps)(withAccessLimitet);
};

export const withAccessLogged = (WrappedComponent: any) => {
  const withAccessLimitet = props => {
    const { user_data, ...the_props } = props;
    return props.user_data.group_id ? (
      <WrappedComponent {...the_props} />
    ) : null;
  };
  return connect(mapStateToProps)(withAccessLimitet);
};
