export default [
  ["Settings", "breadcrumbs"],
  ["Description", "card_description"],
  ["Pdf", "card_pdf"],
  ["Cover", "card_cover"],
  ["Image thumb", "card_city_thumb"],
  ["Images text", "card_images_text"],
  ["Images background", "card_images_bg"],
  ["Menu", "card_menu"],
  ["Language", "card_lang"],
  ["Meta tags", "card_meta_tags"],
  ["MailChimp", "card_mailchimp"],
  ["Contact", "card_contact"],
  ["Export", "card_export"],
  ["Affilate", "card_affilate"],
  ["Redirect", "card_redirect"]
];
