export const setForceFetch = value => {
  return {
    type: "FORCE_FETCH",
    value
  };
};

export const fetchAfterSave = value => {
  return {
    type: "FORCE_FETCH_QUEUE",
    value
  };
};

export const addNotLoggedGoLink = url_in => {
  const url = url_in.replace(/^\//, ""); //remove  slash at begining

  return {
    type: "ADD_NOT_LOGGED_GO_LINK",
    url
  };
};

export const removeNotLoggedDoLink = () => {
  return {
    type: "REMOVE_NOT_LOGGED_GO_LINK"
  };
};
