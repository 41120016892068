import React from "react";
import { connect } from "react-redux";
import FlatButton from "material-ui/FlatButton";
import { queueUpdate } from "../../../actions/actions.js";
import { fetchAfterSave } from "../../../actions/pageControl.js";
import { snackSuccess } from "../../../actions/snacks.js";

const ClearColorsContainer = ({ item_list, type, dispatch }) => {
  const key = type + "_id";

  return (
    <FlatButton
      className="pull-right"
      label="Clean Colors"
      onClick={() => {
        item_list.map(item =>
          dispatch(queueUpdate(type, item[key], "state", null))
        );
        dispatch(fetchAfterSave(true));
        dispatch(snackSuccess("Clik SAVE to apply changes"));
      }}
    />
  );
};

//const ClearColors = connect(({user_data})=> user_data)(ClearColorsContainer)
export default connect()(ClearColorsContainer);
