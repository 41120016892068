import React, { Component } from "react";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import TextField from "material-ui/TextField";
import Paper from "material-ui/Paper";
import CircularProgress from "material-ui/CircularProgress";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { leloFetch } from "../../helpers";

const prop_types = {
  table: PropTypes.string.isRequired,
  narrow_field_key: PropTypes.string.isRequired,
  narrow_field_id: PropTypes.number.isRequired,
  onResultClick: PropTypes.func.isRequired,
  search_id: PropTypes.string.isRequired,
  order_by_city: PropTypes.number,
  exclude: PropTypes.array
};

const state_default = {
  dataSource: {},
  value: "",
  counter: 0,
  results_saved: []
};

class PcSearch extends Component {
  state = state_default;

  /*
	constructor(props) {
		super(props)
		this.handleUpdateInput = this.handleUpdateInput.bind(this)
		this.state_default = this.state = {
			dataSource		: {}	,
			value 			: ''	,
			counter 		: 0		,
			results_saved 	: []
		}
	}
*/

  componentWillReceiveProps(nextProps) {
    if (nextProps.search_id === this.props.search_id) return;
    this.setState(state_default);
  }

  // handle click outside list
  clickOut = event => {
    setTimeout(
      function() {
        this.setState({ value: "", dataSource: {} });
      }.bind(this),
      500
    );
  };

  /*
	componentWillUpdate(nextProps, nextState) {

		const current 	= this.state.dataSource.length
		const next 		= nextState.dataSource.length

		if (!current && next)	{
			document.querySelector("html").addEventListener('click', this.clickOut)
		} else if (current && !next) {
			document.querySelector("html").removeEventListener('click', this.clickOut)
		}

	}


	componentWillUnmount() {
		document.querySelector("html").removeEventListener('click', this.clickOut)
	}
*/

  handleUpdateInput = e => {
    const value = e.target.value;
    const local_counter = this.state.counter + 1;

    if (!value) {
      this.setState({
        dataSource: {},
        value: ""
      });
      return;
    }

    const cached = this.state.results_saved.find(item => item.query === value);

    if (cached) {
      this.setState({
        dataSource: cached.response,
        value: value,
        show_indictor: false
      });
      return;
    }

    let new_state = {
      counter: local_counter,
      value: value
    };

    this.setState(new_state, this.searchDelay(local_counter, value));
  };

  searchDelay = debounce((local_counter, value) => {
    this.performSearch(local_counter, value);
  }, 500);

  performSearch(local_counter, value) {
    if (local_counter < this.state.counter) return;

    const { table, narrow_field_key, narrow_field_id } = this.props;

    let url =
      "search/type/" +
      table +
      "/" +
      narrow_field_key +
      "/" +
      narrow_field_id +
      "/query/" +
      value +
      "?counter=" +
      local_counter +
      (this.props.order_by_city
        ? "&order_by_city=" + this.props.order_by_city
        : "");

    this.setState({ show_indictor: true });

    leloFetch(url).then(responseJson => {
      const { exclude } = this.props;

      //console.log(exclude);
      //console.log(responseJson);
      //console.log(exclude);

      const data = !exclude
        ? responseJson
        : responseJson.filter(item => !exclude.includes(item[table + "_id"]));

      const preserve = {
        query: value,
        response: data
      };

      let new_state = {
        results_saved: this.state.results_saved.concat(preserve)
      };

      // due to asynchronius
      if (local_counter === this.state.counter) {
        new_state = {
          ...new_state,
          dataSource: data,
          show_indictor: false
        };
      }

      this.setState(new_state);
    });
  }

  doDisplayItem(searchText, inListText) {
    if (
      searchText !== "" &&
      inListText.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    )
      return true;

    return false;
  }

  renderList() {
    if (!this.state.value || !this.state.dataSource.length) {
      return;
    }

    const paperStyle = {
      display: "inline-block",
      minWidth: "300px"
    };

    const subTitleStyle = {
      display: "block",
      fontSize: "0.7em",
      marginTop: "-8px",
      marginBottom: "2px",
      lineHeight: 0,
      left: 0,
      top: 0,
      minWidth: "200px"
    };

    const wrapperStyle = {
      position: "relative",
      lineHeight: 0,
      width: "1px",
      height: "1px",
      marginTop: "-0.3em",
      zIndex: "1000"
    };

    var rows = [];

    const numrows = this.state.dataSource.length;

    for (let i = 0; i < numrows; i++) {
      const item = this.state.dataSource[i];

      const value = this.state.value;

      if (!this.doDisplayItem(value, item.name)) continue;

      rows.push(
        <MenuItem onClick={() => this.handleonClick(item)} key={i}>
          {item.name}
          <small style={subTitleStyle}>{item.subtitle}</small>
        </MenuItem>
      );
    }

    if (!rows.length) return;

    return (
      <div style={wrapperStyle}>
        <Paper style={paperStyle} zDepth={3}>
          <Menu disableAutoFocus={true}>{rows}</Menu>
        </Paper>
      </div>
    );
  }

  handleonClick(data) {
    this.props.onResultClick(data);

    this.setState({
      dataSource: {},

      value: ""
    });
  }

  render() {
    return (
      <div>
        <TextField
          hintText="Type to search"
          onChange={this.handleUpdateInput}
          fullWidth={true}
          key="search_field"
          value={this.state.value}
          floatingLabelText="search"
          onBlur={this.clickOut}
        />

        {this.renderList()}

        {this.props.tip && <span className="pull-right">{this.props.tip}</span>}

        <div style={{ position: "relative" }}>
          {this.state.show_indictor && (
            <CircularProgress
              size={10}
              thickness={2}
              style={{ position: "absolute", left: 0, top: 0 }}
            />
          )}
        </div>
      </div>
    );
  }
}

PcSearch.propTypes = prop_types;

const mapStateToProps = ({ data }) => {
  if (!data.city) return {};
  return { order_by_city: data.city.city_id };
};

export default connect(mapStateToProps)(PcSearch);
