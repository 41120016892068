import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import PropTypes from "prop-types";
import Map from "./Map.js";

const propTypes = {
  coords: PropTypes.object,
  center: PropTypes.object, // if item has not coords, lets deliver what map should show
  zoom: PropTypes.number,
  address_text: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

const ButtonOpen = ({ handleOpen, type }) => {
  if (type === "modiffy")
    return <FlatButton label="Modiffy coordinates" onClick={handleOpen} />;
  return (
    <FlatButton
      label="Define coordinates"
      secondary={true}
      onClick={handleOpen}
    />
  );
};

const input_styles = {
  padding: "2px",
  marginRight: "10px"
};

const Actions = ({
  closeDiscard,
  closeSave,
  save_disabled,
  coords,
  handleChange
}) => (
  <span>
    <div style={{ marginLeft: 20, float: "left" }}>
      <input
        type="number"
        step="any"
        value={coords && coords.lat ? coords.lat : ""}
        placeholder="lat"
        style={input_styles}
        onChange={ev =>
          handleChange({ lat: parseFloat(ev.target.value), lng: coords.lng })
        }
      />
      <input
        type="number"
        step="any"
        value={coords && coords.lng ? coords.lng : ""}
        placeholder="lng"
        style={input_styles}
        onChange={ev =>
          handleChange({ lng: parseFloat(ev.target.value), lat: coords.lat })
        }
      />
    </div>
    <FlatButton label="Cancel" primary={true} onClick={closeDiscard} />
    <FlatButton
      label="Apply"
      primary={true}
      keyboardFocused={true}
      onClick={closeSave}
      disabled={save_disabled}
    />
  </span>
);

const MapDialog = props => (
  <Dialog
    title="Move the marker to the correct location"
    actions={
      <Actions
        closeDiscard={props.closeDiscard}
        closeSave={props.closeSave}
        save_disabled={props.save_disabled}
        coords={props.coords}
        handleChange={props.handleCoordsChange}
      />
    }
    modal={false}
    open={props.flag_open}
    autoScrollBodyContent={true}
  >
    <Map
      center={props.center}
      coords={props.coords}
      zoom={props.zoom}
      handleCoordsChange={props.handleCoordsChange}
      handleZoomChange={props.handleZoomChange}
      address_text={props.address_text}
      handleReset={props.handleReset}
    />
  </Dialog>
);

class MapLocation extends Component {
  constructor(props) {
    super(props);
    this.closeDiscard = this.closeDiscard.bind(this);
    this.closeSave = this.closeSave.bind(this);
    this.handleCoordsChange = this.handleCoordsChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
  }

  // norice that the map  center may be set even if we don't know matker coords (eg is taken from city coords)
  init(props) {
    const suchowola = { lat: 53.579444, lng: 23.101667 };

    // user may manually remove coord now.
    const coords =
      props.coords && props.coords.lat && props.coords.lng
        ? props.coords
        : null;

    this.setState({
      flag_open: false,
      center: props.center || coords || suchowola,
      coords,
      zoom: props.zoom || (props.coords ? 10 : 2),
      reset_clicked: false,
      button_type: props.coords ? "modify" : "create"
    });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  handleCoordsChange(coords) {
    this.setState({ coords: coords });
  }

  handleZoomChange(zoom) {
    this.setState({ zoom: zoom });
  }

  handleOpen() {
    this.setState({ flag_open: true });
  }

  closeSave() {
    this.setState(
      {
        button_type: this.state.coords ? "modiffy" : "create",
        flag_open: false
      },
      () => {
        // make sure both coords are added (may be changed manually now)
        const coords =
          !this.state.coords.lat || !this.state.coords.lng
            ? null
            : this.state.coords;
        this.props.handleChange(coords);
      }
    );
  }

  closeDiscard() {
    this.setState({
      flag_open: false
    });
  }

  handleReset() {
    this.setState({
      reset_clicked: true,
      coords: null
    });
  }

  render() {
    const {
      coords,
      reset_clicked,
      button_type,
      flag_open,
      center,
      zoom,
      geo_text
    } = this.state;

    const save_disabled = !coords && !reset_clicked ? true : false;

    const {
      handleOpen,
      closeDiscard,
      closeSave,
      handleCoordsChange,
      handleReset
    } = this;

    return (
      <span>
        <ButtonOpen handleOpen={handleOpen} type={button_type} />
        <MapDialog
          flag_open={flag_open}
          center={center}
          coords={coords}
          zoom={zoom}
          geo_text={geo_text}
          closeDiscard={closeDiscard}
          closeSave={closeSave}
          handleCoordsChange={handleCoordsChange}
          handleReset={handleReset}
          address_text={this.props.address_text}
          save_disabled={save_disabled}
        />
      </span>
    );
  }
}

MapLocation.propTypes = propTypes;

export default MapLocation;
