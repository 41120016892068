const userData = (state = {}, action) => {
  switch (action.type) {
    case "LOGGED_IN":
      return action.user_data;

    case "LOGGED_OUT":
      return {};

    case "SET_LOGIN_ERROR_MESSAGE":
      return { login_error: action.msg_error };

    default:
      return state;
  }
};

export default userData;
