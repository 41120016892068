import React, { Component } from "react";
import PropTypes from "prop-types";

//import	MenuItem	from 'material-ui/MenuItem'
//import	SelectField	from 'material-ui/SelectField'
import FlatButton from "material-ui/FlatButton";
import DatePicker from "material-ui/DatePicker";

import { RowFlex } from "../../shared/Grid";

import { formatDateDb } from "../../../helpers";
import OpenHoursDay from "./OpenGridDay.js";

const propTypes = {
  //open_daily, open_mon.., open_sun, date_start, date_stop
  line_number: PropTypes.number.isRequired,
  multi: PropTypes.bool,
  handleItemChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

class OpenGridLine extends Component {
  handleDowChange = (dow, value) => {
    this.props.handleItemChange(dow, value, this.props.line_number);
  };

  handleDateChange = (key, value) => {
    this.props.handleItemChange(
      key,
      formatDateDb(value),
      this.props.line_number
    );
  };

  handleRemove = () => {
    this.props.onRemove(this.props.line_number);
  };

  renderDateRow() {
    //const months_def	= ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    //const day_style	 	= {width: '90px', marginRight: '30px'};

    //const month_style 	= {width: '110px', marginRight: '30px'};

    const button_remove = this.props.multi ? (
      <FlatButton label="remove date range" onClick={this.handleRemove} />
    ) : null;

    return (
      <div className="row">
        <div className="col-xs-12">
          {/* day start */}

          <RowFlex>
            <DatePicker
              floatingLabelText="date start"
              autoOk={true}
              value={
                this.props.date_start ? new Date(this.props.date_start) : null
              }
              onChange={(something, val) =>
                this.handleDateChange("date_start", val)
              }
            />

            <DatePicker
              floatingLabelText="date end"
              autoOk={true}
              value={
                this.props.date_stop ? new Date(this.props.date_stop) : null
              }
              onChange={(something, val) =>
                this.handleDateChange("date_stop", val)
              }
            />

            {/* day end */}

            {button_remove}
          </RowFlex>
        </div>
      </div>
    );
  }

  render() {
    const dow_def = ["daily", "mon", "tue", "wed", "thu", "fri", "sat", "sun"];

    const dow_full_def = [
      "Daily",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ];

    let days_items = [];

    for (let i = 0; i < 8; i++) {
      const dow = dow_def[i];

      const dow_full = dow_full_def[i];

      const value = this.props["open_" + dow];

      days_items.push(
        <OpenHoursDay
          dow={dow}
          dow_full={dow_full}
          value={value}
          key={i}
          handleDowChange={value => this.handleDowChange("open_" + dow, value)}
        />
      );
    }

    const date_row = this.props.multi ? this.renderDateRow() : null;

    const spacer =
      this.props.line_number > 0 ? (
        <div>
          <br />
          <br />
        </div>
      ) : null;

    return (
      <div className="row" key={this.props.line_number}>
        {spacer}

        {date_row}

        {days_items}
      </div>
    );
  }
}

OpenGridLine.propTypes = propTypes;

export default OpenGridLine;
