// @flow

import { connect } from "react-redux";

import AffiliateVenues from './AffiliateVenues'
import { queueUpdateAuto } from "../../actions/actions";

const mapStateToProps = ({ data }) => {
  const { affiliate_widget } = data;
  const { country_id, venuelist_descriptive } = affiliate_widget;
  return {
    country_id,
    venuelist_descriptive
  };
};

const mapDispatchToProps = dispatch => {
  return {
    queueModifyVenues: venue_list => {
      const venue_ids = venue_list.map(v => v.venue_id);
      dispatch(queueUpdateAuto("display_venue", venue_ids));
    }
  };
};

const AffiliateEditVenues = connect(
  mapStateToProps,
  mapDispatchToProps
)(AffiliateVenues);


export default AffiliateEditVenues;
