import React, { Component } from "react";
import Toggle from "material-ui/Toggle";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { queueUpdate } from "../../actions/actions.js";
import { buildObj } from "../../helpers";
import PcHtmlEditor from "./PcHtmlEditor";

const propTypes = {
  data_type: PropTypes.string.isRequired,
  data_id: PropTypes.number.isRequired
};

const toggle_style = { display: "inline-block", whiteSpace: "nowrap" };

const BodyView = ({
  body,
  body_print,
  body_print_flag,
  dom_key,
  handleChange,
  handleToggle
}) => (
  <div className="row">
    <div className="col-xs-12">
      <div style={toggle_style}>
        <Toggle
          label="Split online/print"
          toggled={body_print_flag ? true : false}
          onToggle={handleToggle}
        />
      </div>

      <div>{body_print_flag && <h3 key="h_online">online</h3>}</div>

      <PcHtmlEditor
        key="body"
        handleChange={val => handleChange("body", val)}
        dom_key={dom_key + "body"}
        value={body}
      />

      {body_print_flag && [
        <h3 key="h_print">print</h3>,
        <PcHtmlEditor
          key="body_print"
          handleChange={val => handleChange("body_print", val)}
          dom_key={dom_key + "body_print"}
          value={body_print}
        />
      ]}
    </div>
  </div>
);

class BodyWithState extends Component {
  constructor(props) {
    super(props);

    const { handleModify, ...p } = props;
    this.state = p;

    this.handleChange = this.handleChange.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
  }

  componentWillReceiveProps(props) {
    const { data_type, data_id } = this.state;
    if (data_type === props.data_type && data_id === props.data_id) return;
    const { handleModify, ...p } = props;
    this.setState(p);
  }

  handleChange(key, val) {
    this.setState(buildObj(key, val));
    this.props.handleModify(key, val);
  }

  handleToggle() {
    this.setState(
      prevState => {
        return { body_print_flag: prevState.body_print_flag ? null : 1 };
      },
      () =>
        this.props.handleModify("body_print_flag", this.state.body_print_flag)
    );
  }

  render() {
    const { body, body_print, body_print_flag, dom_key } = this.state;

    return (
      <BodyView
        body_print_flag={body_print_flag}
        body={body}
        body_print={body_print}
        handleChange={this.handleChange}
        handleToggle={this.handleToggle}
        dom_key={dom_key}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { data_type, data_id } = ownProps;

  const { body, body_print, body_print_flag } = state.data[data_type];

  return {
    body: body || "",
    body_print: body_print || "",
    body_print_flag: body_print_flag ? true : false,
    dom_key: data_type + data_id
  };
};

const mapDispatchToProps = (dispatch, { data_type, data_id }) => {
  return {
    handleModify: (key, value) => {
      dispatch(queueUpdate(data_type, data_id, key, value));
    }
  };
};

const BodySplited = connect(
  mapStateToProps,
  mapDispatchToProps
)(BodyWithState);

BodySplited.propTypes = propTypes;

export default BodySplited;
