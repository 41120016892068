import React from "react";
import FlatButton from "material-ui/FlatButton";
import Dialog from "material-ui/Dialog";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import { connect } from "react-redux";
import PcTextField from "../PcTextField";
import addNew from "../../../actions/addNew";

class AddNewFeature extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      city_id: props.city_id
    };
  }

  componentDidMount() {
    window.setTimeout(
      function() {
        const ref = this.refs["name"];
        if (ref) ref.focusInput();
      }.bind(this),
      200
    );
  }

  handleNameChange = name => this.setState({ name });

  handleChangeType = (e, k, p) => {
    this.setState({ city_id: p });
  };

  handleAddNewClick = () => {
    const { name, city_id } = this.state;
    const { country_id } = this.props;
    this.props.handleCloseModal();
    const new_city_id = city_id === -1 ? null : city_id;
    this.props.dispatch(
      addNew("feature", {
        name,
        city_id: new_city_id,
        country_id
      })
    );
  };

  render() {
    return (
      <Dialog
        title="Add new feature"
        actions={[
          <FlatButton
            label="Cancel"
            primary={true}
            onClick={this.props.handleCloseModal}
          />,
          <FlatButton
            label="Add"
            primary={true}
            keyboardFocused={true}
            onClick={this.handleAddNewClick}
            disabled={!this.state.name || !this.state.city_id}
          />
        ]}
        modal={false}
        open={true}
        onRequestClose={this.props.handleCloseModal}
      >
        <PcTextField
          handleChange={this.handleNameChange}
          floatingLabelText="name"
          value={this.state.name}
          ref="name"
        />
        <br />
        <SelectField
          value={this.state.city_id}
          floatingLabelText="parent"
          autoWidth={true}
          onChange={this.handleChangeType}
        >
          <MenuItem value={0} primaryText="select parent" />
          <MenuItem
            value={-1}
            primaryText={"COUNTRY: " + this.props.country_name}
          />
          {this.props.city_list.map(el => (
            <MenuItem
              value={el.city_id}
              primaryText={el.name}
              key={el.city_id}
            />
          ))}
        </SelectField>
      </Dialog>
    );
  }
}

const mapStateToProps = ({ constants, data }) => {
  const country_id = data.country.country_id;
  const country_name = data.country.name;
  const city_id = data.city ? data.city.city_id : 0;
  const city_list =
    constants.city_list.filter(el => el.country_id === country_id) || [];

  return {
    country_id,
    city_id,
    city_list,
    country_name
  };
};

export default connect(mapStateToProps)(AddNewFeature);
