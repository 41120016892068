import React from "react";
import { connect } from "react-redux";
import CitySelect from "../shared/Drawer/CitySelect";
import ChapterSelect from "../shared/Drawer/ChapterSelect";
import EventCardState from "./EventCardState";
import EventCardDate from "./EventCardDate/EventCardDate";
import EventParents from "./EventParents";
import RemoveArchive from "../shared/RemoveArchive";
import EventInfo from "./EventInfo";

import CardConnectedFeatures from "../shared/CardConnectedFeatures";

//import BodySplited	from '../shared/BodySplited'

import PcAppBar from "../shared/TopBar/PcAppBar";
import PcDrawer from "../shared/Drawer/PcDrawer";

import EventTypeSelect from "../shared/Drawer/EventTypeSelect";
import EventList from "../shared/Drawer/EventTypeFilter/EventList";

import BodySplited from "../shared/BodySplited";
import PcCard from "../shared/PcCard";
import CardMetaTags from "../shared/CardMetaTags";
import BreadCrumbs from "../shared/BreadCrumbs";
import EditedBy from "../shared/EditedBy";
import { modifyWrapper } from "../../core/ModifyWrapper";
import PcTextField from "../shared/PcTextField";
import ResearcherComment from "../shared/ResearcherComment";

import EventCardConnectedEvents from "./EventCardConnectedEvents";

import { Row, CellHalf, RowFlex } from "../shared/Grid";
import CardImages from "../shared/Images/CardImages";
import CardImagesBackground from "../shared/Images/CardImagesBackground";
import BackgroundMobile from "../shared/Images/BackgroundMobile";

import Toc from "../shared/Toc";
import toc from "./event_toc";

const MTextField = modifyWrapper(PcTextField, "event");

const EventView = ({ event_id, event_type_id, city_id }) => (
  <div>
    <PcAppBar />

    <PcDrawer>
      <CitySelect city_id={city_id} />
      <ChapterSelect />

      <EventTypeSelect />
      <EventList />
    </PcDrawer>

    <Toc data={toc} />

    <div className="pd pc-content">
      <div className="top-line">
        <BreadCrumbs />
        <div>
          <EditedBy />
        </div>
      </div>

      <EventInfo />

      <EventCardState />

      <PcCard cardTitle="Name" id="card_name">
        <Row>
          <CellHalf>
            <MTextField name="name" floatingLabelText="title" />
          </CellHalf>
          <CellHalf>
            <MTextField name="altitle" floatingLabelText="altitle" />
          </CellHalf>
        </Row>
      </PcCard>

      <PcCard cardTitle="Description" id="card_description">
        <BodySplited data_type="event" data_id={event_id} />
      </PcCard>

      <PcCard cardTitle="Www" id="card_www">
        <RowFlex>
          <MTextField
            name="website"
            fullWidth={false}
            type="url"
            floatingLabelText="Website"
          />
          <MTextField
            name="facebook"
            fullWidth={false}
            type="url"
            floatingLabelText="Facebook"
          />
        </RowFlex>
      </PcCard>

      <PcCard cardTitle="Price & Tickets" id="card_price">
        <RowFlex>
          <MTextField
            name="ticket_link"
            fullWidth={false}
            type="url"
            floatingLabelText="Ticket Link"
          />
          <MTextField
            name="price"
            fullWidth={false}
            floatingLabelText="Price"
          />
        </RowFlex>
      </PcCard>

      <EventCardDate />

      <EventCardConnectedEvents />

      <CardConnectedFeatures data_type="event" data_id={event_id} />

      <EventParents />

      <ResearcherComment type="event" />

      <CardImages data_type="event" data_id={event_id} />

      <CardImagesBackground data_type="venue" data_id={event_id} />

      <PcCard cardTitle="Image App Mobile" id="card_images_app">
        <BackgroundMobile />
      </PcCard>

      <CardMetaTags TextFieldTyped={MTextField} />

      <RemoveArchive />
    </div>
  </div>
);

const mapStateToProps = ({ data }) => {
  const { event_id, event_type_id, city_id } = data.event;

  return {
    event_id,
    event_type_id,
    city_id
  };
};

export default connect(mapStateToProps)(EventView);
