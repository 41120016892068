/* eslint-disable */

import React, { Component } from "react";
import { connect } from "react-redux";
import PcCard from "../shared/PcCard.js";
import PcTextField from "../shared/PcTextField.js";
import FlatButton from "material-ui/FlatButton";
import { Row, CellHalf, CellFull } from "../shared/Grid";

import { modifyWrapper } from "../../core/ModifyWrapper";

const MTextField = modifyWrapper(PcTextField, "venue");

const WwwView = ({
  show_all,
  website,
  facebook,
  instagram,
  twitter,
  youtube,
  flickr,
  social_foursquare,
  handleShowAll
}) => (
  <PcCard cardTitle="WWW" id="card_www">
    <Row>
      <CellHalf>
        <MTextField name="website" floatingLabelText="website" type="url" />
      </CellHalf>

      <CellHalf>
        <MTextField name="facebook" floatingLabelText="facebook" type="url" />
      </CellHalf>

      {(show_all || instagram) && (
        <CellHalf>
          <MTextField
            name="instagram"
            floatingLabelText="instagram"
            type="url"
          />
        </CellHalf>
      )}

      {(show_all || twitter) && (
        <CellHalf>
          <MTextField name="twitter" floatingLabelText="twitter" type="url" />
        </CellHalf>
      )}

      {(show_all || youtube) && (
        <CellHalf>
          <MTextField name="youtube" floatingLabelText="youtube" type="url" />
        </CellHalf>
      )}

      {(show_all || flickr) && (
        <CellHalf>
          <MTextField name="flickr" floatingLabelText="flickr" type="url" />
        </CellHalf>
      )}

      {(show_all || social_foursquare) && (
        <CellHalf>
          <MTextField
            name="social_foursquare"
            floatingLabelText="foursquare"
            type="url"
          />
        </CellHalf>
      )}

      {!show_all && (
        <div className="button-wrapper">
          <FlatButton
            label="Show all social services"
            onClick={handleShowAll}
          />
        </div>
      )}
    </Row>
  </PcCard>
);

class WwwState extends Component {
  constructor(props) {
    super(props);
    this.state = { show_all: false, ...props };
    this.handleShowAll = this.handleShowAll.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !(nextState.show_all === this.state.show_all);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.venue_id === this.state.venue_id) return;
    this.setState({ show_all: false, ...nextProps });
  }

  handleShowAll() {
    this.setState({ show_all: true });
  }

  render() {
    return <WwwView {...this.state} handleShowAll={this.handleShowAll} />;
  }
}

function mapStateToProps(state) {
  const {
    website,
    facebook,
    instagram,
    twitter,
    youtube,
    flickr,
    social_foursquare,
    venue_id
  } = state.data.venue;

  // values used only to decide if display on init
  return {
    website,
    facebook,
    instagram,
    twitter,
    youtube,
    flickr,
    social_foursquare,
    venue_id
  };
}

export default connect(mapStateToProps)(WwwState);
