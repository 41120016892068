import React, { Component } from "react";
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn
} from "material-ui/Table";

import { connect } from "react-redux";
import PcTextField from "../shared/PcTextField";
import { queueUpdate } from "../../actions/actions";
import PcCard from "../shared/PcCard";

const CategoryRow = ({ category, handleModify }) => {
  const name = category.parent_id ? " - " + category.name : category.name;
  return (
    <TableRow key={category.category_id}>
      <TableRowColumn>{name}</TableRowColumn>
      <TableRowColumn>
        <PcTextField
          handleChange={val => handleModify(category.category_id, val)}
          size={2}
          value={category.ordering}
          type="number"
          fullWidth={false}
          style={{ width: "100px" }}
        />
      </TableRowColumn>
    </TableRow>
  );
};

const categoryList = (category_list, handleModify) => {
  let items = [];

  category_list.map(category => {
    if (category.parent_id) return null;
    items.push(
      <CategoryRow
        category={category}
        handleModify={handleModify}
        key={category.category_id}
      />
    );
    category_list.map(sub_category => {
      if (sub_category.parent_id !== category.category_id) return null;
      return items.push(
        <CategoryRow
          category={sub_category}
          handleModify={handleModify}
          key={sub_category.category_id}
        />
      );
    });
    return null;
  });
  return items;
};

const CategoriesView = ({ category_list, handleModify }) => (
  <PcCard cardTitle="Categories" id="card_categories">
    <Table selectable={false}>
      <TableHeader displaySelectAll={false}>
        <TableRow>
          <TableHeaderColumn>Name</TableHeaderColumn>
          <TableHeaderColumn>Ordering</TableHeaderColumn>
        </TableRow>
      </TableHeader>
      <TableBody displayRowCheckbox={false} selectable={false}>
        {category_list.length > 0 && categoryList(category_list, handleModify)}
        {category_list.length === 0 && (
          <TableRow>
            <TableRowColumn>No categories yet</TableRowColumn>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </PcCard>
);

class CategoriesWithState extends Component {
  state = { category_list: this.props.category_list };

  handleModify = (category_id, ordering) => {
    this.setState({
      category_list: [...this.state.category_list].map(category =>
        category.category_id === category_id
          ? { ...category, ordering }
          : { ...category }
      )
    });
    this.props.modifyItem(category_id, ordering);
  };

  render() {
    return (
      <CategoriesView
        category_list={this.state.category_list}
        handleModify={this.handleModify}
      />
    );
  }
}

const mapStateToProps = state => {
  const { category_id, category_list, data_key } = state.data;
  return {
    category_id,
    category_list: category_list || [],
    key: data_key
  };
};

const mapDispatchToProps = dispatch => {
  return {
    modifyItem: (category_id, ordering) => {
      dispatch(queueUpdate("category", category_id, "ordering", ordering));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesWithState);
