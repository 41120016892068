// @flow

// $FlowFixMe - after flow update https://github.com/facebook/flow/pull/7149/
import React, { useState } from "react";

import { connect } from "react-redux";
import FlatButton from "material-ui/FlatButton";

import addNew from "../../actions/addNew";
import PcAppBar from "../shared/TopBar/PcAppBar";
import BreadCrumbs from "../shared/BreadCrumbs";

import withUserDataFetched from "../../core/withUserDataFetched";
import { withAccessSuperadmin } from "../../core/withAccess";

import PcTextField from "../shared/PcTextField.js";

const AffiliateNew = ({ country, dispatchAdd }) => {
  const [name, setName] = useState("");

  if (!country) return <>loading</>;

  const breadcrumbs = [
    {
      url: "/",
      name: "home"
    },
    {
      url: "/affiliate",
      name: "Affliliate home"
    },
    {
      url: "/affiliate/" + country.country_id,
      name: country.name
    },
    {
      url: "/affiliate/" + country.country_id + "/new",
      name: "add new"
    }
  ];

  const handleChange = value => {
    setName(value);
  };

  const handleAdd = () => {
    dispatchAdd(name, country.country_id, "affiliate/" + country.country_id);
  };

  return (
    <>
      <PcAppBar title={"Add new affiliate widget"} no_drawer={true} />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          <h1>{country.name}</h1>
        </div>
        <div style={{ maxWidth: "500px", margin: "auto" }}>
          <PcTextField
            name="name"
            value={name}
            fullWidth={false}
            floatingLabelText="name"
            handleChange={handleChange}
          />
          <FlatButton
            label="Add Widget"
            primary={true}
            disabled={name ? false : true}
            onClick={handleAdd}
          />
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    dispatchAdd: (name, country_id, new_url_after_insert) => {
      dispatch(
        addNew("affiliate_widget", {
          name,
          country_id,
          new_url_after_insert
        })
      );
    }
  };
};

const mapStateToProps = ({ constants, data }, ownProps) => {
  const country_list = constants.country_list || null;
  const country_id_route = parseInt(ownProps.match.params.country_id);
  const country = country_list
    ? country_list.find(c => c.country_id === country_id_route)
    : null;

  return {
    country,
    country_list
  };
};

export default withUserDataFetched(
  withAccessSuperadmin(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(AffiliateNew)
  )
);
