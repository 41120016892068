import React from "react";
import IconButton from "material-ui/IconButton";
import HelpOutline from "material-ui/svg-icons/action/help-outline";
import PcTextField from "./PcTextField";

// wrapper for PcTextField
export default ({ tooltip, ...props }) => (
  <div
    style={{ position: "relative", display: "inline-block", paddingRight: 20 }}
  >
    <IconButton
      style={{
        position: "absolute",
        right: 10,
        top: 22,
        width: 15,
        height: 15
      }}
      tooltip={tooltip}
    >
      <HelpOutline color="grey" />
    </IconButton>
    <PcTextField {...props} />
  </div>
);
