import React, { Component } from "react";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import Chip from "material-ui/Chip";
import { queueUpdate } from "../../actions/actions";
import { cssActive } from "../../helpers";
import PcCard from "../shared/PcCard.js";
import FeatureTypesParent from "./FeatureTypesParent";

const MainParent = ({
  city_id = 0,
  city_list,
  country_name,
  handleMainChange
}) => (
  <SelectField
    floatingLabelText="Parent site"
    value={city_id}
    autoWidth={true}
    onChange={(event, index, value) => handleMainChange(value)}
  >
    <MenuItem value={0} primaryText={country_name} />
    {city_list.map(city => (
      <MenuItem
        value={city.city_id}
        primaryText={city.name}
        style={cssActive(city.active)}
        key={city.city_id}
      />
    ))}
  </SelectField>
);

const selectionRenderer = values => (
  <span>{values.length ? values.length + " marked cities" : null}</span>
);

const AdditionalCities = ({
  active_cities,
  city_list,
  city_id,
  handleAdditionalChange,
  handleExtraRemove
}) => (
  <div className="row-flex">
    <SelectField
      floatingLabelText="Additional cities"
      value={active_cities}
      onChange={(event, index, value) => handleAdditionalChange(value)}
      multiple={true}
      selectionRenderer={selectionRenderer}
      autoWidth={true}
    >
      {city_list.map(city => {
        if (city.city_id === city_id) return null; // do not show main city here
        return (
          <MenuItem
            value={city.city_id}
            primaryText={city.name}
            style={cssActive(city.active)}
            key={city.city_id}
          />
        );
      })}
    </SelectField>

    {active_cities &&
      active_cities.map(active_city_id => {
        const city = city_list.find(city => city.city_id === active_city_id);
        if (!city || city.city_id === city_id) return null;
        return (
          <Chip
            key={"chip" + city.city_id}
            style={{ margin: "2px" }}
            onRequestDelete={() => handleExtraRemove(city.city_id)}
          >
            {city.name}
          </Chip>
        );
      })}
  </div>
);

class FeatureCardParent extends Component {
  state = {
    feature_id: this.props.feature_id,
    city_id: this.props.city_id,
    active_cities: this.props.active_cities,
    country_name: this.props.country_name,
    city_list: this.props.city_list
  };

  handleMainChange = city_id => {
    const { feature_id, dispatch } = this.props;
    dispatch(queueUpdate("feature", feature_id, "city_id", city_id));
    this.setState({ city_id });
  };

  handleExtraRemove = remove_city_id => {
    const active_cities = this.state.active_cities.filter(
      city_id => city_id !== remove_city_id
    );
    this.handleAdditionalChange(active_cities);
  };

  handleAdditionalChange = active_cities => {
    const { feature_id, dispatch } = this.props;
    dispatch(
      queueUpdate("feature", feature_id, "active_cities", active_cities)
    );
    this.setState({ active_cities });
  };

  render() {
    return (
      <PcCard cardTitle="Parents" id="card_parents">
        <br />
        <strong>City parents</strong>
        <p>
          Features can be assigned to all cities (select{" "}
          {this.props.country_name} for this) or those selected only.
        </p>

        <MainParent {...this.state} handleMainChange={this.handleMainChange} />

        {this.state.city_id > 0 && (
          <AdditionalCities
            {...this.state}
            handleAdditionalChange={this.handleAdditionalChange}
            handleExtraRemove={this.handleExtraRemove}
          />
        )}
        <br />
        <strong>Feature category parents</strong>
        {!this.props.feature_type_list && (
          <p>You don't have any feature types yet</p>
        )}
        {this.props.feature_type_list && <FeatureTypesParent />}
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data, constants }, ownProps) => {
  const { feature, country, feature_type_list, data_key } = data;

  const cityList = country_id =>
    constants.city_list.filter(city => city.country_id === country_id);

  return {
    feature_id: feature.feature_id,
    city_id: feature.city_id,
    active_cities: feature.active_cities,
    country_name: country.name,
    city_list: constants.city_list ? cityList(country.country_id) : [],
    feature_type_list,
    key: data_key
  };
};

export default connect(mapStateToProps)(FeatureCardParent);
