import React from "react";
import { connect } from "react-redux";

const getLink = d => {
  switch (d.page_type) {
    case "venue":
      return {
        link: d.venue.venue_id,
        google_short: d.venue.google_short
          ? "https://goo.gl/#analytics/goo.gl/" +
            d.venue.google_short +
            "/all_time"
          : null
      };
    case "feature":
      return {
        link: d.feature.feature_id + "f"
      };
    case "category":
      return {
        link: d.category.category_id + "ca"
      };
    case "chapter":
      return {
        link: d.chapter.chapter_id + "ch"
      };

    default:
      //console.warn('expected known item in ShortLink')
      return null;
  }
};

const ShortLink = ({ data }) => {
  const item = getLink(data);

  if (!item) return null;

  return (
    <span style={{ fontSize: "14px" }}>
      {item.google_short && (
        <a
          className="the_link"
          href={item.google_short}
          target="_blank"
          rel="noopener noreferrer"
        >
          {" iyp.me/" + item.link}
        </a>
      )}
      {!item.google_short && <span>{" iyp.me/" + item.link}</span>}
    </span>
  );
};

export default connect(({ data }) => {
  return { data };
})(ShortLink);
