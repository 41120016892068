const openPretty = data => {
  if (!data) return "";

  const allowed = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];

  /*create object {
		daily 	: '10:00-20:00',
		fri		: '12:00-14:00',
		sat		: '12:00-14:00'
	}*/
  const filtered = Object.keys(data)
    .filter(key => allowed.includes(key.replace("open_", "")))
    .reduce((obj, key) => {
      if (!data[key]) return obj;
      obj[key.replace("open_", "")] = data[key];
      return obj;
    }, {});
  /*
		['10:00-20:00', '12:00-14:00', '12:00-14:00']
	*/
  const values = Object.values(filtered);

  /*
		['10:00-20:00', '12:00-14:00', 'Closed']
	*/
  const hours_uniq = [...new Set(values)].sort((a, b) =>
    a === "Closed" && b !== "Closed" ? 1 : 0
  );

  const ranges = hours_uniq.map(hours_item => {
    const dows = Object.keys(filtered)
      .filter(key => filtered[key] === hours_item)
      .map(string => string.charAt(0).toUpperCase() + string.slice(1)) //capitalize
      .join(", ");
    return hours_item === "Closed" ? "Closed " + dows : dows + " " + hours_item;
  });

  let out = "";

  if (data.open_daily) {
    if (data.open_daily === "Closed" && ranges.length === 0) {
      out += "Closed. ";
    } else if (data.open_daily !== "Closed") {
      out += "Open " + data.open_daily + ". ";
    }
  }
  if (ranges.length) {
    out += ranges.join(", ");
    out += ".";
  }
  return out.trim();

  /*
	return (
		(
			(data.open_daily ?
				(data.open_daily ==='Closed' ? 'Closed' :  'Open ' + data.open_daily) + '. ')
				: ''
			)
			+
			(ranges.length ? ranges.join(', ') + '.' : '')
		).trim()
	)
	*/
};

export default openPretty;
