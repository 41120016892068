/* background */

import React, { Component } from "react";
import Close from "material-ui/svg-icons/navigation/close";
import IconButton from "material-ui/IconButton";
import update from "immutability-helper";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

import PcTextField from "../../shared/PcTextField";
import PcCheckbox from "../../shared/PcCheckbox";
import FileImage from "./FileImage";
import { RowFlexCenter } from "../../shared/Grid";
import {
  queueUpdate,
  queueDelete,
  addPostData
} from "../../../actions/actions";
import { static_url } from "../../../config/config";

const Thumb = ({ background_id }) => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={static_url + "img/background/" + background_id + ".jpg"}
  >
    <img
      style={{ verticalAlign: "middle", margin: "3px", borderRadius: "10px" }}
      src={static_url + "img/background/" + background_id + "-360x360.jpg"}
      alt=""
      width="120"
    />
  </a>
);
const TheBgItem = ({ item, handleChange, itemRemove, index }) => (
  <div>
    <RowFlexCenter>
      <span>{index + 1}</span>

      <Thumb background_id={item.background_id} />

      <div>
        <div>
          <div className="inline-block pd-w">
            <PcTextField
              name="caption"
              value={item.caption}
              fullWidth={false}
              handleChange={val => handleChange("caption", val)}
              floatingLabelText="caption"
            />
          </div>
          <div className="inline-block pd-w">
            <PcTextField
              type="url"
              name="caption_url"
              value={item.caption_url}
              fullWidth={false}
              handleChange={val => handleChange("caption_url", val)}
              floatingLabelText="caption url (IYP website)"
            />
          </div>
        </div>

        <div>
          <div className="inline-block pd-w">
            <PcTextField
              name="copyright"
              value={item.copyright}
              fullWidth={false}
              handleChange={val => handleChange("copyright", val)}
              floatingLabelText="copyright"
            />
          </div>
          <div className="inline-block pd-w">
            <PcTextField
              type="url"
              name="copyright_url"
              value={item.copyright_url}
              fullWidth={false}
              handleChange={val => handleChange("copyright_url", val)}
              floatingLabelText="copyright url"
            />
          </div>
        </div>
      </div>
      <div>
        <PcCheckbox
          name="active"
          value={item.active}
          handleChange={val => handleChange("active", val)}
          label="active"
        />
        <br />

        <IconButton onClick={itemRemove} className="pull-right">
          <Close />
        </IconButton>
      </div>
    </RowFlexCenter>

    <div className="visible-xs">
      <hr />
    </div>
  </div>
);

const TheBgList = ({ background, itemRemove, handleChange }) => (
  <div>
    {background.map((item, index) => (
      <TheBgItem
        key={item.background_id}
        index={index}
        item={item}
        itemRemove={() => {
          itemRemove(index);
        }}
        handleChange={(field, val) => {
          handleChange(index, field, val);
        }}
      />
    ))}
  </div>
);

class backgroundWithState extends Component {
  componentWillReceiveProps(nextProps) {
    this.init(nextProps);
  }

  componentWillMount() {
    this.init(this.props);
  }

  init(props) {
    this.setState({
      background: props.background,
      gravity: 0
    });
  }

  removeItem = index => {
    const confirm = window.confirm("Removing background image. Are you sure?");

    if (!confirm) return;

    this.props.dispatch(
      queueDelete("background", this.state.background[index].background_id)
    );

    this.setState(
      {
        background: update(this.state.background, { $splice: [[index, 1]] })
      },
      () =>
        this.props.handleImagesChange({
          background: [...this.state.background]
        })
    );
  };

  handleChange = (index, field, val) => {
    let value_updated = {};
    value_updated[field] = val;

    const item_updated = { ...this.state.background[index], ...value_updated };

    this.setState(
      {
        background: update(this.state.background, {
          [index]: { $set: item_updated }
        })
      },
      // level up
      () => {
        this.props.handleImagesChange({
          background: [...this.state.background]
        });
        // to modiffy
        console.log(this.state.background);
        return this.props.dispatch(
          queueUpdate("background", item_updated.background_id, field, val)
        );
      }
    );
  };

  ChangeGravity = val => {
    this.props.dispatch(addPostData("gravity", val));
    this.setState({ gravity: val });
  };

  BtnClick = event => {
    if (this.state.gravity) return;
    event.preventDefault();
    alert("Select Gravity first");
  };

  render() {
    return (
      <div>
        <div className="pd">
          <SelectField
            floatingLabelText="gravity"
            value={this.state.gravity || 0}
            onChange={(event, key, payload) => this.ChangeGravity(payload)}
          >
            <MenuItem value={0} primaryText="select gravity" />
            <MenuItem value="left top" primaryText="left top" />
            <MenuItem value="center top" primaryText="center top" />
            <MenuItem value="right top" primaryText="right top" />
            <MenuItem value="left center" primaryText="left center" />
            <MenuItem value="center center" primaryText="center center" />
            <MenuItem value="right center" primaryText="right center" />
            <MenuItem value="left bottom" primaryText="left bottom" />
            <MenuItem value="center bottom" primaryText="center bottom" />
            <MenuItem value="right bottom" primaryText="right bottom" />
          </SelectField>
        </div>
        <div className="pd">
          <div style={{ display: "inline-block" }} onClick={this.BtnClick}>
            <FileImage
              name="background"
              label="add background file"
              preview_size={80}
              disabled={this.state.gravity ? false : true}
              info="Minimum 1500px width"
              min_width={1500}
            />
          </div>
        </div>
        {this.state.background.length === 0 && (
          <div className="pd">No background yet</div>
        )}
        {this.props.children} {/*used by CardCityBackground}*/}
        {this.state.background.length !== 0 && (
          <div>
            <TheBgList
              background={this.state.background}
              itemRemove={this.removeItem}
              handleChange={this.handleChange}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect(null)(backgroundWithState);
