import React, { Component } from "react";
import PcHtmlEditor from "./PcHtmlEditor";
import PcCard from "./PcCard";
import { modifyWrapper } from "../../core/ModifyWrapper";

const config_researcher_comments = {
  plugins: "autoresize autolink",
  menubar: false,
  statusbar: false,
  autoresize_on_init: false,
  autoresize_max_height: 300,
  autoresize_bottom_margin: 10,
  toolbar: "undo redo | styleselect bold italic"
};

export default class ResearcherComment extends Component {
  componentWillMount() {
    this.MEditor = modifyWrapper(PcHtmlEditor, this.props.type);
  }

  render() {
    const MEditor = this.MEditor;
    return (
      <PcCard cardTitle="Researcher Comments" id="card_researcher_comments">
        <div className="row">
          <div className="col-xs-12">
            <MEditor
              name="researcher_text"
              config={config_researcher_comments}
              dom_key="researcher_comment"
            />
          </div>
        </div>
      </PcCard>
    );
  }
}
