import React, { Component } from "react";
import { connect } from "react-redux";
import { queueUpdate } from "../../actions/actions";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { List, ListItem } from "material-ui/List";

import { omitBy, isNull } from "lodash";

const TheDialog = ({
  open,
  chapter_list,
  chapter_id_selected,
  handleClose,
  handleSelect
}) => {
  const actions = [<FlatButton label="Cancel" onClick={handleClose} />];

  return (
    <Dialog
      title="Select Chapter"
      open={open}
      actions={actions}
      onRequestClose={handleClose}
      autoScrollBodyContent={true}
    >
      <List>
        <ListItem primaryText="-none-" onClick={() => handleSelect(null)} />
        {chapter_list.map(chapter => (
          <ListItem
            style={
              chapter_id_selected === chapter.chapter_id
                ? { color: "#E91E63" }
                : {}
            }
            primaryText={chapter.name}
            onClick={() => handleSelect(chapter.chapter_id)}
            key={chapter.chapter_id}
          />
        ))}
      </List>
    </Dialog>
  );
};

const symbolLikeName = text => {
  const s = text.replace("_", " ");
  return s[0].toUpperCase() + s.slice(1);
};

const getChapterByMenuItem = (menu_item_symbol, menu_data, chapter_list) => {
  if (!menu_data[menu_item_symbol]) return;
  const chapter_id = menu_data[menu_item_symbol];
  const chapter = chapter_list.find(
    chapter => chapter.chapter_id === parseInt(chapter_id, 10)
  );
  return chapter;
};

const MenuGroup = ({
  group_symbol,
  predefinied_chapters,
  custom_items,
  name,
  menu_data,
  chapter_list,
  openSelector
}) => {
  // generate predefinied
  let predefinied = [];
  if (predefinied_chapters) {
    predefinied_chapters.map((menu_item_symbol, index) => {
      const chapter = getChapterByMenuItem(
        menu_item_symbol,
        menu_data,
        chapter_list
      );
      const link_text = chapter ? chapter.name : "set";
      const title = chapter ? chapter.name : null;
      return predefinied.push(
        <div key={index}>
          {symbolLikeName(menu_item_symbol)}
          <span
            className="the_link"
            title={title}
            onClick={() => openSelector(menu_item_symbol)}
          >
            {" "}
            {link_text}{" "}
          </span>
        </div>
      );
    });
  }

  // generate custom
  let customs = [];
  for (let i = 1; i <= custom_items; i++) {
    const menu_item_symbol = group_symbol + i;
    //console.log('menu_item_symbol', menu_item_symbol)

    const chapter = getChapterByMenuItem(
      menu_item_symbol,
      menu_data,
      chapter_list
    );
    const link_text = chapter ? chapter.name : "set";
    const title = chapter ? chapter.name : null;
    customs.push(
      <div key={i}>
        {"Custom " + i}
        <span
          className="the_link"
          title={title}
          onClick={() => openSelector(menu_item_symbol)}
        >
          {" "}
          {link_text}{" "}
        </span>
      </div>
    );
  }

  return (
    <div
      className="pd"
      style={{ border: "1px solid #E0E0E0", marginBottom: "10px" }}
    >
      <h2>{name}</h2>
      {predefinied}
      {customs}
    </div>
  );
};

class CityMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu_data: props.menu_data,
      open: false,
      item_selected: null // the symbol_id of group child eg do1
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.openSelector = this.openSelector.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.city_id === this.props.city_id &&
      nextProps.menu_def === this.props.menu_def
    )
      return;
    this.setState({
      menu_data: nextProps.menu_data,
      open: false
    });
  }

  openSelector(item_id) {
    const chapter = getChapterByMenuItem(
      item_id,
      this.state.menu_data,
      this.props.chapter_list
    );

    this.setState({
      open: true,
      item_selected: item_id,
      chapter_id_selected: chapter ? chapter.chapter_id : null
    });
  }

  handleClose() {
    this.setState({
      open: false,
      item_selected: null,
      chapter_id_selected: null
    });
  }

  handleSelect(chapter_id) {
    let new_item = {};
    new_item[this.state.item_selected] = chapter_id;

    let new_menu_data = { ...this.state.menu_data };

    //remove if exists
    new_menu_data = omitBy(new_menu_data, function(menu_item_chapter_id) {
      return parseInt(menu_item_chapter_id, 10) === chapter_id;
    });

    // add new
    new_menu_data = { ...new_menu_data, ...new_item };

    // remove null
    new_menu_data = omitBy(new_menu_data, isNull);

    this.setState({
      menu_data: new_menu_data,
      open: false,
      item_selected: null
    });

    this.props.dispatch(
      queueUpdate("city", this.props.city_id, "menu_data", new_menu_data)
    );
  }

  render() {
    const { menu_data, open, chapter_id_selected } = this.state;
    const { chapter_list, menu_def } = this.props;

    if (chapter_list.length === 0)
      return <div className="pd">Add chapters first</div>;

    return (
      <div className="row-flex top">
        <TheDialog
          open={open}
          chapter_list={chapter_list}
          handleClose={this.handleClose}
          handleSelect={this.handleSelect}
          chapter_id_selected={chapter_id_selected}
        />
        {menu_def.map((group, index) => (
          <MenuGroup
            {...group}
            key={index}
            openSelector={this.openSelector}
            menu_data={menu_data}
            chapter_list={chapter_list}
          />
        ))}
      </div>
    );
  }
}

const mapStateToProps = ({ constants, data }) => {
  return {
    menu_def: constants.menu_def || [],
    chapter_list: data.chapter_list || [],
    menu_data: JSON.parse(data.city.menu_data) || {},
    city_id: data.city.city_id
  };
};

export default connect(mapStateToProps)(CityMenu);
