import { is_debug } from "../config/config";
import {
  indicatorOn,
  indicatorOff,
  cleanModiffy,
  clearPostData
} from "./actions";
import { setForceFetch, fetchAfterSave } from "./pageControl";
import { snackSuccess, snackError } from "./snacks";
import { leloFetch } from "../helpers";

export const save = () => {
  return (dispatch, getState) => {
    const state = getState();

    const force_fetch_after_save = state.page_control.force_fetch_after_save;

    const form_data = prepareFormData(state);

    if (Object.keys(state.custom_post).length) {
      dispatch(clearPostData());
    }

    if (form_data) {
      return dispatch(saveData(form_data, force_fetch_after_save));
    } else {
      dispatch(snackSuccess("Nothing to save"));
    }
  };
};

const prepareFormData = state => {
  let is_empty = true;

  let form_data = new FormData();

  const { queue_update, queue_delete, queue_insert } = state.queue_modify;

  const queue = {
    update: queue_update,
    delete: queue_delete,
    insert: queue_insert
  };

  if (
    Object.keys(queue_update).length ||
    Object.keys(queue_delete).length ||
    Object.keys(queue_insert).length
  ) {
    is_empty = false;
  }

  form_data.append("modify", JSON.stringify(queue));
  form_data.append("page_type", state.data.page_type);
  form_data.append("page_id", state.data.page_id);

  // custom post variables

  if (state.custom_post.modify) {
    return console.error('You are not allowed to add custom post "modify"');
  }

  Object.keys(state.custom_post).map(key =>
    form_data.append(key, state.custom_post[key])
  );

  const inputAll = document.querySelectorAll('input[type="file"]');
  let has_files_loaded = false;
  for (let i = 0; i < inputAll.length; i++) {
    const input = inputAll[i];
    if (input.files.length) {
      for (let j = 0; j < input.files.length; j++) {
        const name = input.name + "[" + j + "]";
        const file = input.files[j];
        form_data.append(name, file);
        is_empty = false;
        has_files_loaded = true;
      }
    } else {
      //console.log('input files empty');
    }
  }

  form_data.append("has_files", has_files_loaded);

  return is_empty ? null : form_data;
};

const saveData = (form_data, reload) => {
  return dispatch => {
    dispatch(indicatorOn());

    return leloFetch("save", {
      method: "POST",
      form_data
    }).then(
      data => {
        dispatch(indicatorOff());

        if (data.success) {
          dispatch(snackSuccess("Item saved"));
          dispatch(cleanModiffy());

          if (form_data.get("has_files") === "true" || reload) {
            // this is string
            dispatch(setForceFetch(true));
          }
          if (reload) {
            dispatch(fetchAfterSave(false)); // reset
          }
        } else if (data.warning) {
          dispatch(snackError("WARNING: some data might be not saved"));
        }
        if (data.error) {
          if (data.error === "not_logged")
            alert(
              "You are not logged in. Open pc in new tab, login and click save button again."
            );
          else {
            if (is_debug) alert(data.error);
            else alert("Something went wrong");
          }
        }
        console.log("server response is ", data);
      },
      data_error => {
        dispatch(indicatorOff());
        dispatch(snackError("ERROR: " + data_error.error));
      }
    );
  };
};
