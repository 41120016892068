import React from "react";

const goTo = item => {
  const el = document.getElementById(item);
  if (!el) return console.warn("toc element not found: " + item);

  window.scrollTo(0, el.offsetTop - 80);
};

const Toc = ({ data }) => (
  <div id="pc-toc">
    {data.map((item, key) => (
      <span key={key} className="toc-link" onClick={() => goTo(item[1])}>
        {item[0]}
      </span>
    ))}
  </div>
);

export default Toc;
