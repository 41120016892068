import React, { Component } from "react";
import Drawer from "material-ui/Drawer";
import { connect } from "react-redux";
import { toggleDrawer } from "../../../actions/actions.js";

import { debounce } from "lodash";

class DrawerWithState extends Component {
  constructor(props) {
    super(props);
    this.state = this.compute(); // not debounced here
  }

  compute = () => {
    return {
      docked: document.body.clientWidth > 1000 ? true : false
    };
  };

  sizeChangeDebounced = debounce(() => this.setState(this.compute()), 200);

  componentWillMount() {
    window.addEventListener("resize", this.sizeChangeDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.sizeChangeDebounced);
  }

  render() {
    const styles = {
      padding: "7px"
    };
    return (
      <Drawer
        docked={this.state.docked}
        width={250}
        open={this.props.open || this.state.docked}
        onRequestChange={this.props.onRequestChange}
      >
        <div style={styles}>{this.props.children}</div>
      </Drawer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    open: state.drawer_open
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRequestChange: () => {
      dispatch(toggleDrawer());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DrawerWithState);
