import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { snackSuccess } from "../../../actions/snacks.js";
import ClearColors from "./ClearColors";

import { state_colors as colors } from "../../../config/config";

import Popover from "material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

import { hasAccessLevel } from "../../../helpers/hasAccess";
import fetchSingle from "../../../helpers/fetchSingle";
import getCategoryVenues from "selectors/getCategoryVenues";

import "./Drawer.css";

const ViewItem = ({
  venue_id,
  symbol,
  name,
  state,
  web,
  print,
  mobile,
  chapter_pick,
  instant,
  filter1,
  filter2,
  current_id,
  mini,
  is_new,
  closed
}) => {
  return (
    <li>
      <Link to={"/venue/" + symbol + "/" + venue_id}>
        <span style={{ color: colors[state] }}>
          {current_id === venue_id && <span>-</span>}
          {name}
        </span>
      </Link>

      <span className="flags">
        {web && <span>w</span>}
        {print && <span>p</span>}
        {mobile && <span>a</span>}
        {chapter_pick && <span>h</span>}
        {instant && <span>i</span>}
        {filter1 && <span>s</span>}
        {filter2 && <span>f</span>}
        {mini && <span>m</span>}
        {is_new && <span>n</span>}
        {closed && <span>c</span>}
      </span>
    </li>
  );
};

class ViewList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_flags: []
    };
  }

  resetFilter = () => {
    this.setState({
      filter_flags: []
    });
  };

  toggleFilterValue(val) {
    const { filter_flags } = this.state;
    this.setState({
      filter_flags: filter_flags.includes(val)
        ? filter_flags.filter(i => i !== val)
        : [...filter_flags, val]
    });
  }

  render() {
    const {
      venue_list,
      current_id,
      access_edit,
      displaying_archived,
      showArchived,
      showLive,
      toggleFilter,
      filter_open
    } = this.props;

    const { filter_flags } = this.state;

    const venue_list_filtered =
      filter_flags.length === 0
        ? venue_list
        : venue_list.filter(venue => filter_flags.some(flag => venue[flag]));

    const style_filter_toggle =
      filter_flags.length === 0
        ? {}
        : { backgroundColor: "red", color: "white" };

    return (
      <div>
        <div className="pd-h">
          {!displaying_archived && <span>VENUES:</span>}{" "}
          {displaying_archived && (
            <span style={{ color: "#DD2C00" }}>ARCHIVED:</span>
          )}
          <span
            style={{
              float: "right",
              cursor: "pointer",
              padding: "5px",
              ...style_filter_toggle
            }}
            id="filter_hook"
            onClick={toggleFilter}
            ref="filter_hook"
          >
            Filter
          </span>
          <Popover
            open={filter_open}
            onRequestClose={toggleFilter}
            anchorEl={this.refs.filter_hook}
          >
            <Menu>
              <MenuItem
                primaryText="none"
                onClick={this.resetFilter}
                checked={filter_flags.length === 0}
              />
              <MenuItem
                primaryText="print"
                onClick={() => this.toggleFilterValue("print")}
                checked={filter_flags.includes("print")}
              />
              <MenuItem
                primaryText="web"
                onClick={() => this.toggleFilterValue("web")}
                checked={filter_flags.includes("web")}
              />
              <MenuItem
                primaryText="app"
                onClick={() => this.toggleFilterValue("mobile")}
                checked={filter_flags.includes("mobile")}
              />
              <MenuItem
                primaryText="mini"
                onClick={() => this.toggleFilterValue("mini")}
                checked={filter_flags.includes("mini")}
              />
              <MenuItem
                primaryText="featured"
                onClick={() => this.toggleFilterValue("chapter_pick")}
                checked={filter_flags.includes("chapter_pick")}
              />
              <MenuItem
                primaryText="instant"
                onClick={() => this.toggleFilterValue("instant")}
                checked={filter_flags.includes("instant")}
              />
              <MenuItem
                primaryText="filter1"
                onClick={() => this.toggleFilterValue("filter1")}
                checked={filter_flags.includes("filter1")}
              />
              <MenuItem
                primaryText="filter2"
                onClick={() => this.toggleFilterValue("filter2")}
                checked={filter_flags.includes("filter2")}
              />
              <MenuItem
                primaryText="new"
                onClick={() => this.toggleFilterValue("is_new")}
                checked={filter_flags.includes("is_new")}
              />
              <MenuItem
                primaryText="closed"
                onClick={() => this.toggleFilterValue("closed")}
                checked={filter_flags.includes("closed")}
              />
            </Menu>
          </Popover>
        </div>

        {venue_list_filtered.length > 0 && (
          <div>
            <ul id="nav_list">
              {venue_list_filtered.map((item, i) => (
                <ViewItem {...item} current_id={current_id} key={i} />
              ))}
            </ul>
            {!displaying_archived && access_edit && (
              <div>
                <ClearColors item_list={venue_list} type="venue" />
              </div>
            )}
          </div>
        )}
        {venue_list.length === 0 && <ViewEmpty />}

        {!displaying_archived && (
          <div>
            <div
              className="pd the_link pull-right"
              onClick={() => showArchived()}
            >
              show archived
            </div>
          </div>
        )}
        {displaying_archived && (
          <div className="pd the_link pull-right" onClick={() => showLive()}>
            show unarchived
          </div>
        )}
      </div>
    );
  }
}

const ViewEmpty = () => <div className="pd text-center">No venues yet</div>;

class VenueListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_archived: false,
      venue_list_archived: [],
      filter_open: false,
      filters: []
    };
  }

  componentWillReceiveProps() {
    this.setState({
      show_archived: false,
      venue_list_archived: []
    });
  }

  showArchived = () => {
    const { dispatch, archived_page_type, archived_page_id } = this.props;

    fetchSingle(
      "venues_archived/" + archived_page_type + "/" + archived_page_id
    ).then(data => {
      if (!data) return;
      this.setState({
        show_archived: true,
        venue_list_archived: data
      });
      dispatch(snackSuccess("Displaying archived venues"));
    });
  };

  showLive = () => {
    this.setState({
      show_archived: false,
      venue_list_archived: []
    });
    this.props.dispatch(snackSuccess("Displaying unarchived venues"));
  };

  toggleFilter = () => {
    this.setState({
      filter_open: !this.state.filter_open
    });
  };

  render() {
    const { current_id, access_view, access_edit } = this.props;
    const { show_archived, venue_list_archived } = this.state;

    const venue_list = show_archived
      ? venue_list_archived
      : this.props.venue_list;

    if (!access_view) return <span className="pd">No access</span>;

    if (access_view)
      return (
        <div>
          <ViewList
            venue_list={venue_list}
            current_id={current_id}
            access_edit={access_edit}
            displaying_archived={show_archived}
            showArchived={this.showArchived}
            showLive={this.showLive}
            toggleFilter={this.toggleFilter}
            filter_open={this.state.filter_open}
            filter_hook={this.filter_hook}
          />
        </div>
      );
  }
}

const mapStateToProps = ({ data, user_data }) => {
  const { page_type, page_id, category } = data;

  const archived_page_type = page_type === "venue" ? "category" : page_type;
  const archived_page_id =
    page_type === "venue" ? category.category_id : page_id;

  return {
    venue_list:
      (["venue", "category"].includes(page_type)
        ? getCategoryVenues(data)
        : data.venue_list) || [],
    current_id: data.venue ? data.venue.venue_id : null,
    access_edit: hasAccessLevel(2, "venue"),
    //access_view	: hasAccessLevel(1, 'venue'),
    access_view: true,
    archived_page_type,
    archived_page_id
  };
};

export default connect(mapStateToProps)(VenueListContainer);
