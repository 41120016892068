import { createSelector } from "reselect";

const getFeatures = data => data.feature_list || [];

export const getFeaturesNotArchived = createSelector(
  [getFeatures],
  feature_list => {
    return feature_list.filter(feature => !feature.archived);
  }
);

export const getFeaturesArchived = createSelector(
  [getFeatures],
  feature_list => {
    return feature_list.filter(feature => feature.archived);
  }
);
