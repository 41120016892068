import React from "react";
import { connect } from "react-redux";
import CitySelect from "../shared/Drawer/CitySelect";
import FeatureList from "../shared/Drawer/FeatureList";
import PcTextField from "../shared/PcTextField";
//import	PcTextFieldWithTooltip  	from '../shared/PcTextFieldWithTooltip'
import PcCheckbox, { Label } from "../shared/PcCheckbox";
import FeatureCardConnectedVenues from "./FeatureCardConnectedVenues";

//import	FeatureCardConnectedFeatures from './FeatureCardConnectedFeatures'
import CardConnectedFeatures from "../shared/CardConnectedFeatures";

import FeatureCardConnectedCategories from "./FeatureCardConnectedCategories";
import FeatureCardParents from "./FeatureCardParents";
import BreadCrumbs from "../shared/BreadCrumbs";
import EditedBy from "../shared/EditedBy";
import CardMetaTags from "../shared/CardMetaTags";
//import	PcHtmlEditor		from '../shared/PcHtmlEditor'
import { modifyWrapper } from "../../core/ModifyWrapper";
import BodySplited from "../shared/BodySplited";
import PcAppBar from "../shared/TopBar/PcAppBar";
import PcDrawer from "../shared/Drawer/PcDrawer";
import { Row, CellHalf, RowFlex } from "../shared/Grid";
import PcCard from "../shared/PcCard";
import CommentsLink from "../shared/CommentsLink";
import RemoveArchive from "../shared/RemoveArchive";
import Toc from "../shared/Toc";
import toc from "./feature_toc";
import CardImages from "../shared/Images/CardImages";
import CardImagesBackground from "../shared/Images/CardImagesBackground";
import ResearcherComment from "../shared/ResearcherComment";
import CardRedirect from "../shared/CardRedirect";
import CardTextMulti from "../shared/CardTextMulti";
import CardOffer from "../shared/CardOffer";
import FeatureTypeSelect from "../shared/Drawer/FeatureTypeSelect";

import { isSuperAdmin, isEditorPlus } from "helpers/hasAccess";

import FeatureEditorColors from "./FeatureEditorColors";

const TextFieldFeature = modifyWrapper(PcTextField, "feature");
const CheckboxFeature = modifyWrapper(PcCheckbox, "feature");

//const TextField	= modifyFeature(PcTextField)

const FeatureView = ({ feature_id, city_id }) => {
  const show_offers = isSuperAdmin() || isEditorPlus();

  const toc_filtered = toc.filter(item => item[1] !== "offers" || show_offers);

  return (
    <div>
      <PcAppBar />
      <PcDrawer>
        <CitySelect city_id={city_id} />
        <FeatureTypeSelect />
        <FeatureList />
      </PcDrawer>
      <Toc data={toc_filtered} />
      <div className="pd pc-content">
        <div className="top-line">
          <BreadCrumbs />
          <div>
            <EditedBy />
            <CommentsLink />
          </div>
        </div>

        <PcCard cardTitle="Settings" id="card_settings">
          <Row>
            <FeatureEditorColors />
          </Row>

          <Row>
            <CellHalf>
              <TextFieldFeature
                name="name"
                required={true}
                floatingLabelText="name"
              />
            </CellHalf>
            <CellHalf>
              <TextFieldFeature
                name="ordering"
                floatingLabelText="ordering"
                fullWidth={false}
                style={{ width: "100px" }}
              />
            </CellHalf>
          </Row>
          <RowFlex>
            <CheckboxFeature
              name="active"
              label={<Label name="web" abbr="w" />}
            />
            <CheckboxFeature
              name="print"
              label={<Label name="print" abbr="p" />}
            />
            <CheckboxFeature
              name="one_page_view"
              label="display&nbsp;on&nbsp;one&nbsp;page"
            />
          </RowFlex>
        </PcCard>

        <PcCard cardTitle="Description" id="card_description">
          <BodySplited data_type="feature" data_id={feature_id} />
        </PcCard>

        <ResearcherComment type="feature" />

        <CardImages data_type="feature" data_id={feature_id} />
        <CardImagesBackground data_type="feature" data_id={feature_id} />

        <FeatureCardConnectedVenues key={"feature" + feature_id} />

        <CardConnectedFeatures data_type="feature" data_id={feature_id} />

        <FeatureCardConnectedCategories />
        <CardMetaTags TextFieldTyped={TextFieldFeature} />

        <FeatureCardParents />

        <CardRedirect data_type="feature" />

        {show_offers && (
          <div>
            <CardOffer />
            <CardTextMulti
              card_title="Offer widget"
              field="object"
              type="feature"
            />
          </div>
        )}

        <RemoveArchive label="Feature" />
      </div>
    </div>
  );
};

const mapStateToProps = ({ data, user_data }) => {
  const { feature } = data;

  return {
    feature_id: feature.feature_id,
    city_id: feature.city_id
  };
};

export default connect(mapStateToProps)(FeatureView);
