import React from "react";
import { connect } from "react-redux";
import { queueUpdate } from "../../actions/actions.js";
import PcCard from "../shared/PcCard.js";
import PcCheckbox, { Label } from "../shared/PcCheckbox.js";
import EditorState from "../shared/EditorState.js";
import { modifyWrapper } from "../../core/ModifyWrapper";

//import config from '../config/config';

/************** Part checkbxes flags ************/

const MCheckbox = modifyWrapper(PcCheckbox, "event");

const PartCheckboxes = () => (
  <div>
    <MCheckbox name="print" label={<Label name="print" abbr="p" />} />
    <MCheckbox name="web" label={<Label name="web" abbr="w" />} />
    <MCheckbox name="mobile" label={<Label name="mobile" abbr="a" />} />
    <MCheckbox name="pick" label={<Label name="featured" abbr="h" />} />
    <MCheckbox name="filter1" label={<Label name="filter1" abbr="s" />} />
    <MCheckbox name="filter2" label={<Label name="filter2" abbr="f" />} />
    <MCheckbox name="cancelled" label="cancelled" />
  </div>
);

/************** Part radios state ************/

const mapStateToProps = ({ data }) => {
  const { event, data_key } = data;
  const { state, event_id } = event;

  return {
    value: state,
    data_id: event_id,
    dom_key: data_key
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleChange: (data_id, value) => {
      dispatch(queueUpdate("event", data_id, "state", value));
    }
  };
};

const PartStateRadios = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorState);

/******************* Final component ************/

const EventCardState = ({ data_id, dom_key }) => (
  <PcCard cardTitle="" id="card_top">
    <div className="pd" />
    <PartCheckboxes />
    <PartStateRadios data_id={data_id} dom_key={dom_key} />
  </PcCard>
);

export default EventCardState;
