import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../Drawer.css";
import ClearColors from "../ClearColors";
import { connect } from "react-redux";
import { snackSuccess } from "actions/snacks.js";
import fetchSingle from "helpers/fetchSingle";

const ViewItem = ({
  event_id,
  symbol,
  name,
  state,
  web,
  print,
  mobile,
  pick,
  instant,
  filter1,
  filter2,
  current_id
}) => {
  const colors = {
    6: "black",
    2: "blue",
    4: "purple",
    3: "#aaaa00",
    5: "red",
    1: "green"
  };

  return (
    <li>
      <Link to={"/event/" + symbol + "/" + event_id}>
        <span style={{ color: colors[state] }}>
          {current_id === event_id && <span>-</span>}
          {name}
        </span>
      </Link>

      <span className="flags">
        {web && <span>w</span>}
        {print && <span>p</span>}
        {mobile && <span>a</span>}
        {pick && <span>h</span>}
        {instant && <span>i</span>}
        {filter1 && <span>s</span>}
        {filter2 && <span>f</span>}
      </span>
    </li>
  );
};

class EventList extends Component {
  state = {
    show_archived: false,
    event_list_archived: []
  };

  showArchived = async () => {
    const { dispatch, city_id, event_type_id } = this.props;
    const archived = await fetchSingle("events_archived/" + city_id);
    if (!archived) return;
    this.setState({
      show_archived: true,
      event_list_archived: !event_type_id
        ? archived
        : archived.filter(item => item.event_type_id === event_type_id)
    });
    dispatch(snackSuccess("Displaying archived events"));
  };

  showLive = () => {
    this.setState({
      show_archived: false,
      event_list_archived: []
    });
    this.props.dispatch(snackSuccess("Displaying unarchived events"));
  };

  render() {
    const { current_id, access_edit, access_view } = this.props;
    const { show_archived, event_list_archived } = this.state;

    const event_list = show_archived
      ? event_list_archived
      : this.props.event_list;

    if (!access_view) return <span className="pd">No access</span>;

    return (
      <div>
        {event_list.length > 0 && access_view && (
          <div>
            {!show_archived && <div className="pd-h">EVENTS:</div>}{" "}
            {show_archived && (
              <div className="pd-h" style={{ color: "#DD2C00" }}>
                ARCHIVED:
              </div>
            )}
            <ul id="nav_list">
              {event_list.map((item, i) => (
                <ViewItem {...item} current_id={current_id} key={i} />
              ))}
            </ul>
            {!show_archived && access_edit && (
              <div>
                <ClearColors item_list={event_list} type="event" />
              </div>
            )}
          </div>
        )}
        {!event_list.length && (
          <div className="pd text-center">No events no show</div>
        )}

        {!show_archived && (
          <div>
            <div className="pd the_link pull-right" onClick={this.showArchived}>
              show archived
            </div>
          </div>
        )}
        {show_archived && (
          <div className="pd the_link pull-right" onClick={this.showLive}>
            show unarchived
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ data, user_data }, ownProps) => {
  const { event_type_id } = ownProps;
  const { event, city, data_key } = data;
  const event_list = data.event_list || [];

  const list_filtered = !event_type_id
    ? event_list
    : event_list.filter(item => item.event_type_id === event_type_id);

  return {
    key: data_key,
    event_type_id,
    event_list: list_filtered || [],
    current_id: event ? event.event_id : null,
    access_edit: user_data.rights.venue || 0 >= 2,
    access_view: user_data.rights.venue || 0 >= 1,
    city_id: city ? city.city_id : null
  };
};

export default connect(mapStateToProps)(EventList);
