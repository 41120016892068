export default [
  ["Top", "breadcrumbs"],
  ["Name", "card_name"],
  ["Location", "card_location"],
  ["Www", "card_www"],
  ["Contact", "card_contact"],
  ["Description", "card_description"],
  ["Researcher comments", "card_researcher_comments"],
  ["Open hours", "card_open_hours"],
  ["Hotel", "card_hotel"],
  ["Reservation description", "card_reservation_text"],
  ["Price description", "card_price"],
  ["Lead", "card_lead"],
  ["Meta keywords", "card_meta_tags"],
  ["Connected venues", "card_connected_venues"],
  ["Venue's app", "card_venue_app"],
  ["Services", "card_services"],
  ["Display in", "card_parents"],
  ["Images text", "card_images_text"],
  ["Images background", "card_images_bg"],
  ["Images App mobile", "card_images_app"],
  ["Siblings", "card_siblings"],
  ["Offer", "card_offer"],
  ["360° tour", "card_tour360"],
  ["Redirect", "card_redirect"]
];
