import React from "react";

import { connect } from "react-redux";

import CitySelect from "../shared/Drawer/CitySelect";
import ChapterSelect from "../shared/Drawer/ChapterSelect";
import CategorySelect from "../shared/Drawer/CategorySelect";
import PcCard from "../shared/PcCard.js";
import RemoveArchive from "../shared/RemoveArchive";

import CardRedirect from "../shared/CardRedirect.js";
//import	{InfoNotice} 	from '../shared/Info/Info'

import { Row, CellHalf, RowFlex } from "../shared/Grid";
import VenueList from "../shared/Drawer/VenueList";
import CardMetaTags from "../shared/CardMetaTags";
import PcAppBar from "../shared/TopBar/PcAppBar";
import PcDrawer from "../shared/Drawer/PcDrawer";
import CategoryParent from "./CategoryParent";
import CategoryInfo from "./CategoryInfo";
import PcTextField from "../shared/PcTextField.js";
import PcCheckbox from "../shared/PcCheckbox.js";
import {
  modifyWrapper,
  modifyCategory,
  modifyText
} from "../../core/ModifyWrapper";
import BodySplited from "../shared/BodySplited";
import CardImagesBackground from "../shared/Images/CardImagesBackground";

import BreadCrumbs from "../shared/BreadCrumbs";
import EditedBy from "../shared/EditedBy";

const TextFieldCategory = modifyWrapper(PcTextField, "category");
const TextFieldText = modifyText(PcTextField);
const CheckboxCategory = modifyCategory(PcCheckbox);

const CategoryView = ({
  city_id,
  chapter_id,
  category_id,
  country_id,
  text_id
}) => {
  return (
    <div>
      <PcAppBar />

      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect chapter_id={chapter_id} />
        <CategorySelect category_id={category_id} />
        <VenueList />
      </PcDrawer>

      <div className="pd pc-content">
        <div className="top-line">
          <BreadCrumbs />
          <EditedBy />
        </div>

        <CategoryInfo />

        <PcCard cardTitle="Settings">
          <Row>
            <CellHalf>
              <TextFieldCategory
                name="name"
                required={true}
                floatingLabelText="name"
              />
            </CellHalf>
            <CellHalf>
              <TextFieldCategory
                name="symbol"
                required={true}
                disabled={true}
                floatingLabelText="url symbol"
              />
            </CellHalf>
          </Row>

          <RowFlex>
            <CheckboxCategory name="active" label="active" />

            <CategoryParent category_id={category_id} />

            <TextFieldCategory
              name="ordering"
              floatingLabelText="ordering"
              type="number"
              fullWidth={false}
            />
          </RowFlex>
        </PcCard>

        <PcCard cardTitle="Description" id="card-description">
          <Row>
            <CellHalf>
              <TextFieldText
                name="name"
                floatingLabelText="text title"
                hintText="If empty, category name will be used"
              />
            </CellHalf>
          </Row>
          <br />

          <BodySplited data_type="text" data_id={text_id} />
        </PcCard>

        <CardMetaTags TextFieldTyped={TextFieldCategory} />

        <CardImagesBackground data_type="category" data_id={category_id} />

        <CardRedirect data_type="category" />

        <RemoveArchive />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const d = state.data;

  const c = d.category;

  return {
    data_id: c.category_id,
    city_id: c.city_id,
    country_id: c.country_id,
    chapter_id: c.chapter_id,
    category_id: c.category_id,
    text_id: d.text.text_id
  };
};

export default connect(mapStateToProps)(CategoryView);
