import React, { Component } from "react";
import { connect } from "react-redux";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { city_colors } from "../../config/config";
import { queueUpdate } from "../../actions/actions";

const ColorItem = ({ color, handleClick, is_active = false }) => {
  const cssItem = {
    width: "30px",
    height: "30px",
    display: "inline-block",
    border: "4px solid white",
    cursor: "pointer",
    backgroundColor: color,
    boxSizing: "border-box",
    borderColor: is_active ? "rgba(0,0,0,0.5)" : "transparent",
    margin: "0 4px"
  };

  return <div style={cssItem} onClick={() => handleClick(color)} />;
};

const ColorList = ({ handleClick, active_color }) => (
  <div>
    {city_colors.map((color, i) => {
      return (
        <ColorItem
          color={color}
          key={i}
          handleClick={handleClick}
          is_active={color === active_color ? true : false}
        />
      );
    })}
  </div>
);

class CityColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
      open: false
    };
    this.handleColorClick = this.handleColorClick.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.city_id === nextProps.city_id) return;
    this.setState({
      color: nextProps.color,
      open: false
    });
  }

  handleModalOpen() {
    this.setState({ open: true });
  }

  handleModalClose() {
    this.setState({ open: false });
  }

  handleColorClick(color) {
    this.setState({
      color,
      open: false
    });
    this.props.dispatch(
      queueUpdate("city", this.props.city_id, "color", color)
    );
  }

  render() {
    const actions = [
      <FlatButton
        label="Cancel"
        primary={true}
        onClick={this.handleModalClose}
      />
    ];

    const { open, color } = this.state;

    return (
      <div>
        <Dialog
          title="Select color"
          actions={actions}
          open={open}
          modal={false}
          onRequestClose={this.handleModalClose}
        >
          <ColorList active_color={color} handleClick={this.handleColorClick} />
        </Dialog>

        <span>City Color</span>
        {color && (
          <ColorItem color={color} handleClick={this.handleModalOpen} />
        )}
        {!color && (
          <FlatButton
            label="Select color"
            secondary={true}
            onClick={this.handleModalOpen}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { color, city_id } = state.data.city;
  return { color, city_id };
};

export default connect(mapStateToProps)(CityColorPicker);
