import { connect } from "react-redux";
import Info from "../shared/Info";

const mapStateToProps = ({ data }) => {
  let warnings = [];
  let notices = [];
  let errors = [];
  const venue = data.venue;

  if (venue.redirect_to)
    warnings.push(
      "This venue has redirect set and another page will be displayed instead: " +
        venue.redirect_to
    );
  if (venue.archived) warnings.push("This venue is archived");
  if (venue.editors_pick && !venue.mobile)
    warnings.push("This venue displays in app because it is editor's pick");

  if (venue.web && !venue.img_gallery)
    warnings.push("Please add at least one image into gallery");

  if (venue.editors_pick) notices.push("This venue is editor's pick");

  if (venue.mobile && (!venue.lat || !venue.lon))
    errors.push(
      "A problem occurred with this venue therefore it will not be displayed in the app. Add map coordinates"
    );
  if (venue.mobile && !venue.mobile_background)
    errors.push(
      "A problem occurred with this venue therefore it will not be displayed in the app. Add app background image"
    );

  return {
    warnings,
    notices,
    errors
  };
};
export default connect(mapStateToProps)(Info);
