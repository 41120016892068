import { createSelector } from "reselect";

const getVenueList = data => data.venue_list || [];
const getCategoryList = data => data.category_list || [];
const getCategoryId = data =>
  data.category ? data.category.category_id : null;

const getCategoryVenues = createSelector(
  [getVenueList, getCategoryList, getCategoryId],
  (venue_list, category_list, category_id) => {
    const category_ids = [
      category_id,
      ...category_list.filter(c => c.parent_id === category_id)
    ];
    return venue_list.filter(venue => category_ids.includes(venue.category_id));
  }
);

export default getCategoryVenues;
