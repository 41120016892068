import React from "react";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import getCityMenu from "selectors/getCityMenu";

const propTypes = {
  city_id: PropTypes.number,
  country_id: PropTypes.number
};

const CitySelectView = ({ data_list, current }) => {
  const selected = current
    ? current.country_id
      ? "country" + current.country_id
      : "city" + current.city_id
    : null;

  return (
    <SelectField
      value={selected}
      floatingLabelText="City"
      autoWidth={true}
      style={{ maxWidth: "100%" }}
    >
      {data_list.map(
        ({ name, value, link, active, current, is_country }, i) => {
          let style = {};

          let innerDivStyle = {
            width: "200px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          };

          if (!active) style.color = "grey";

          if (is_country) {
            style.textTransform = "uppercase";
            if (i > 0) {
              style.borderTop = "1px solid grey";
            }
          }

          return (
            <MenuItem
              key={i}
              value={value}
              primaryText={name}
              style={style}
              innerDivStyle={innerDivStyle}
              containerElement={<Link to={link} />}
            />
          );
        }
      )}
    </SelectField>
  );
};

const mapStateToProps = (state, ownProps) => {
  let current_item = null;

  if (ownProps.country_id) {
    current_item = { country_id: ownProps.country_id };
  } else if (ownProps.city_id) {
    current_item = { city_id: ownProps.city_id };
  }

  return {
    data_list: getCityMenu(state),
    current: current_item
  };
};

const CitySelect = connect(mapStateToProps)(CitySelectView);

CitySelect.propTypes = propTypes;

export default CitySelect;
