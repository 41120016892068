import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const getLinksFromPage = d => {
  const home = () => {
    return {
      url: "/",
      name: "Home"
    };
  };

  const country = () => {
    const { symbol, name, country_id } = d.country;
    return {
      url: "/country/" + symbol + "/" + country_id,
      name
    };
  };

  const city = () => {
    if (!d.city) return null; // feaure country
    const { symbol, name, city_id } = d.city;
    return {
      url: "/city/" + symbol + "/" + city_id,
      name
    };
  };

  const cityHome = () => {
    const { name, city_id } = d.city;
    return {
      url: "/city_home/" + city_id,
      name: name + " home"
    };
  };

  const eventsHome = () => {
    const { symbol, city_id } = d.city;
    return {
      url: "/events_home/" + symbol + "/" + city_id,
      name: "events"
    };
  };

  const eventType = () => {
    const { symbol, name, event_type_id } = d.event_type;
    return {
      url: "/event_type/" + symbol + "/" + event_type_id,
      name
    };
  };

  const featureType = () => {
    const { symbol, name, feature_type_id } = d.feature_type;
    return {
      url: "/feature_type/" + symbol + "/" + feature_type_id,
      name
    };
  };

  const chapter = () => {
    const { symbol, name, chapter_id } = d.chapter;
    return {
      url: "/chapter/" + symbol + "/" + chapter_id,
      name
    };
  };

  const category = () => {
    const { symbol, name, category_id } = d.category;
    return {
      url: "/category/" + symbol + "/" + category_id,
      name
    };
  };

  const district = () => {
    const { symbol, name, district_id } = d.district;
    return {
      url: "/district/" + symbol + "/" + district_id,
      name: name + " (district)"
    };
  };

  const venue = () => {
    const { symbol, name, venue_id } = d.venue;
    return {
      url: "/venue/" + symbol + "/" + venue_id,
      name
    };
  };

  const venueComments = () => {
    const { symbol, venue_id } = d.venue;
    return {
      url: "/venue_comments/" + symbol + "/" + venue_id,
      name: "comments"
    };
  };

  const feature = () => {
    const { symbol, name, feature_id } = d.feature;
    return {
      url: "/feature/" + symbol + "/" + feature_id,
      name
    };
  };

  const featureComments = () => {
    const { symbol, feature_id } = d.feature;
    return {
      url: "/feature_comments/" + symbol + "/" + feature_id,
      name: "comments"
    };
  };

  const event = () => {
    const { symbol, name, event_id } = d.event;
    return {
      url: "/event/" + symbol + "/" + event_id,
      name
    };
  };

  const page_type = d.page_type;

  switch (page_type) {
    case "country":
      return [home(), country()];

    case "city":
      return [home(), country(), city()];

    case "chapter":
      return [home(), country(), city(), chapter()];
    case "category":
      return [home(), country(), city(), chapter(), category()];
    case "venue":
      return [home(), country(), city(), chapter(), category(), venue()];
    case "venue_comments":
      return [
        home(),
        country(),
        city(),
        chapter(),
        category(),
        venue(),
        venueComments()
      ];

    case "district":
      return [home(), country(), city(), district()];
    case "feature":
      return [home(), country(), city(), feature()];
    case "feature_type":
      return [home(), country(), featureType()];
    case "feature_comments":
      return [home(), country(), city(), feature(), featureComments()];
    case "events_home":
      return [home(), country(), city(), eventsHome()];
    case "event":
      return [
        home(),
        country(),
        city(),
        eventsHome(),
        event() // no event eventType(). Once removed then may cause error
      ];

    case "event_type":
      return [home(), city(), eventsHome(), eventType()];

    case "city_home":
      return [home(), country(), city(), cityHome()];

    default:
      console.error("expected known item in BreadCrumbs");
      return [];
  }
};

const BreadCrumbs = ({ data, link_data }) => {
  if (!data) {
    console.warn("Unknown page type in BreadCrumbs");
    return null;
  }

  // additionaly clean empty
  const items = link_data ? link_data : getLinksFromPage(data).filter(el => el);

  const l = items.length;

  return (
    <div className="breadcrumbs" id="breadcrumbs">
      {items.map((item, index) => (
        <span key={index}>
          <Link to={item.url}>{item.name}</Link>
          {index < l - 1 && <span className="sep"> » </span>}
        </span>
      ))}
    </div>
  );
};

const mapStateToProps = ({ data }, { link_data }) => {
  return {
    data,
    link_data
  };
};

export default connect(mapStateToProps)(BreadCrumbs);
