import React, { Component } from "react";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";

import { queueUpdate } from "../../../actions/actions.js";

class CurrencyWithState extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event, index, value) {
    this.props.handleUpdate(this.props.venue_id, value);
    this.setState({ currency_id: value });
  }

  componentWillMount() {
    this.init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.init(this.props);
  }

  init({ currency_id }) {
    this.setState({ currency_id });
  }

  render() {
    return (
      <SelectField
        floatingLabelText="Currency"
        value={this.state.currency_id}
        onChange={this.handleChange}
      >
        <MenuItem value={null} primaryText="" />
        {this.props.currency_list.map((item, index) => (
          <MenuItem
            value={item.currency_id}
            primaryText={item.symbol}
            key={index}
          />
        ))}
      </SelectField>
    );
  }
}

const mapStateToProps = ({ data, constants }) => {
  return {
    currency_id: data.venue_room.currency_id,
    venue_id: data.venue.venue_id,
    currency_list: constants.currency_list || []
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleUpdate: (venue_id, value) => {
      dispatch(
        queueUpdate("venue_room", venue_id, "currency_id", value, "venue_id")
      );
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrencyWithState);
