/* wrapper for leloFetch that adds :
 - indicator on
 - indicator off
 - snack error
Promise returns null if error
*/
import { indicatorOn, indicatorOff } from "../actions/actions";
import { leloFetch } from "../helpers";
import { snackError } from "../actions/snacks";
import store from "../store";

//

const fetchSingle = (path, params_in = {}) => {
  store.dispatch(indicatorOn());
  return new Promise(function(resolve, reject) {
    leloFetch(path, params_in).then(
      data => {
        store.dispatch(indicatorOff());
        if (data.error) {
          store.dispatch(snackError(data.error));
          resolve(null);
        }
        resolve(data);
      },
      error => {
        store.dispatch(indicatorOff());
        store.dispatch(snackError(error));
        resolve(null);
      }
    );
  });
};

export default fetchSingle;
