import React, { Component } from "react";
import { connect } from "react-redux";

import { queueUpdate } from "../../actions/actions.js";
import CardImagesBackground from "../shared/Images/CardImagesBackground";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";

class CardCityBackground extends Component {
  constructor(props) {
    super(props);
    const { background, mobile_background_id } = props;
    this.state = { background, mobile_background_id };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.city_id === this.props.city_id) return;
    const { background, mobile_background_id } = nextProps;
    this.setState({ background, mobile_background_id });
  }

  _handleBackgroundChange = data => {
    const { background } = data;
    this.setState({
      background
    });
  };

  _handleMobileSelect = mobile_background_id => {
    this.setState({ mobile_background_id });
    this.props.dispatch(
      queueUpdate(
        "city",
        this.props.city_id,
        "mobile_background_id",
        mobile_background_id
      )
    );
  };

  render() {
    const { city_id } = this.props;
    const { background, mobile_background_id } = this.state;
    const { _handleBackgroundChange, _handleMobileSelect } = this;

    let background_id = null;
    if (background.length > 1) {
      if (
        // not definied:
        !mobile_background_id ||
        // was definied but removed later:
        !background.find(el => el.background_id === mobile_background_id)
      ) {
        background_id = background.slice(-1)[0].background_id;
      } else {
        background_id = mobile_background_id;
      }
    }

    return (
      <CardImagesBackground
        data_type="city"
        data_id={city_id}
        handleBackgroundChange={_handleBackgroundChange}
      >
        {background.length > 1 && (
          <MobileBackgroundSelector
            background={background}
            mobile_background_id={background_id}
            handleMobileSelect={_handleMobileSelect}
          />
        )}
      </CardImagesBackground>
    );
  }
}

const MobileBackgroundSelector = ({
  background,
  mobile_background_id,
  handleMobileSelect
}) => (
  <div className="pd">
    <SelectField
      floatingLabelText="Mobile app background"
      value={mobile_background_id}
      onChange={(event, key, payload) => handleMobileSelect(payload)}
    >
      {background.map((bg, index) => (
        <MenuItem
          value={bg.background_id}
          primaryText={"number " + (index + 1)}
          key={bg.background_id}
        />
      ))}
    </SelectField>
  </div>
);

const mapStateToProps = ({ data }) => {
  const { mobile_background_id, city_id } = data.city;
  return {
    background: data.background || [],
    mobile_background_id,
    city_id
  };
};

export default connect(mapStateToProps)(CardCityBackground);
