import mainReducer from "./reducers/mainReducer";
import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { enableBatching } from "redux-batched-actions";
//import { createLogger } from 'redux-logger'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  enableBatching(mainReducer) /* preloadedState, */,
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware // lets us dispatch() functions
      //createLogger()// neat middleware that logs actions
    )
  )
);

/*
const store = createStore(
	enableBatching(mainReducer),
	applyMiddleware(
		thunkMiddleware, // lets us dispatch() functions
		//createLogger() // neat middleware that logs actions
	)
)
*/

// return object like {data_type: "category", data_id: 88196} based store first element
// Use only if key like $table_name + '_id'
export const dataDefRoot = () => {
  const state = store.getState();
  const data_type = Object.keys(state.data)[0];
  const data_id = state.data[data_type][data_type + "_id"];
  return {
    data_type,
    data_id
  };
};

export default store;
