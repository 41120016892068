import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";

class searchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      go_to: null
    };
  }

  handleChange = event => {
    this.setState({
      value: event.target.value,
      go_to: null
    });
  };

  handleSubmit = event => {
    const { country_id, city_id } = this.props;
    event.preventDefault();
    this.setState({
      go_to: "/search/" + country_id + "/" + city_id + "/" + this.state.value
    });
  };

  render() {
    const { label } = this.props;
    if (!label) return null;
    return (
      <div style={{ display: "inline-block" }}>
        {this.state.go_to && <Redirect to={this.state.go_to} />}
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            name="search"
            onChange={this.handleChange}
            placeholder={"Search in " + label}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({ data }, ownProps) => {
  if (ownProps.label && ownProps.country_id) {
    return {
      label: ownProps.label,
      country_id: ownProps.country_id,
      city_id: ownProps.city_id
    };
  } else {
    let label = "";
    const { city, country } = data;
    const city_id = city ? city.city_id : null;
    const country_id = country ? country.country_id : null;
    if (city_id) label = city.name;
    else if (country_id) label = country.name;
    return { label, country_id, city_id };
  }
};

export default connect(mapStateToProps)(searchForm);
