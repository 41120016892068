import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  notices: PropTypes.array,
  warnings: PropTypes.array,
  errors: PropTypes.array
};

const colors_notice = { backgroundColor: "#5cb85c", color: "#FFF" };
const colors_warning = { backgroundColor: "#f0ad4e", color: "#FFF" };
const colors_error = { backgroundColor: "#d9534f", color: "#FFF" };

const InfoView = ({ type, messages }) => {
  let def = {};

  switch (type) {
    case "notices":
      def = colors_notice;
      break;
    case "warnings":
      def = colors_warning;
      break;
    case "errors":
      def = colors_error;
      break;
    default:
      return new Error("Uknwnown type prop in Info component");
  }

  const style = {
    ...def,
    borderRadius: "3px",
    display: "flex"
  };

  return (
    <div className="pd">
      <div style={style}>
        <div
          style={{
            width: 20,
            backgroundColor: "rgba(0,0,0,0.2)"
          }}
        />
        <div style={{ padding: "3px 20px" }}>
          {messages.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

const Info = ({ errors, warnings, notices }) => {
  if (!errors && !warnings && !notices) return null;

  return (
    <div>
      {errors && errors.length > 0 && (
        <InfoView type="errors" messages={errors} />
      )}
      {warnings && warnings.length > 0 && (
        <InfoView type="warnings" messages={warnings} />
      )}
      {notices && notices.length > 0 && (
        <InfoView type="notices" messages={notices} />
      )}
    </div>
  );
};

Info.propTypes = propTypes;

export { colors_notice, colors_warning, colors_error };
export default Info;
