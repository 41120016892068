/*This Class get a components chunks from page directory, and store them in state
/*Once a component is called, it's wrapped by Promised component which hyfralize it with data from server */

import React, { Component } from "react";
//import Promised from './Promised';
import { batchActions } from "redux-batched-actions";
import { connect } from "react-redux";
import Cookies from "js-cookie";

import {
  loadData,
  cleanModiffy,
  indicatorOn,
  indicatorOff,
  indicatorOffDelayed,
  queueUpdate
} from "../actions/actions";
import getConstants from "../actions/getConstants";
import getUserData from "../actions/getUserData";
import { preserveCityData } from "../actions/cityData";
import { setForceFetch, addNotLoggedGoLink } from "../actions/pageControl";
import { leloFetch } from "../helpers";
import history from "../history.js";

import Venue from "../page/venue/Venue";
import VenueComments from "../page/venue/VenueComments";
import Category from "../page/category/Category";
import Chapter from "../page/chapter/Chapter";
import City from "../page/city/City";
import Feature from "../page/feature/Feature";
import FeatureComments from "../page/feature/FeatureComments";
import District from "../page/district/District";
import EventsHome from "../page/events_home/EventsHome";
import Event from "../page/event/Event";
import Home from "../page/home/Home";
import PcUserList from "../page/pc_user/PcUserList";
import PcUser from "../page/pc_user/PcUser";
import PcNewUser from "../page/pc_user/PcNewUser";
import NotFound from "../page/NotFound";
import Country from "../page/country/Country";
import Homepage from "../page/homepage/Homepage";
import CityHome from "../page/city_home/CityHome";
import EventType from "../page/event_type/EventType";
import FeatureType from "../page/feature_type/FeatureType";

import { withErrorBoundary } from "../page/ErrorBoundary";

class PageLoader extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };
    this.fetchAll(props);
  }

  // overwirite defaul
  mapPageTypeToApiCall(page_type) {
    switch (page_type) {
      case "city_home":
        return "city";
      case "pc_new_user":
        return "pc_user"; // as it returns group_list we only  need
      default:
        return page_type;
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.data.data_key !== nextProps.data.data_key) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    // clean component speciffic data
    this.props.dispatch(loadData([]));
    this.props.dispatch(cleanModiffy());
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.match.params !== nextProps.match.params ||
      (!this.props.force_fetch && nextProps.force_fetch)
    ) {
      //// hard reload if app modiffied change detected
      const app_modified_local = localStorage.getItem("app_modified");
      const app_modified_server = Cookies.get("App-Modified");
      if (app_modified_server && app_modified_local !== app_modified_server) {
        localStorage.setItem("app_modified", app_modified_server);
        if (app_modified_local) {
          console.log("hard reload");
          window.location.reload();
        }
      }

      this.props.dispatch(cleanModiffy());
      this.props.dispatch(setForceFetch(false));
      this.fetchAll(nextProps);

      // scroll top if not autorefresh
      if (!nextProps.force_fetch) {
        this.scroll_top_after_update = true;
      }
    }
  }

  componentDidUpdate() {
    if (!this.scroll_top_after_update) return;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.scroll_top_after_update = false;
  }

  runAllActions(page_data, props) {
    const { dispatch } = this.props;
    let actions = [];
    actions.push(loadData(page_data));
    if (this.city) {
      const { city_id, city_data } = this.city;
      actions.push(preserveCityData(city_id, city_data));
      this.city = null;
    }

    // add update modiffy
    const core_item = page_data[Object.keys(page_data)[0]];
    if (core_item && Object.keys(core_item).includes("modified")) {
      const { match } = props;
      const { page_type } = match.params;
      const page_id = parseInt(match.params.page_id, 10) || 0;

      if (page_type === Object.keys(page_data)[0]) {
        // make sense eg events_home where core item is city
        actions.push(queueUpdate(page_type, page_id, "modified", 1));
      }
    }
    dispatch(batchActions(actions));
  }

  async fetchAll(props) {
    this.props.dispatch(indicatorOn());

    props.dispatch(getConstants());
    props.dispatch(getUserData());

    try {
      const [page_data] = await Promise.all([this.promisedPageData(props)]);
      this.runAllActions(page_data, props);
      this.props.dispatch(indicatorOffDelayed());
    } catch (error_data) {
      ////
      this.props.dispatch(indicatorOff());

      if (error_data === "not_logged") {
        this.props.dispatch(addNotLoggedGoLink(history.location.pathname));
        history.push("/login");
      } else {
        let the_error = true;

        if (error_data.message) {
          the_error = error_data.message;
        } else if (error_data.error) {
          the_error = error_data.error;
        } else if (error_data === "removed") {
          the_error = "this item was deleted";
        } else if (error_data === "unknown page type") {
          the_error = error_data;
        }

        this.setState({ error: the_error }, () => this.forceUpdate());
      }
    }
  }

  promisedPageData = props => {
    return new Promise(
      function(resolve, reject) {
        const { match } = props;
        const { page_type } = match.params;
        const page_id = parseInt(match.params.page_id, 10) || 0;

        const data_type = this.mapPageTypeToApiCall(page_type);

        leloFetch("page/" + data_type + "/" + page_id).then(
          data_in => {
            const now = new Date();
            const hour_mark =
              now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
            const data_key = page_type + page_id + "|" + hour_mark;
            const data = {
              ...data_in,
              data_key,
              page_type,
              data_type,
              page_id
            };
            if (data.error) reject(data.error);
            const core_item = data[Object.keys(data)[0]];
            const city_id = core_item ? core_item.city_id : null;
            if (city_id) {
              if (props.data_city.find(el => el.city_id === city_id))
                return resolve(data); // city data already cached
              this.promisedCityData(city_id).then(
                city_data => {
                  if (city_data.error) reject(city_data.error);
                  this.city = { city_data, city_id };
                  return resolve(data);
                },
                error => reject(error)
              );
            } else {
              return resolve(data);
            }
          },
          error => {
            reject(error);
          }
        );
      }.bind(this)
    );
  };

  promisedCityData = city_id => {
    return new Promise(function(resolve, reject) {
      leloFetch("page/city-structure/" + city_id).then(
        data => {
          if (data.error) reject(data.error);
          resolve(data);
        },
        error => reject(error)
      );
    });
  };

  render() {
    const { page_type } = this.props.match.params;

    if (this.state.error) {
      return <NotFound message={this.state.error}>Page not found</NotFound>;
    }

    const data = this.props.data;

    if (Object.keys(data).length === 0) return null;

    if (data.error) {
      return (
        <NotFound message={"backend error: " + data.error}>
          Page not found
        </NotFound>
      );
    }
    switch (page_type) {
      case "venue":
        return <Venue />;

      case "venue_comments":
        return <VenueComments />;

      case "category":
        return <Category />;

      case "chapter":
        return <Chapter />;

      case "city":
        return <City />;

      case "feature":
        return <Feature />;

      case "feature_comments":
        return <FeatureComments />;

      case "district":
        return <District />;

      case "events_home":
        return <EventsHome />;

      case "event":
        return <Event />;

      case "home":
        return <Home />;

      case "pc_user_list":
        return <PcUserList />;

      case "pc_new_user":
        return <PcNewUser />;

      case "pc_user":
        return <PcUser />;

      case "country":
        return <Country />;

      case "homepage":
        return <Homepage />;

      case "city_home":
        return <CityHome />;

      case "event_type":
        return <EventType />;

      case "feature_type":
        return <FeatureType />;

      default:
        return (
          <NotFound message="Component not definied in PageLoader render functon">
            Page not found
          </NotFound>
        );
    }
  }
}

const mapStateToProps = state => {
  const { data, data_city, page_control } = state;
  return {
    data: data,
    data_city: data_city, // array of cached cities
    force_fetch: page_control.force_fetch
  };
};

export default connect(mapStateToProps)(withErrorBoundary(PageLoader));
