import React from "react";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { connect } from "react-redux";
import { queueUpdate } from "../../../actions/actions";

const MediaTypeSelector = ({ type, handleImagesChange, onChange }) => (
  <SelectField floatingLabelText="media type" value={type} onChange={onChange}>
    <MenuItem value={1} primaryText="No media" />
    <MenuItem value={2} primaryText="Gallery" />
    <MenuItem value={3} primaryText="Video & gallery" />
    <MenuItem value={4} primaryText="Full width video" />
    <MenuItem value={5} primaryText="Full width image" />
  </SelectField>
);

const mapDispatchToProps = (dispatch, ownProps) => {
  const { data_type, data_id, handleImagesChange } = ownProps;

  return {
    onChange: (event, key, value) => {
      dispatch(queueUpdate(data_type, data_id, "top_figure_type", value));
      handleImagesChange({ top_figure_type: value });
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(MediaTypeSelector);
