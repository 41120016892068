import { dataDefRoot } from "store";

export const queueUpdate = (table, id, field, value, custom_key = null) => {
  return {
    type: "QUEUE_UPDATE",
    table,
    id,
    field,
    value,
    custom_key
  };
};

export const loadConstants = (data = {}) => {
  return {
    type: "LOAD_CONSTANTS",
    data
  };
};

export const clearConstants = () => {
  return {
    type: "CLEAR_CONSTANTS"
  };
};

export const queueUpdateAuto = (field, value) => {
  const { data_type, data_id } = dataDefRoot();
  return {
    type: "QUEUE_UPDATE",
    table: data_type,
    id: data_id,
    field,
    value
  };
};

export const addPostData = (key, value) => {
  return {
    type: "SET_POST_DATA",
    key,
    value
  };
};

export const clearPostData = () => {
  return {
    type: "CLEAR_POST_DATA"
  };
};

export const sourceUpdate = (data_type, field, value) => {
  return {
    type: "SOURCE_UPDATE",
    data_type,
    field,
    value
  };
};

export const queueDelete = (table, id, custom_key = null) => {
  return {
    type: "QUEUE_DELETE",
    table,
    id,
    custom_key
  };
};

export const cleanModiffy = () => {
  return {
    type: "MODIFFY_CLEAN"
  };
};

export const indicatorOn = () => {
  return {
    type: "INDICATOR_ON"
  };
};

export const indicatorOff = () => {
  return {
    type: "INDICATOR_OFF"
  };
};

export const indicatorOffDelayed = () => {
  return dispatch => {
    setTimeout(() => dispatch(indicatorOff()), 500);
  };
};

export const toggleDrawer = () => {
  return {
    type: "TOGGLE_DRAWER"
  };
};

export const drawerHide = () => {
  return {
    type: "DRAWER_HIDE"
  };
};

export const loadData = data => {
  return {
    type: "LOAD_DATA",
    data
  };
};

export const formErrorAdd = (id, name) => {
  return {
    type: "FORM_ERROR_ADD",
    id,
    name
  };
};

export const formErrorRemove = id => {
  return {
    type: "FORM_ERROR_REMOVE",
    id
  };
};

export const apiFetchError = message => {
  return {
    type: "API_FETCH_ERROR",
    message
  };
};
