import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PcSearch from "../../shared/PcSearch";
import EditableList from "../../shared/EditableList";
import Checkbox from "material-ui/Checkbox";

const propTypes = {
  venue_id: PropTypes.number,
  hour_from_venue: PropTypes.bool,
  handleAddVenue: PropTypes.func.isRequired,
  handleRemoveVenue: PropTypes.func.isRequired
};

export class VenueSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { venue_obj: null };
  }

  addItem = venue_obj => {
    this.setState({ venue_obj });
    this.props.handleAddVenue(venue_obj.venue_id);
  };

  remove = () => {
    this.setState({ venue_obj: null });
    this.props.handleRemoveVenue();
  };

  render() {
    const { event_venues, event_id, city_id, city_name, venue_id } = this.props;

    const { venue_obj } = this.state;

    let all_venues = venue_obj ? [venue_obj] : event_venues || [];

    const venue =
      venue_id && all_venues
        ? all_venues.find(obj => obj.venue_id === this.props.venue_id)
        : null;

    if (venue) {
      return (
        <div>
          <EditableList
            items={[venue]}
            is_sortable={false}
            handleChange={this.remove}
          />
          <Checkbox
            label="Use venue grid hours"
            onCheck={(ev, val) => this.props.handleUseVenueHour(val)}
            checked={this.props.hour_from_venue}
          />
        </div>
        /* remove is the only change available when one element*/
      );
    } else {
      return (
        <PcSearch
          narrow_field_key="city_id"
          narrow_field_id={city_id}
          table="venue"
          search_key={"event" + event_id}
          onResultClick={this.addItem}
          tip={"venus in " + city_name}
        />
      );
    }
  }
}

const mapStateToProps = ({ data }) => {
  const { event_venues, event_id } = data.event;
  const { city_id, name } = data.city;

  return {
    event_venues,
    event_id,
    city_id,
    city_name: name
  };
};

VenueSearch.propTypes = propTypes;

export default connect(mapStateToProps)(VenueSearch);
