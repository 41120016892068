import React, { Component } from "react";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import PcCard from "../shared/PcCard.js";
import { queueUpdate } from "../../actions/actions.js";
import { Row, CellHalf } from "../shared/Grid";
import PcTextField from "../shared/PcTextField";
import { modifyWrapper } from "../../core/ModifyWrapper";

const MTextField = modifyWrapper(PcTextField, "venue");

class EveragePricesWithState extends Component {
  constructor(props) {
    super(props);
    const { handleModify, ...p } = props;
    this.state = p;
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps({ average_prices, venue_id }) {
    if (venue_id === this.state.venue_id) return;
    this.setState({ average_prices, venue_id });
  }

  handleChange(event, index, value) {
    this.setState({ average_prices: value }, () =>
      this.props.handleModify(this.state.venue_id, "average_prices", value)
    );
  }

  render() {
    return (
      <SelectField
        floatingLabelText="price range"
        value={this.state.average_prices}
        onChange={this.handleChange}
      >
        <MenuItem value={null} primaryText="" />
        <MenuItem value={1} primaryText="€" />
        <MenuItem value={2} primaryText="€€" />
        <MenuItem value={3} primaryText="€€€" />
        <MenuItem value={4} primaryText="€€€€" />
        <MenuItem value={5} primaryText="€€€€€" />
      </SelectField>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { average_prices, venue_id } = data.venue;

  return { average_prices, venue_id };
};

const mapDispatchToProps = dispatch => {
  return {
    handleModify: (venue_id, key, value) => {
      dispatch(queueUpdate("venue", venue_id, key, value));
    }
  };
};

const EveragePrices = connect(
  mapStateToProps,
  mapDispatchToProps
)(EveragePricesWithState);

const VenueCardPrices = () => (
  <PcCard cardTitle="Price" id="card_price">
    <Row>
      <CellHalf>
        <MTextField
          name="price_text"
          multiLine={true}
          floatingLabelText="description"
        />
      </CellHalf>
      <CellHalf>
        <EveragePrices />
      </CellHalf>
    </Row>
  </PcCard>
);

export default VenueCardPrices;
