import React, { Component } from "react";
import { connect } from "react-redux";
import PcCard from "../shared/PcCard";
import PcAppBar from "../shared/TopBar/PcAppBar";
import PcSelect from "../shared/PcSelect";
import PcSearch from "../shared/PcSearch";
import { queueUpdate } from "../../actions/actions.js";
import { RowFlex } from "../shared/Grid";
import BreadCrumbs from "../shared/BreadCrumbs";
import PcTextField from "../shared/PcTextField";

import { List, ListItem } from "material-ui/List";
import Close from "material-ui/svg-icons/navigation/close";
import ActionPanTool from "material-ui/svg-icons/action/pan-tool";
import IconButton from "material-ui/IconButton";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from "react-sortable-hoc";
import update from "immutability-helper";

//import	{RowFlexTop} 	from '../shared/Grid'
//import	CitySelect 		from '../shared/Drawer/CitySelect'
import { isSuperAdmin } from "../../helpers/hasAccess";

const ItemSortable = SortableElement(props => <ItemView {...props} />);

const DragHandle = SortableHandle(() => <ActionPanTool />);

const breadcrumbs = [
  {
    url: "/",
    name: "home"
  },
  {
    url: "/homepage",
    name: "Homepage Articles"
  }
];

const ItemView = ({
  name,
  url,
  subtitle,
  the_index,
  removeItem,
  onNameChange
}) => (
  <ListItem
    disableTouchRipple={true}
    primaryText={
      <PcTextField
        value={name}
        handleChange={value => onNameChange(value, the_index)}
        fullWidth={false}
        required={true}
      />
    }
    secondaryText={subtitle}
    rightIconButton={
      <IconButton style={{ width: "90px" }} disableTouchRipple={true}>
        <DragHandle />
        <span className="editable_list_sep" />
        <Close onClick={removeItem} />
      </IconButton>
    }
  />
);

const HomepageView = ({
  country_list,
  country_id,
  features,
  handleCountryChange,
  addFeature,
  onSortEnd,
  removeItem,
  onNameChange
}) => (
  <div>
    <PcAppBar title="Homepage articles" no_drawer={true} />
    <div className="pd pc-content-wide">
      <BreadCrumbs link_data={breadcrumbs} />
      <PcCard cardTitle="Add new">
        <RowFlex>
          <PcSelect
            floatingLabelText="from Country"
            options={country_list}
            handleChange={handleCountryChange}
            value={country_id}
          />
          {country_id && (
            <PcSearch
              narrow_field_key="country_id"
              narrow_field_id={country_id}
              table="feature"
              onResultClick={addFeature}
              search_id={"country" + country_id}
              label="search features"
            />
          )}
        </RowFlex>
      </PcCard>

      <div style={{ maxWidth: "500px" }}>
        <PcCard cardTitle="Features">
          {features.length > 0 && (
            <div>
              First four features will display on homepage
              <ListSortable
                features={features}
                handleNameChange={(val, index) => onNameChange(val, index)}
                handleRemove={index => removeItem(index)}
                useDragHandle={true}
                onSortEnd={onSortEnd}
              />
            </div>
          )}
          {features.length === 0 && <span>No features</span>}
        </PcCard>
      </div>
    </div>
  </div>
);

const ListView = ({ features, handleNameChange, handleRemove }) => (
  <List>
    {features.map(({ name, url, subtitle, feature_id }, index) => (
      <ItemSortable
        name={name}
        url={url}
        subtitle={subtitle}
        the_index={index}
        removeItem={() => handleRemove(index)}
        onNameChange={val => handleNameChange(val, index)}
        key={feature_id}
        index={index}
      />
    ))}
  </List>
);

const ListSortable = SortableContainer(props => <ListView {...props} />);

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      country_id: null,
      features: this.props.features
    };
    this.country_list = this.props.country_list.map(item => {
      // prepere for PcSelect requirements
      return {
        name: item.name,
        value: item.country_id
      };
    });
  }

  _handleCountryChange = value => {
    this.setState({ country_id: value });
  };

  _addFeature = data => {
    this.setState(
      prevState => {
        return {
          features: [data, ...prevState.features]
        };
      },
      () => this._saveQueue()
    );
  };

  _saveQueue = () => {
    const out = this.state.features.map(({ name, feature_id }) => {
      return { name, feature_id };
    });
    console.log(out);

    this.props.dispatch(queueUpdate("homepage", 1, "features", out));
  };

  _onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      prevState => ({
        features: arrayMove(prevState.features, oldIndex, newIndex)
      }),
      () => this._saveQueue()
    );
  };

  _removeItem = key => {
    if (!window.confirm("Are you sure?")) return;

    this.setState(
      prevState => ({
        features: update([...prevState.features], { $splice: [[key, 1]] })
      }),
      () => this._saveQueue()
    );
  };

  _onNameChange = (val, index) => {
    this.setState(
      prevState => ({
        features: update(prevState.features, {
          [index]: { name: { $set: val } }
        })
      }),
      () => this._saveQueue()
    );
  };

  render() {
    const { country_id, features } = this.state;
    const {
      _addFeature,
      _handleCountryChange,
      _onSortEnd,
      _removeItem,
      _onNameChange,
      country_list
    } = this;

    if (!isSuperAdmin) document.location.href = "/";

    return (
      <div>
        <HomepageView
          country_list={country_list}
          country_id={country_id}
          handleCountryChange={_handleCountryChange}
          addFeature={_addFeature}
          features={features}
          onSortEnd={_onSortEnd}
          removeItem={_removeItem}
          onNameChange={_onNameChange}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ constants, data }) => {
  console.log("first");
  const { country_list } = constants;
  const { features } = data;
  return {
    country_list: country_list || [],
    features: features || []
  };
};

export default connect(mapStateToProps)(Homepage);
