/*
	(1)modifyItem function expect at least 'data_type' parameter. It may have 'data_field' in not point (2)
	(2)component should contain 'name' prop. 
	(3)wrapped componet should have props:

		data_field	,
		value		,
		handleChange(value, data_id),
		handleAddError:(message) [optional]
*/

import React, { Component } from "react";
import { connect } from "react-redux";
import {
  queueUpdate,
  formErrorAdd,
  formErrorRemove,
  sourceUpdate
} from "../actions/actions.js";

/* HOC component */

const modifyItem = (WrappedComponent, containerProps) => {
  return class extends Component {
    mapDispatchToProps(dispatch, ownProps) {
      const { data_type, name, custom_key } = ownProps;

      const file_id = JSON.stringify({ data_type, name, custom_key });

      return {
        handleChange: (value, data_id) => {
          dispatch(queueUpdate(data_type, data_id, name, value, custom_key));

          if (!custom_key) {
            dispatch(sourceUpdate(data_type, name, value));
          }
        },
        handleAddError: message => {
          const message_text =
            "Field " + data_type + ":" + name + "\n" + message;
          dispatch(formErrorAdd(file_id, message_text));
        },
        handleRemoveError: () => {
          dispatch(formErrorRemove(file_id));
        }
      };
    }

    /* optional: value, custom_key */
    mapStateToProps(state, ownProps) {
      const data_type = ownProps.data_type;

      const data_field = ownProps.name;
      const data = state.data[data_type];
      if (!data) {
        /* eslint no-throw-literal: "off" */
        throw "No state.data for " + data_type + "[" + data_field + "]";
      }
      const value = ownProps.value || data[data_field];
      const data_key = ownProps.custom_key || data_type + "_id";
      const data_id = ownProps.data_id || data[data_key];

      return {
        value: value,
        data_id: data_id
      };
    }

    render() {
      let props = { ...containerProps, ...this.props };

      props.wrapped_component = WrappedComponent;

      //const ComponentDataHolder = dataHolder(WrappedComponent, props);

      const El = connect(
        this.mapStateToProps,
        this.mapDispatchToProps
      )(DataHolder);

      return <El {...props} />;
    }
  };
};

// As we cannot transimt data_id between functions mapsStateToProps & mapDispatchToProps, we have create one more indirect component that will store that

class DataHolder extends Component {
  /*
	shouldComponentUpdate(nextProps) {
		
		const n = nextProps;
		const p = this.props;
		const test = (
			n.data_type		!== p.data_type			||
			n.data_field	!== p.data_field		||
			n.data_id 		!== p.data_id			||
			n.custom_key 	!== p.custom_key
		)
		
		return test;		
	}
	*/

  handleChange(value) {
    this.props.handleChange(value, this.props.data_id);
  }

  render() {
    // extract p withount unnecesery props
    const {
      handleChange,
      wrapped_component,
      data_type,
      data_id,
      data_field,
      custom_key,
      ...p
    } = this.props;

    const WrappedComponent = wrapped_component;

    return (
      <WrappedComponent handleChange={this.handleChange.bind(this)} {...p} />
    );
  }
}

export default modifyItem;
