import React, { Component } from "react";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { api_url } from "config/config";

class PcHtmlEditor extends Component {
  static propTypes = {
    value: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    dom_key: PropTypes.string.isRequired,
    config: PropTypes.object,
    spell_check: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.CKEDITOR = window.CKEDITOR;
    this.propChangeDebounced = this.propChangeDebounced.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);

    // due to difficaults with updating pure textarea element with prop defaultValue we have to recreate the textarea element
    this.state = {
      clearer: false
    };
  }

  propChangeDebounced = debounce(value => {
    this.props.handleChange(value);
  }, 500);

  componentWillReceiveProps(nextProps) {
    if (this.props.dom_key === nextProps.dom_key) {
      return;
    }
    this.CKEDITOR.instances[this.props.dom_key].destroy();
    this.setState({ clearer: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.clearer) {
      this.setState({
        clearer: false
      });
      return;
    }
    if (
      prevProps.dom_key === this.props.dom_key &&
      prevState.clearer === this.state.clearer
    )
      return;
    this.initComponent(this.props.dom_key);
  }

  initComponent = component_name => {
    const CKEDITOR = this.CKEDITOR;
    let configuration = {
      extraPlugins: "autogrow,image2,uploadimage,youtube",
      height: 100,
      autoGrow_maxHeight: 800,
      scayt_sLang: "en_GB",
      enterMode: CKEDITOR.ENTER_BR,
      format_tags: "p;h2",
      scayt_autoStartup: this.props.spellcheck || false,
      toolbar: "Basic",
      toolbar_Basic: [
        [
          "Bold",
          "Italic",
          "Underline",
          "-",
          "NumberedList",
          "BulletedList",
          "-",
          "Link",
          "Unlink",
          "-",
          "Image",
          "Youtube",
          "Table",
          "Maximize",
          "-",
          "Scayt",
          "Source",
          "Format"
        ]
      ],
      //filebrowserBrowseUrl: '',
      filebrowserUploadUrl: api_url + "image-in-text?dir=" + this.props.dir,
      filebrowserImageBrowseUrl: "",

      youtube_width: "640",
      youtube_height: "480",
      youtube_responsive: true,
      youtube_related: false,
      youtube_older: false,
      youtube_privacy: false,
      youtube_autoplay: false,
      youtube_controls: true,
      youtube_disabled_fields: [
        "txtEmbed",
        "chkAutoplay",
        "txtWidth",
        "txtHeight",
        "chkResponsive",
        "chkOlderCode",
        "chkPrivacy",
        "chkAutoplay"
      ],
      allowedContent: true
    };

    CKEDITOR.addCss(
      ".cke_editable table { margin-top:10px;} .cke_editable h2 { margin-bottom:7px; margin-top:23px; font-weight: 300;} .cke_editable { margin:5px; font-size: 1em; font-family: 'Lato', sans-serif; }"
    );

    CKEDITOR.replace(component_name, configuration);

    const this_editor = this.CKEDITOR.instances[this.props.dom_key];

    this_editor.on(
      "change",
      function() {
        this.propChangeDebounced(this_editor.getData());
      }.bind(this)
    );

    //////////////////
    //https://github.com/ckeditor/ckeditor-dev/issues/1180
    this_editor.on(
      "fileUploadResponse",
      function() {
        setTimeout(
          function() {
            this.propChangeDebounced(this_editor.getData());
            console.log("200");
          }.bind(this, this_editor),
          100
        );
      }.bind(this)
    );

    //////////////////
  };

  componentDidMount() {
    this.initComponent(this.props.dom_key);
  }

  componentWillUnmount() {
    this.CKEDITOR.instances[this.props.dom_key].destroy();
  }

  render() {
    return (
      <div style={{ maxWidth: 910, minHeight: 171 }}>
        {!this.state.clearer && (
          <textarea
            name={this.props.dom_key}
            cols="100"
            rows="6"
            defaultValue={this.props.value}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ data }, ownProps) => {
  let spellcheck = false;

  if (process.env.NODE_ENV !== "production") {
    spellcheck = false;
  } else if (ownProps.spellcheck || ownProps.spellcheck === false) {
    spellcheck = ownProps.spellcheck;
  } else if (data.city && (!data.city.lang || data.city.lang === "en")) {
    spellcheck = true;
  }

  let dir = "";
  if (data.country) {
    dir = data.country.symbol;
  }
  if (data.city) {
    dir += "/" + data.city.symbol;
  }
  if (!dir) {
    dir = "others";
  }

  return { spellcheck, dir };
};

export default connect(mapStateToProps)(PcHtmlEditor);
