import React, { Component } from "react";
import { connect } from "react-redux";
import { queueUpdate } from "../../../actions/actions.js";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { cloneDeep } from "lodash";
import PcCard from "../../shared/PcCard";
import ExactDays from "./ExactDays";
import DateRange from "./DateRange";

/* the old format
weekly = date_range
{
  "date_start": "2017-07-21",
  "date_stop": "2017-12-31",
  "datesofweek": "1234567",
  "hour": "",
  "hour2": "",
  "hour2_stop": "",
  "hour_stop": "",
  "hour_from_venue": "",
  "type": "weekly",
  "venue_id": "31901",
  "venue_link": "http://pc.inyourpocket.com/cms_venue/31901-ethnographic-department-of-the-national-museum.html"
}

exact days
{
  "days": [
    {
      "date": "2017-11-19",
      "hour": "",
      "hour2": "",
      "hour2_stop": "",
      "hour_stop": "",
  	  "hour_from_venue": "",
      "venue_id": "151262",
      "venue_link": "http://pc.inyourpocket.com/cms_venue/151262-b90.html"
    }
  ],
  "type": "exact"
}
*/

const translateToNewFormat = data_in => {
  const translateHours = row_in => {
    const { hour, hour2, hour_stop, hour2_stop, ...data } = row_in;
    let hours = [];
    if (hour) {
      hours.push([hour, hour_stop || null]);
    }
    if (hour2) {
      hours.push([hour2, hour2_stop || null]);
    }
    if (!hours.length) hours = [[null, null]];
    return { hours, ...data };
  };

  if (!data_in.type) return;

  if (data_in.type === "weekly") {
    const data = translateHours(data_in);
    const { venue_link, ...data_out } = data;
    return data_out;
  } else if (data_in.type === "exact") {
    let days = [];
    for (let i = 0; i < data_in.days.length; i++) {
      const { venue_link, ...day } = data_in.days[i];
      days.push(translateHours(day));
    }
    return {
      ...data_in,
      days
    };
  }
};

class EventCardDate extends Component {
  constructor(props) {
    super(props);

    this.state_exact_init = {
      days: [
        {
          date: null,
          hours: [[null, null]],
          venue_id: null,
          hour_from_venue: null
        }
      ],
      type: "exact"
    };

    this.state_exact = cloneDeep(this.state_exact_init);

    this.state_weekly = {
      date_start: null,
      date_stop: null,
      datesofweek: "1234567",
      hours: [[null, null]],
      venue_id: null,
      type: "weekly",
      hour_from_venue: null
    };

    this.state = {
      dates_venues: this.stateInit(props)
    };
  }

  stateInit = props => {
    return props.dates_venues
      ? translateToNewFormat(props.dates_venues)
      : this.state_exact;
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.event_id === this.props.event_id) return;
    this.setState({
      dates_venues: this.stateInit(nextProps)
    });
  }

  handleTypeChange = event => {
    const type = event.target.value;
    if (type === "weekly") {
      this.state_exact = cloneDeep(this.state.dates_venues);
      this.setState({
        dates_venues: this.state_weekly
      });
    } else {
      this.state_weekly = cloneDeep(this.state.dates_venues);
      this.setState({
        dates_venues: this.state_exact
      });
    }
  };

  setStateUp = new_state => {
    const type = this.state.dates_venues.type;

    // make sure there is no non lines
    if (type === "exact") {
      if (!new_state.days.length) {
        new_state.days = this.state_exact_init.days;
      }
    }

    this.setState(
      {
        dates_venues: Object.assign({}, this.state.dates_venues, new_state)
      },
      () => {
        const dates_venues = this.state.dates_venues;
        this.props.dispatch(
          queueUpdate(
            "event",
            this.props.event_id,
            "dates_venues",
            dates_venues
          )
        );
        let venues_ids = [];
        if (type === "exact") {
          venues_ids = dates_venues.days.map(day => day.venue_id);
        } else {
          venues_ids.push(dates_venues.venue_id);
        }
        // remove empty
        venues_ids = venues_ids.filter(item => item);

        this.props.dispatch(
          queueUpdate("event", this.props.event_id, "venues_ids", venues_ids)
        );

        // temporary till old pc works
        // https://docs.google.com/document/d/1ev70ln2VW3PTJg7e2C27ZwGm0DMeu2aKF0ImuQQ7ecc/edit
        this.props.dispatch(
          queueUpdate("event", this.props.event_id, "new_pc", 1)
        );
      }
      // add venue days
    );
  };

  render() {
    const styles = {
      radioButton: {
        display: "inline-block",
        width: "180px"
      }
    };

    const dates_venues = this.state.dates_venues;

    return (
      <PcCard cardTitle="Date & venue" id="card_date">
        <div className="pd">
          <RadioButtonGroup
            name="shipSpeed"
            defaultSelected="not_light"
            onChange={this.handleTypeChange}
            valueSelected={dates_venues.type}
          >
            <RadioButton
              value="exact"
              label="Exact days"
              style={styles.radioButton}
            />
            <RadioButton
              value="weekly"
              label="Date range"
              style={styles.radioButton}
            />
          </RadioButtonGroup>
        </div>

        {dates_venues.type === "exact" && (
          <ExactDays days={dates_venues.days} stateUp={this.setStateUp} />
        )}
        {dates_venues.type === "weekly" && (
          <DateRange {...dates_venues} stateUp={this.setStateUp} />
        )}
      </PcCard>
    );
  }
}

const mapStateToProps = ({ data }) => {
  const { event_id, dates_venues, city_id } = data.event;
  return {
    event_id,
    dates_venues,
    city_id
  };
};

export default connect(mapStateToProps)(EventCardDate);
