import React, { Component } from "react";
import PcCard from "./PcCard";
import PcSearch from "./PcSearch";
import EditableList from "./EditableList";
import { connect } from "react-redux";
import { queueUpdate } from "../../actions/actions.js";
import { Row, CellHalf } from "./Grid";

import PropTypes from "prop-types";

class FeatureConnectedState extends Component {
  constructor(props) {
    super();
    this.state = {
      items: props.connected_features || [],
      value: ""
    };
    this.addItem = this.addItem.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  // added new item
  addItem(data) {
    const items = [...this.state.items, data];
    this.setState({ items });
    this.props.modifyIds(items);
  }

  handleChange(items) {
    this.setState({ items });
    this.props.modifyIds(items);
  }

  render() {
    const { country_id, country_name, data_type, data_id } = this.props;

    const search_key = data_type + data_id;

    return (
      <PcCard cardTitle="Connected Features" id="card_features">
        <Row>
          <CellHalf>
            {/*<PcSearch narrow_field_key='country_id' narrow_field_id={country_id} table='feature' onResultClick={this.addItem} tip={"features in "+country_name} search_id={'feature'+feature_id}/>*/}
            <PcSearch
              narrow_field_key="country_id"
              narrow_field_id={country_id}
              table="feature"
              onResultClick={this.addItem}
              tip={"features in " + country_name}
              search_id={search_key}
            />
          </CellHalf>
          <CellHalf>
            {this.state.items.length > 0 && (
              <EditableList
                items={this.state.items}
                is_sortable={false}
                handleChange={this.handleChange}
              />
            )}
          </CellHalf>
        </Row>
      </PcCard>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    modifyIds: items => {
      const feature_ids = items.map(item => {
        return item.feature_id;
      });
      dispatch(
        queueUpdate(ownProps.data_type, ownProps.data_id, "feature_ids", [
          ...feature_ids
        ])
      ); /*...feature_ids will make them uniqe */
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  const { data_type, data_id } = ownProps;

  return {
    connected_features: state.data[data_type].connected_features || [],
    country_name: state.data.country.name,
    country_id: state.data.country.country_id,
    data_type,
    data_id
  };
};

const CardConnectedFeaturesReduxed = connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureConnectedState);

export default class CardConnectedFeatures extends Component {
  static propTypes = {
    data_type: PropTypes.string.isRequired,
    data_id: PropTypes.number.isRequired
  };

  render() {
    return (
      <CardConnectedFeaturesReduxed
        data_type={this.props.data_type}
        data_id={this.props.data_id}
        key={this.props.data_type + this.props.data_id}
      />
    );
  }
}
