// @flow

// $FlowFixMe - after flow update https://github.com/facebook/flow/pull/7149/
import React from "react";
import { connect } from "react-redux";
import PcAppBar from "../shared/TopBar/PcAppBar";
import BreadCrumbs from "../shared/BreadCrumbs";

import withUserDataFetched from "../../core/withUserDataFetched";
import withDataFromUrl from "../../core/withDataFromUrl";
import { withAccessSuperadmin } from "../../core/withAccess";

import { queueUpdate } from "../../actions/actions";

import AffiliateVenues from "./AffiliateVenues";

const AffiliateVenueExlusions = ({
  country,
  affiliate_widget_venue_exclude_id,
  venuelist_descriptive,
  queueModifyVenues
}) => {

  const breadcrumbs = [
    {
      url: "/",
      name: "home"
    },
    {
      url: "/affiliate",
      name: "Affliliate home"
    },
    {
      url: "/affiliate/" + country.country_id,
      name: country.name
    },
    {
      url: "/affiliate/" + country.country_id + "/venue_exclusions",
      name: "Venue exclusions"
    }
  ];

  const handleModify = venue_list => {
    const venue_ids = venue_list.map(v => v.venue_id);
    queueModifyVenues(affiliate_widget_venue_exclude_id, venue_ids);
  };

  return (
    <>
      <PcAppBar
        title={country.name + " - Affiliate Widgets "}
        no_drawer={true}
      />
      <div className="pd pc-content-wide">
        <div className="pd">
          <BreadCrumbs link_data={breadcrumbs} />
          <h1>{country.name}</h1>
        </div>

        <div style={{ maxWidth: "500px", margin: "auto" }}>
          <h3>Do not display venues on these venues</h3>
          <p>You may still add widgets to the venue if it is exactly picked out in venuelist section on the widget page</p>
          <AffiliateVenues
            country_id={country.country_id}
            venuelist_descriptive={venuelist_descriptive}
            queueModifyVenues={handleModify}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ constants, data }, ownProps) => {
  const country_list = constants.country_list || null;
  const country_id_route = parseInt(ownProps.match.params.country_id);
  const country = country_list
    ? country_list.find(c => c.country_id === country_id_route)
    : null;

  const { affiliate_widget_venue_exclude } = data || null;
  const { venuelist_descriptive, affiliate_widget_venue_exclude_id } =
    affiliate_widget_venue_exclude || [];

  return {
    country,
    venuelist_descriptive,
    affiliate_widget_venue_exclude_id
  };
};

const mapDispatchToProps = dispatch => {
  return {
    queueModifyVenues: (affiliate_widget_venue_exclude_id, venue_ids) => {
      dispatch(
        queueUpdate(
          "affiliate_widget_venue_exclude",
          affiliate_widget_venue_exclude_id,
          "venue_ids",
          venue_ids
        )
      );
    }
  };
};

export default withUserDataFetched(
  withDataFromUrl(match => {
    return {
      page_type: "affiliate-exclusions",
      page_id: match.country_id,
      api_call: "page/affiliate/" + match.country_id + "/excluded"
    };
  }, withAccessSuperadmin(connect(mapStateToProps, mapDispatchToProps)(AffiliateVenueExlusions)))
);
