import { is_debug } from "../config/config";
import {
  indicatorOn,
  indicatorOff,
  cleanModiffy,
  clearPostData
} from "./actions";
import { snackSuccess, snackError } from "./snacks";
import { leloFetch } from "../helpers";
import history from "../history.js";

export const remove = (item_type, item_key, item_id, parent_url) => {
  return dispatch =>
    dispatch(makeAction("remove", item_type, item_key, item_id, parent_url));
};

export const archive = (item_type, item_key, item_id, parent_url) => {
  return dispatch =>
    dispatch(makeAction("archive", item_type, item_key, item_id, parent_url));
};

const makeAction = (action, item_type, item_key, item_id, parent_url) => {
  return dispatch => {
    dispatch(indicatorOn());
    dispatch(cleanModiffy());
    dispatch(clearPostData());
    return leloFetch("remove", {
      method: "POST",
      json_data: { action, item_type, item_key, item_id }
    }).then(
      data => {
        dispatch(indicatorOff());

        if (data.success) {
          const message =
            action === "archive" ? "Item archived" : "Item removed";
          dispatch(snackSuccess(message));
          history.push("/" + parent_url);
        }
        if (data.error) {
          if (is_debug) dispatch(snackError("ERROR: " + data.error));
          else dispatch(snackError("ERROR: something went wrong"));
        }
      },
      error => {
        dispatch(indicatorOff());
        dispatch(snackError("ERROR: " + error));
      }
    );
  };
};
