import { connect } from "react-redux";
import Info from "../shared/Info";

const mapStateToProps = ({ data }) => {
  let warnings = [];
  let notices = [];
  let errors = [];
  const event = data.event;

  if (event.archived) warnings.push("This event is archived");

  if (event.web && !event.img_gallery)
    warnings.push("Please add at least one image into gallery");

  if (event.mobile && !event.mobile_background && !event.img_gallery)
    errors.push(
      "A problem occurred with this event therefore it will not be displayed in the app. Add image."
    );

  return {
    warnings,
    notices,
    errors
  };
};
export default connect(mapStateToProps)(Info);
