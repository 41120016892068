import React from "react";
import { connect } from "react-redux";
import Snackbar from "material-ui/Snackbar";

const snack_style = {
  all: {
    top: 0,
    bottom: "auto",
    left: (window.innerWidth - 288) / 2
  },
  transform_on: "translate3d(0, 0, 0)",
  transform_off: "translate3d(0, -50px, 0)"
};

const Snacks = ({ success, error }) => [
  <Snackbar
    key={1}
    open={success ? true : false}
    message={success || ""}
    style={{
      ...snack_style.all,
      transform: success ? snack_style.transform_on : snack_style.transform_off
    }}
    bodyStyle={{ backgroundColor: "#3b934f" }}
  />,
  <Snackbar
    key={2}
    open={error ? true : false}
    message={error || ""}
    style={{
      ...snack_style.all,
      transform: error ? snack_style.transform_on : snack_style.transform_off
    }}
    bodyStyle={{ backgroundColor: "#DD2C00" }}
  />
];

const mapStateToProps = ({ snack }) => {
  const { success, error } = snack;
  return { success, error };
};

export default connect(mapStateToProps)(Snacks);
