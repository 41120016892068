import React from "react";

import { connect } from "react-redux";

import CitySelect from "../shared/Drawer/CitySelect";
import ChapterSelect from "../shared/Drawer/ChapterSelect";
import CategorySelect from "../shared/Drawer/CategorySelect";
import PcCard from "../shared/PcCard.js";
import RemoveArchive from "../shared/RemoveArchive";

//import	CardRedirect 	from '../shared/CardRedirect.js'
//import	{InfoNotice} 	from '../shared/Info/Info'

import ChapterInfo from "./ChapterInfo";

import { Row, CellHalf, RowFlex } from "../shared/Grid";
import VenueList from "../shared/Drawer/VenueList";
import CardMetaTags from "../shared/CardMetaTags";
import PcAppBar from "../shared/TopBar/PcAppBar";
import PcDrawer from "../shared/Drawer/PcDrawer";
import PcTextField from "../shared/PcTextField.js";
import PcSelect from "../shared/PcSelect.js";
import PcCheckbox from "../shared/PcCheckbox.js";
import { modifyWrapper } from "../../core/ModifyWrapper";
import BodySplited from "../shared/BodySplited";
import CardImages from "../shared/Images/CardImages";
import CardImagesBackground from "../shared/Images/CardImagesBackground";
import BackgroundMobile from "../shared/Images/BackgroundMobile";
import ChapterCardPicks from "./ChapterCardPicks";
import ChapterCardCategories from "./ChapterCardCategories";
import BreadCrumbs from "../shared/BreadCrumbs";
import EditedBy from "../shared/EditedBy";
import CardRedirect from "../shared/CardRedirect.js";

import Toc from "../shared/Toc";
import toc from "./chapter_toc";

const MTextField = modifyWrapper(PcTextField, "chapter");
const MCheckbox = modifyWrapper(PcCheckbox, "chapter");
const MPcSelect = modifyWrapper(PcSelect, "chapter");

const MTextFieldText = modifyWrapper(PcTextField, "text");

const ChapterView = ({ chapter_id, city_id, country_id, text_id }) => {
  const template_options = [
    { name: "auto", value: 0 },
    { name: "filter", value: 1 },
    { name: "directory", value: 2 },
    { name: "wiki", value: 3 }
  ];

  return (
    <div>
      <PcAppBar />
      <PcDrawer>
        <CitySelect city_id={city_id} />
        <ChapterSelect chapter_id={chapter_id} />
        <CategorySelect />
        <VenueList />
      </PcDrawer>

      <Toc data={toc} />

      <div className="pd pc-content">
        <div className="top-line">
          <BreadCrumbs />
          <EditedBy />
        </div>

        <ChapterInfo />

        <PcCard cardTitle="Settings" id="card_top">
          <Row>
            <CellHalf>
              <MTextField
                name="name"
                required={true}
                floatingLabelText="name"
              />
            </CellHalf>
            <CellHalf>
              <MTextField name="title" floatingLabelText="alternative name" />
            </CellHalf>
          </Row>

          <RowFlex>
            <MCheckbox name="active" label="active" />

            <MTextField
              name="ordering"
              floatingLabelText="ordering"
              type="number"
              fullWidth={false}
            />

            <MPcSelect
              name="template"
              floatingLabelText="listing type"
              options={template_options}
            />
          </RowFlex>
        </PcCard>

        <PcCard cardTitle="Description" id="card_description">
          <Row>
            <CellHalf>
              <MTextFieldText
                name="name"
                floatingLabelText="text title"
                hintText="If empty, chapter name will be used"
              />
            </CellHalf>
          </Row>
          <br />

          <BodySplited data_type="text" data_id={text_id} />
        </PcCard>

        <ChapterCardPicks />

        <CardImages data_type="chapter" data_id={chapter_id} />

        <CardImagesBackground data_type="chapter" data_id={chapter_id} />

        <PcCard cardTitle="Image App Mobile" id="card_images_app">
          <BackgroundMobile />
        </PcCard>

        <CardMetaTags TextFieldTyped={MTextField} />

        <ChapterCardCategories />

        <CardRedirect data_type="chapter" />

        <RemoveArchive />
      </div>
    </div>
  );
};

/*
<CardRedirect data_type="category" data_id={category_id} />
*/

const mapStateToProps = state => {
  const { chapter_id, city_id, country_id } = state.data.chapter;
  const { text_id } = state.data.text;

  return { chapter_id, city_id, country_id, text_id };
};

export default connect(mapStateToProps)(ChapterView);
