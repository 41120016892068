const indicator = (state = false, action) => {
  switch (action.type) {
    case "INDICATOR_ON":
      return true;

    case "INDICATOR_OFF":
      return false;

    default:
      return state;
  }
};

export default indicator;
