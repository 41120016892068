import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PcCard from "../PcCard.js";
import BackgroundWww from "./BackgroundWww";

const prop_types = {
  data_type: PropTypes.string.isRequired,
  data_id: PropTypes.number.isRequired,
  handleBackgroundChange: PropTypes.func // emit to parent in city for app backgrounsd selector
};

class BgWithState extends Component {
  _init({ background }) {
    this.setState({ background });
  }

  componentWillMount() {
    this._init(this.props);
  }

  componentWillReceiveProps(nextProps) {
    const { background } = nextProps;
    if (nextProps.unique !== this.props.unique) this._init({ background });
  }

  _handleImagesChange = data_change => {
    this.setState(data_change);
    if (this.props.handleBackgroundChange) {
      this.props.handleBackgroundChange(data_change);
    }
  };

  render() {
    return (
      <PcCard cardTitle="Images Background" id="card_images_bg">
        <BackgroundWww
          background={this.state.background}
          handleImagesChange={this._handleImagesChange}
        >
          {this.props.children}
        </BackgroundWww>
      </PcCard>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { data_key, background } = state.data;

  return {
    data_type: ownProps.data_type,
    data_id: ownProps.data_id,
    unique: data_key,
    background: background || []
  };
};

const CardImages = connect(mapStateToProps)(BgWithState);

CardImages.propTypes = prop_types;

export default CardImages;
