import { leloFetch } from "helpers";

import history from "../history.js";

import { preserveUserData } from "./login";
import { addNotLoggedGoLink } from "./pageControl";

const getUserDataPromise = () => (dispatch, getState) =>
  new Promise(async (resolve, reject) => {
    //reject('a probelm accoured');

    const { user_data } = getState();
    if (user_data.rights) {
      //console.log('user_data already in store', user_data)
      resolve("user_data already in store");
    }
    const data_from_localstorage = localStorage.getItem("user_data");
    if (data_from_localstorage) {
      dispatch(preserveUserData(JSON.parse(atob(data_from_localstorage))));
      resolve("user_data already in localStorage");
    }
    try {
      const data = await leloFetch("getUserData");
      if (data.error) {
        reject(data.error);
        // error should be trown as exception but somehow it was passed
      }

      dispatch(preserveUserData(data));

      try {
        localStorage.setItem("user_data", btoa(JSON.stringify(data)));
        //console.log('user_data saved into localStorage')
      } catch (error) {
        console.warn("localStorage probably full", error);
      }
      resolve("user data fetched");
    } catch (error) {
      reject(error);
    }
  });

export { getUserDataPromise };

const getUserData = () => {
  return async (dispatch, getState) => {
    const { user_data } = getState();
    if (user_data.rights) {
      //console.log('user_data already in store', user_data)
      return;
    }
    const data_from_localstorage = localStorage.getItem("user_data");
    if (data_from_localstorage) {
      dispatch(preserveUserData(JSON.parse(atob(data_from_localstorage))));
      //console.log('user_data already in localStorage')
      return;
    }
    try {
      const data = await leloFetch("getUserData");
      if (data.error) {
        console.log("errror 6789");
        // error should be trown as exception but somehow it was passed
        history.push("/login");
        return;
      }
      //console.log('data', data)
      dispatch(preserveUserData(data));
      try {
        localStorage.setItem("user_data", btoa(JSON.stringify(data)));
        //console.log('user_data saved into localStorage')
      } catch (error) {
        console.warn("localStorage probably full", error);
      }
    } catch (e) {
      dispatch(addNotLoggedGoLink(history.location.pathname));
      history.push("/login");
    }
  };
};

export default getUserData;
