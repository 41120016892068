import React, { Component } from "react";
import FlatButton from "material-ui/FlatButton";
import PropTypes from "prop-types";

class Map extends Component {
  static propTypes = {
    address_text: PropTypes.string,
    center: PropTypes.object,
    coords: PropTypes.object,
    zoom: PropTypes.number,
    handleCoordsChange: PropTypes.func.isRequired,
    handleReset: PropTypes.func.isRequired
  };

  static defaultProps = {
    center: { lat: 0, lng: 0 },
    zoom: 10
  };

  constructor(props) {
    super(props);

    this.map = null;
    this.marker = null;
  }

  componentDidMount() {
    const { center, coords, zoom, handleCoordsChange } = this.props;

    const L = window.L;
    const map_center = [center.lat, center.lng];
    const marker_position = coords ? [coords.lat, coords.lng] : map_center;

    const map = L.map(this.refs.map).setView(map_center, zoom);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    this.map = map;

    this.marker = L.marker(marker_position, {
      draggable: true
    })
      .on("dragend", function(e) {
        const new_coords = {
          lat: this.getLatLng().lat,
          lng: this.getLatLng().lng
        };
        handleCoordsChange(new_coords);
      })
      .addTo(map);
  }

  componentWillReceiveProps({ coords, center }) {
    // do nothing
    if (
      coords &&
      this.props.coords &&
      this.props.coords.lat === coords.lat &&
      this.props.coords.lng === coords.lng
    )
      return;

    // reset clicked
    if (this.props.coords && !coords) {
      this.map.panTo(center);
      return;
    }

    if (coords) {
      // if user manually clean field
      if (!coords.lat || !coords.lng) return;
      this.map.panTo(coords);
      this.marker.setLatLng(coords);
    }
  }

  async handleAddressSearch() {
    const { address_text } = this.props;

    //const url = "https://geocoder.api.here.com/6.2/geocode.json?app_id=10TrL0m9cwZIz4ANlbTv&app_code=yE9vj0gRggPjqEmuBQDhXw&searchtext="+encodeURI(address_text)
    const url =
      "https://www.mapquestapi.com/geocoding/v1/address?key=eYC5Zu7xtQIsWJAuN8aKeuasHdhMbDzF&inFormat=kvp&outFormat=json&location=" +
      encodeURI(address_text);
    try {
      let response = await fetch(url);
      // only proceed once promise is resolved
      let data = await response.json();
      // only procee1d once second promise is resolved

      if (!data || !data.results || !data.results.length) {
        return alert("Geocode was not successful (code:0287)");
      }

      const result = data.results[0].locations[0];

      const is_accurate = result.geocodeQualityCode.startsWith("P1A");
      if (!is_accurate) {
        alert("Please notice that position is inaccurate");
      }

      const { lat, lng } = result.latLng;

      const new_coords = {
        lat,
        lng
      };

      this.props.handleCoordsChange(new_coords);

      //map.setView(new L.LatLng(40.737, -73.923), 16);
      this.map.setView(new_coords, is_accurate ? 18 : 16);
    } catch (error) {
      console.log("geocode api error: ", error);
      alert("Geocode was not successful  (code:7vkkjj)");
    }
  }

  /*
	async handleAddressSearch() {

		const {address_text} = this.props;

		const url = "https://api.opencagedata.com/geocode/v1/json?key=6348a8d0a67c4a7e963ed3a448bf9b2b&pretty=1&no_annotations=1&q="+encodeURI(address_text)

		try {
			let response = await fetch(url);
			  // only proceed once promise is resolved
			let data = await response.json();
			  // only procee1d once second promise is resolved

			 if (data.status.code !== 200) {
			 	return alert ('Geocode was not successful (code:8ch5)')
			 }
			 if (data.total_result < 1) {
			 	return alert ('No results found for ' + address_text)
			 }

			const result = data.results[0];
			if (result.confidence < 6) return alert ('Geocode was not successful  (code:8cghge4)')

			// without return
			if (result.confidence < 9) alert ('please take a notice that this position may be inaccurate')

			this.props.handleCoordsChange(result.geometry)
		}
		catch(error) {
			alert ('Geocode was not successful  (code:7vkkjj)')
		}
	}
*/

  render() {
    const AddressButton = ({ address_text, handleAddressSearch }) => {
      if (!address_text) return null;

      return (
        <span>
          {address_text}
          <FlatButton
            label="Search by address"
            onClick={handleAddressSearch}
            primary={true}
          />
        </span>
      );
    };

    const ResetButton = ({ coords, handleReset }) => {
      if (!coords) return null;

      return (
        <FlatButton
          label="Remove coords"
          secondary={true}
          onClick={handleReset}
          style={{ float: "right" }}
        />
      );
    };

    const handleReset = () => {
      this.map.setZoom(10);
      this.props.handleReset();
    };

    const style = this.props.style || { minHeight: "300px", width: "100%" };

    return (
      <div>
        {this.props.address_text && (
          <div style={{ height: "2.3em" }}>
            <AddressButton
              address_text={this.props.address_text}
              handleAddressSearch={this.handleAddressSearch.bind(this)}
            />
            <ResetButton coords={this.props.coords} handleReset={handleReset} />
          </div>
        )}

        <div id="the_map" ref="map" style={style} />
      </div>
    );
  }
}

export default Map;
