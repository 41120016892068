import React from "react";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { Link } from "react-router-dom";
import { cssActive } from "../../../helpers";
import getCityStructureData from "selectors/getCityStructureData";

const DistrictSelectView = ({ district_id, district_list }) => {
  if (district_list.length === 0) return null;

  return (
    <SelectField
      value={district_id}
      floatingLabelText="District"
      autoWidth={true}
      style={{ maxWidth: "100%" }}
    >
      {district_list.map((district, i) => {
        const style = cssActive(district.active);
        const link =
          "/district/" + district.symbol + "/" + district.district_id;
        return (
          <MenuItem
            value={district.district_id}
            primaryText={district.name}
            style={style}
            containerElement={<Link to={link} />}
            key={district.district_id}
          />
        );
      })}
    </SelectField>
  );
};

const mapStateToProps = ({ data_city }, ownProps) => {
  const { district_list } = getCityStructureData({
    data_city,
    city_id: ownProps.city_id
  });

  return {
    district_list,
    district_id: ownProps.district_id
  };
};

const DistrictSelect = connect(mapStateToProps)(DistrictSelectView);

export default DistrictSelect;
