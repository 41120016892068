import React, { Component } from "react";
import { connect } from "react-redux";

import IconButton from "material-ui/IconButton";
import ActionLaunch from "material-ui/svg-icons/action/launch";
import ActionAccountCircle from "material-ui/svg-icons/action/account-circle";

import AppBar from "material-ui/AppBar";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import Subheader from "material-ui/Subheader";

import { debounce } from "lodash";

import { toggleDrawer } from "../../../actions/actions";
import { logoutClick } from "../../../actions/login";
import SaveButton from "./SaveButton";
import UnArchiveButton from "./UnArchiveButton";
import ShortLink from "./ShortLink";
import SearchForm from "./SearchForm";
import { test_url } from "../../../config/config";

import PropTypes from "prop-types";

const propTypes = {
  custom_save_component: PropTypes.func, // used in venue which expect next & prev button
  title: PropTypes.string, //
  no_drawer: PropTypes.bool, // no drawer. Set true to spread whole width
  no_save: PropTypes.bool // Set true to hide save button
};

const TopBarRight = ({
  user_name,
  test_link,
  custom_save_component,
  no_save = false,
  archived,
  logout
}) => {
  const SaveComponent = custom_save_component
    ? custom_save_component
    : () => <SaveButton />;

  return (
    <div style={{ marginTop: "-8px", display: "inline-block" }}>
      <div style={{ display: "inline-block", transform: "translateY(-6px)" }}>
        <SearchForm />
      </div>

      {!no_save && !archived && <SaveComponent />}
      {archived && <UnArchiveButton />}
      <IconMenu
        iconButtonElement={
          <IconButton>
            <ActionAccountCircle color="white" />
          </IconButton>
        }
      >
        <Subheader>{user_name}</Subheader>
        <MenuItem primaryText="Logout" onClick={logout} />
      </IconMenu>
    </div>
  );
};

const AppBarView = ({
  user_name,
  title,
  test_link,
  short_link,
  no_drawer,
  no_save,
  archived,
  showMenuIconButton,
  toggleDrawer,
  logout,
  custom_save_component
}) => {
  document.title = title || "";

  const app_bar_class = no_drawer ? "" : "with-drawer";

  return (
    <div>
      <AppBar
        title={
          <span
            style={{
              display: "flex"
            }}
          >
            <span
              id="aaaaaaa"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {title}
            </span>

            {test_link && (
              <a target="_blank" rel="noopener noreferrer" href={test_link}>
                <IconButton>
                  <ActionLaunch color="white" />
                </IconButton>
              </a>
            )}
            <ShortLink />
          </span>
        }
        className={"pc-app-bar " + app_bar_class}
        style={{ position: "fixed" }}
        showMenuIconButton={showMenuIconButton}
        onLeftIconButtonClick={toggleDrawer}
        iconElementRight={
          <TopBarRight
            user_name={user_name}
            test_link={test_link}
            archived={archived}
            custom_save_component={custom_save_component}
            logout={logout}
            no_save={no_save}
          />
        }
      />
    </div>
  );
};

class AppBarWithState extends Component {
  constructor(props) {
    super(props);
    this.state = this.compute(); // not debounced here
  }

  compute = () => {
    return {
      showMenuIconButton: document.body.clientWidth > 1000 ? false : true
    };
  };

  sizeChangeDebounced = debounce(() => this.setState(this.compute()), 200);

  componentWillMount() {
    window.addEventListener("resize", this.sizeChangeDebounced);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.sizeChangeDebounced);
  }

  render() {
    return (
      <AppBarView
        showMenuIconButton={this.state.showMenuIconButton}
        {...this.props}
      />
    );
  }
}

const getPreviewLink = data => {
  const {
    country,
    city,
    chapter,
    category,
    venue,
    event,
    feature,
    feature_type
  } = data;

  let parts = [];

  switch (data.page_type) {
    case "event":
      parts = [city.symbol, event.symbol + "_" + event.event_id + "e"];
      break;
    case "venue":
      parts = [city.symbol, venue.symbol + "_" + venue.venue_id + "v"];
      break;
    case "category":
      parts = [city.symbol, chapter.symbol, category.symbol];
      break;
    case "chapter":
      parts = [city.symbol, chapter.symbol];
      break;
    case "city":
      parts = [city.symbol];
      break;
    case "country":
      parts = [country.symbol];
      break;
    case "feature_type":
      parts = [country.symbol, "features", feature_type.symbol];
      break;
    case "feature":
      if (city) parts.push(city.symbol);
      parts.push(feature.symbol + "_" + feature.feature_id + "f");
      break;
    default:
      parts = [];
  }

  return parts.length > 0 ? parts.join("/") : null;
};

const mapStateToProps = (state, ownProps) => {
  const path = getPreviewLink(state.data);
  const test_link = path ? test_url + path : null;
  const first_item = state.data[state.data.data_type];

  // get title
  let title = ownProps.title;

  if (!title) {
    if (!first_item) console.error("No title element found in store data");
    title = first_item.name;
  }

  const archived = first_item && first_item.archived ? true : false;

  return {
    title: title,
    custom_save_component: ownProps.custom_save_component,
    test_link,
    user_name: state.user_data.user ? state.user_data.user.name : "Not logged",
    archived
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleDrawer: () => dispatch(toggleDrawer()),
    logout: () => dispatch(logoutClick())
  };
};

const PcAppBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppBarWithState);

PcAppBar.propTypes = propTypes;

export default PcAppBar;
