/**
 * @flow
 */

import React from "react";
import { connect } from "react-redux";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import { Link } from "react-router-dom";

type event_type = {
  name: string,
  symbol: string,
  event_type_id: number,
  ordering: ?number
};

const EventTypeSelect = ({
  event_type_id,
  event_type_list
}: {
  event_type_id: number,
  event_type_list: Array<event_type>
}) => {
  return (
    <SelectField
      value={event_type_id}
      floatingLabelText="Event category"
      autoWidth={true}
      style={{ maxWidth: "100%" }}
    >
      {event_type_list.length === 0 && (
        <div className="pd text-center">No categories yet</div>
      )}
      {event_type_list.length > 0 &&
        event_type_list.map(({ symbol, event_type_id, name, ordering }) => {
          const link = "/event_type/" + symbol + "/" + event_type_id;
          return (
            <MenuItem
              primaryText={name}
              secondaryText={ordering}
              containerElement={<Link to={link} />}
              key={event_type_id}
              value={event_type_id}
            />
          );
        })}
    </SelectField>
  );
};

const mapStateToProps = ({ data }) => {
  const { event_type, event_type_list, city } = data;

  const event_type_id = event_type ? event_type.event_type_id : null;

  return {
    event_type_list: event_type_list || [],
    event_type_id,
    key: city.city_id + "-" + (event_type_id || "")
  };
};

export default connect(mapStateToProps)(EventTypeSelect);
